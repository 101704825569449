

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CanalizarService from "../../services/CanalizarService";
import CitasService from "../../services/CitasService";
import ColegasService from "../../services/ColegasService";
import ExpedientesService from "../../services/ExpedientesService";
import FamiliaresService from "../../services/FamiliaresService";
import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import SesionesService from "../../services/SesionesService";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Reporte.css";
import "../../styles/MenuHamburguesa.css";


const Reporte = (id) => {

    const [famDelPac, setFamDelPaciente] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [citasPac, setCitasPaciente] = useState([]);
    const [fotografia, setFotografia] = useState('');
    const [sesionesPac, setSesionesPaciente] = useState([]);
    const [expedientePac, setExpedientePaciente] = useState([]);

    const [pacientes, setPacientes] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [sinFoto, setSinFoto] = useState('');
    const [pacActivos, setPacientesActivos] = useState([]);
    const [canalizados, setCanalizados] = useState([]);
    const [colegaAtiende, setColegaAtiende] = useState([]);
    const [citas, setCitas] = useState([]);
    const [famEsPaciente, setFamEsPaciente] = useState([]);
    const [famNoEsPaciente, setFamNoEsPaciente] = useState([]);
    const [esPaciente, setEsPaciente] = useState("");   // evaluar si el Familiar tambien es paciente
    const [image, setImage] = useState(null);
    const [fotoFam, setFotoFam] = useState('');
    const [colegas, setColegas] = useState([]);

    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const navigate = useNavigate();


    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });


    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {

        // si existe el paciente
        if (routeParams.id !== undefined) {

            cargarInformacion(routeParams.id);

            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);

            const data2 = await PacientesService.getPacientesActivos(usuario.id_psicologo);
            setPacientesActivos(data2);

            // Pacientes canalizados
            const data4 = await CanalizarService.getPacienteCanalizado(routeParams.id);
            setCanalizados(data4);

            // Colegas atienden paciente
            const data5 = await CanalizarService.getColegasAtiendenPaciente(usuario.id_psicologo);
            setColegaAtiende(data5);

            // Familiares del paciente: famNoEsPac
            const data6 = await FamiliaresService.getFamiliarNoEsPaciente(routeParams.id);
            setFamNoEsPaciente(data6);

            // Familiares del paciente: famEsPac
            const data3 = await FamiliaresService.getFamiliarEsPaciente(routeParams.id);
            setFamEsPaciente(data3);

            // Citas del paciente
            const data7 = await CitasService.getCitasHistorial(routeParams.id);
            for (let i = 0; i < data7.length; i++) {
                data7[i].numeroCita = (data7.length + 1) - (i + 1);   //.numeroSesion, se puede crear un parámetro temporal cuando no existe en la tabla DB
            }
            setCitasPaciente(data7);

            // Sesiones del paciente
            const data8 = await SesionesService.getSesionesHistorial(routeParams.id);

            for (let i = 0; i < data8.length; i++) {
                data8[i].numeroSesion = (data8.length + 1) - (i + 1);   //.numeroSesion, se puede crear un parámetro temporal cuando no existe en la tabla DB
            }
            setSesionesPaciente(data8);

            // Expediente 
            const data9 = await ExpedientesService.getExpediente(routeParams.id);
            setExpedientePaciente(data9[0]);

        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    }


    useEffect(() => {
        getData();
    }, []);


    // Información del paciente
    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais(InformacionPacientes.paisesPaciente(data[0].pais));

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);

    }


    // cargar datos familiares: El familiar no es paciente
    const cargarInformacionFam = async (idPaciente) => {
        //tabla familiar
        const data = await FamiliaresService.getFamDelPaciente(idPaciente);
        setFamDelPaciente(data);    //famDelPac

        //tabla vparentesco -- El familiar es paciente
        const data2 = await FamiliaresService.getFamiliarEsPaciente(idPaciente);
        setFamEsPaciente(data2);    //famEsPaciente
    }

    // cargar información del familiar
    const cargarInformacionPorFamiliar = async (id_familiar, esPac) => {
        // DisplayData2: El familiar no es paciente
        if (esPac === 'no') {

            const data = await FamiliaresService.getFamiliarIndividual(id_familiar);

            data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo)
            data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil)
            data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo)
            data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais)

            const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

            if (cont.length < 1) {
                setSinFoto("0");
            } else if (cont.length > 1 && cont.length < 50) {
                mostrarAdvertencia("Error al obtener fotografía");
            } else if (cont.length > 50) {
                setSinFoto("1");
            }

            setEsPaciente("no");
            setInformacion(data[0]);
            setFotoFam(data[0].fotografia.data)

        } else if (esPac === 'si') {

            // Display3: El familiar sí es paciente
            const data = await FamiliaresService.getFamiliarP(id_familiar);

            data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo)
            data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil)
            data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo)
            data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais)

            const cont = data[0].pf_fotografia.data;

            if (cont.length < 1) {
                setSinFoto("0");
            } else if (cont.length > 1 && cont.length < 50) {
                mostrarAdvertencia("Error al obtener fotografía");
            } else if (cont.length > 50) {
                setSinFoto("1");
            }

            setEsPaciente("si");
            setInformacion(data[0]);
            setFotoFam(data[0].pf_fotografia.data)

        }
    }

    const handleSalir = (event) => {
        // navigate('/pacientes');  //local
        navigate('/expedientes');  //web
    }

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        setPacientes(data);
    }

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            setPacientes(data);
        }
    }

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    )


    const card_datosPersonales = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <div id="div_photo_reporte">
                        {
                            fotografia.length !== 0 ? (
                                <img alt="photo_reporte" src={fotografia}></img>
                            ) : (<div></div>)
                        }
                    </div>
                    <div id="div_headPac_2_reporte">
                        <table id="table_headPac_1_reporte">
                            <thead>
                                <tr id="tr_headPac_reporte">
                                    <th id="th_nombre_reporte">Nombre</th>
                                    <th id="th_edad_reporte">Edad</th>
                                    <th id="th_sexo_reporte">Sexo</th>
                                    <th id="th_edoCivil_reporte">Estado civil</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="tr_datosPac_reporte">
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.nombre} {informacion.ap1} {informacion.ap2}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.edad}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {sexo}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {edoCivil}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table id="table_headPac_2_reporte">
                            <thead>
                                <tr id="tr_headPac2_reporte">
                                    <th id="th_dom_reporte">Domicilio</th>
                                    <th id="th_ciudad_reporte">Ciudad</th>
                                    <th id="th_cp_reporte">CP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="tr_datosPac2_reporte">
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.domicilio}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.ciudad}, &nbsp; &nbsp; {estado}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.cod_pos}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table id="table_headPac_3_reporte">
                            <thead>
                                <tr id="tr_headPac3_reporte">
                                    <th id="th_tel_reporte">Teléfono</th>
                                    <th id="th_correo_reporte">Correo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="tr_datosPac3_reporte">
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.tel}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.correo}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_ingresoSalidaSistema = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_headPac_reporte">
                                <th id="th_nom_reporte">Ingreso al sistema</th>
                                <th id="th_dom_reporte">Salida del sistema</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_datosPac_reporte">
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {moment(informacion.fecha_ingreso).format("DD-MM-YYYY")}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.fecha_inactivo}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_InfoPaciente = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_infoPac_reporte">
                                <th id="th_esc_reporte">Escolaridad</th>
                                <th id="th_prof_reporte">Profesión</th>
                                <th id="th_trabajo_reporte">Trabajo actual</th>
                                <th id="th_canalizar_reporte">Canalización</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_datosPac_reporte">
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.escolaridad}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.profesion}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.trabajo_actual}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.edo_canalizar}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_fam = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_famPac_reporte">
                                <th id="th_nom_reporte">Nombre</th>
                                <th id="th_edad_reporte">Edad</th>
                                <th id="th_edoCivil_reporte">Estado civil</th>
                                <th id="th_perent_reporte">Parentesco</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                famNoEsPaciente.map((fam) => {
                                    return (
                                        <tr id="tr_datosPac_reporte">
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.f_nom} {fam.f_ap1} {fam.f_ap2}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.f_edad}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {InformacionPacientes.estadoCivil(fam.f_edo_civil)}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.parentesco}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tbody>
                            {
                                famEsPaciente.map((fam) => {
                                    return (
                                        <tr id="tr_datosPac_reporte">
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.pf_nom} {fam.pf_ap1} {fam.pf_ap2}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.pf_edad}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {InformacionPacientes.estadoCivil(fam.pf_edo_civil)}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.parentesco}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_citas = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_famPac_reporte">
                                <th id="th_noCita_cita">No. cita</th>
                                <th id="th_fecha_cita">Fecha</th>
                                <th id="th_hora_cita">Horario</th>
                                <th id="th_estatus_cita">Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                citasPac.map((cita) => {
                                    return (
                                        <tr id="tr_datosPac_reporte">
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.numeroCita}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {moment(cita.fecha).format("DD-MM-YYYY")}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.hora_i} - {cita.hora_f}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.estatus_cita}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_sesiones = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        {
                            sesionesPac.map((sesion) => {
                                return (
                                    <div>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte">No. de sesión</th>
                                                <th id="th_fechaSe_reporte">Fecha</th>
                                                <th id="th_horaSe_reporte">Hora</th>
                                                <th id="th_estatusSe_reporte">Estatus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_datosPac_reporte">
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.numeroSesion}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {moment(sesion.fecha).format("DD-MM-YYYY")}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.hora_i} - {sesion.hora_f}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.estatus_sesion}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte">Contenido de la sesión</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={3}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.sesion}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <hr></hr>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                )
                            })
                        }
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_canalizaciones = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        {
                            canalizados.map((canalizar) => {
                                return (
                                    <div>
                                        <thead>
                                            <tr id="tr_canalizacion_can">
                                                <th id="th_Colega_can">Colega</th>
                                                <th id="th_Especialidad_can">Especialidad</th>
                                                <th id="th_fechaIn_can">Fecha inicio</th>
                                                <th id="th_fechaOut_can">Fecha finalización</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_datosPac_reporte">
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.co_nombre + " " + canalizar.co_ap1 + " " + canalizar.co_ap2}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.especialidad}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.fecha_i}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.fecha_f}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte" colSpan={2}>Motivo de la canalización:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={3}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.motivo}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte" colSpan={2}>Comentarios del psicólogo:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={3}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.comentario_psi}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte" colSpan={2}>Comentarios del colega:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={3}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.comentario_colega}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                )
                            })
                        }
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_expediente = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_pruebas_reporte">Pruebas aplicadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_datosPac_reporte">
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.pruebas}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_sugerencias_reporte">Sugerencias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.sugerencias}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_hisMedic_reporte">Historial médico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.hist_medico}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_expSig_reporte">Experiencias significativas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.exp_significativa}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_familiograma_reporte">Mapa de relaciones familiares</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.familiograma}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_familiograma_reporte">Diagnóstico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.diagnostico}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );


    return (
        <Layout>
            <Content>
                <div className="div-1-reporte">
                    <div className="px_div_2_reporte">
                        <div className="px_sesion_reporte">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label id="lbl-enSesion_psic">{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_reporte">
                            <label>Reporte general</label>
                        </div>
                    </div>
                    <div className="px_div_3_reporte">
                        <div className="div-3-card-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Datos personal</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_datosPersonales}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-card-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Ingreso al sistema</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_ingresoSalidaSistema}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-card2-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Información personal</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_InfoPaciente}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardFam-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Familiares del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_fam}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardCitas-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Citas del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_citas}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardSesiones-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Sesiones del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_sesiones}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardSesiones-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Canalizaciones del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_canalizaciones}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardExpediente-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Expediente del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_expediente}</Card>
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className="div-btnSalir1-reporte">
                        <div className="div-btnSalir2-reporte">
                            <button value="cancelar" id="btn-cancelar-edit" onClick={handleSalir}>Regresar</button>
                        </div>
                    </div>

                </div>
            </Content>
        </Layout >
    );
}


export default Reporte;





