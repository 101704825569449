
// Rol Paciente

import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import UsuariosService from "../../services/UsuariosService";
import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import FormularioCambiarContrasena from "../Rol_Paciente/FormularioCambiarContrasena";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Psicologo.css";
import "../../styles/RolPaciente.css";

import img_edit from "../../img/edit_note.svg";



const RolPaciente = (id) => {

    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [psicologo, setPsicologo] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [fotografiaPsi, setFotografiaPsi] = useState('');
    const [sinFotoPsi, setSinFotoPsi] = useState('');
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            timer: 6000,
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacionPacIndividual(routeParams.id);
        }
    };


    useEffect(() => {
        getData();
    }, [])


    const cargarInformacionPacIndividual = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInputValue(data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2);

        setInformacion(data[0]);

        cargarInformacionPsicologo(data[0].fk_psicologo);

    };

    const cargarInformacionPsicologo = async (id_psicologo) => {

        const data = await PsicologoService.getPsicologoIndividual(id_psicologo);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setSinFotoPsi("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFotoPsi("1");
        }

        setFotografiaPsi(data[0].fotografia.data)  // get base64 de DB
        setPsicologo(data[0]);
    };


    const editarRolPaciente = () => {
        navigate(`/editrolpaciente/${usuario.id_paciente}`);
    }



    const cambiarContrasena = async () => {

        const data = await UsuariosService.getUsuario(parseInt(routeParams.id));

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioCambiarContrasena usuario={data} />
        );
        Swal.fire({
            title: "Cambiar contraseña",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const contrasenaActual = document.getElementById("input-contrasenaActual").value;
                const contrasenaNueva = document.getElementById("input-contrasenaNueva").value;
                const id_usuario = data[0].id_usuario;

                if (contrasenaActual !== data[0].contrasena) {
                    mostrarError("La contraseña actual no es correcta.");
                } else if (contrasenaNueva === data[0].contrasena) {
                    mostrarError("No se puede repetir una contraseña actual.");
                } else {
                    return { contrasenaNueva, id_usuario };
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                putCambiarContrasena(datos);
                // refreshPage();
            } else if (result.dismiss === 'cancel') {
                navigate(`/rolPaciente/${usuario.id_paciente}`);
            }
        });
    }


    const putCambiarContrasena = async (datos) => {

        let data = {
            contrasena: datos.contrasenaNueva,
            id_usuario: datos.id_usuario,
        }

        const respuesta = await PacientesService.editarContrasenaPaciente(data);

        if (respuesta.status === 200) {

            Toast.fire("¡La contraseña se actualizó correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    navigate(`/rolPaciente/${usuario.id_paciente}`);
                }
            }).then(function () {
                navigate(`/rolPaciente/${usuario.id_paciente}`);
            });

        } else {
            mostrarError("Error al cambiar contraseña.");
        }
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="psi_div_1">
                    <div className="psi_div_2">
                        <div className="px_sesion_px">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_psi">
                            <label>Paciente</label>
                        </div>
                    </div>
                    <div className="div-datos-psi">
                        <div className="div-foto-psi">
                            <div className="div-avatar-psi">
                                {
                                    sinFoto === "1" ? (
                                        fotografia === "" ? (
                                            <div>
                                                <img id="myImage" alt="base64"></img>
                                                <label> Cargando imagen...</label>
                                            </div>
                                        ) : (
                                            <img id="myImage" src={fotografia} alt="base64"></img>
                                        )
                                    ) : (<div></div>)
                                }
                            </div>
                            <div className="div-label-psi">
                                <label> {informacion.nombre} {informacion.ap1} {informacion.ap2} </label>
                                <div id="container-toolTip-editar-psi">
                                    <span className="lbl-toolTip-editar-psi">
                                        Editar
                                    </span>
                                    <img src={img_edit} alt="edit" id="img_psi" onClick={editarRolPaciente} />
                                </div>
                            </div>
                        </div>
                        <table className="table-psi">
                            <tbody>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-edad" htmlFor="input-edad">Edad:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.edad}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-sexo" htmlFor="select-sexo">Sexo:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.sexo}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-escolaridad" htmlFor="input-escolaridad">Escolaridad:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.escolaridad}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-profesion" htmlFor="select-profesion">Profesión:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.profesion}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-trabajo" htmlFor="input-trabajo">Trabajo actual:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.trabajo_actual}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-prefsexual" htmlFor="select-prefSexual">Preferencia sexual:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.pref_sexual}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-edo-civil" htmlFor="select-edo-civil">Estado civil:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.edo_civil}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label>
                                    </td>
                                    <td className="psi-td-der" colSpan={3}>
                                        <label>{informacion.domicilio}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.ciudad}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-cp" htmlFor="input-cp">Código Postal:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.cod_pos}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-tel" htmlFor="input-tel">Teléfono:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.tel}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-correo" htmlFor="input-correo">Correo:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.correo}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <button className="div-psi-botones" onClick={cambiarContrasena}>Cambiar</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq">
                                        <label className="label-edo" htmlFor="select-edo">Estado:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.edo}</label>
                                    </td>
                                    <td className="psi-td-izq">
                                        <label className="label-pais" htmlFor="select-pais">País:</label>
                                    </td>
                                    <td className="psi-td-der">
                                        <label>{informacion.pais}</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="div-datos-hr">
                        <div>
                            <hr></hr>
                        </div>
                        <div>
                            <label> Psicólogo que atiende: </label>
                            <hr></hr>
                        </div>
                    </div>
                    <div className="div-datos-psi">
                        <div className="div-foto-psi">
                            <div className="div-avatar-psi">
                                {
                                    sinFotoPsi === "1" ? (
                                        fotografiaPsi === "" ? (
                                            <div>
                                                <img id="myImage" alt="base64"></img>
                                                <label> Cargando imagen...</label>
                                            </div>
                                        ) : (
                                            <img id="myImage" src={fotografiaPsi} alt="base64"></img>
                                        )
                                    ) : (<div></div>)
                                }
                            </div>
                            <div className="div-label-psi">
                                <label> {psicologo.nombre} {psicologo.ap1} {psicologo.ap2} </label>
                            </div>
                        </div>
                        <table className="table-psi">
                            <tbody>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-cedula" htmlFor="input-cedula">Cédula:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.cedula}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-titulo" htmlFor="select-titulo">Titulo:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.titulo}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-especialidad" htmlFor="input-especialidad">Especialidad actual:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.especialidad}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-tel" htmlFor="input-tel">Teléfono:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.tel}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-correo" htmlFor="input-correo">Correo:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.correo}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.ciudad}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-cp" htmlFor="input-cp">Código Postal:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.cod_pos}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-edo" htmlFor="select-edo">Estado:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.edo}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-pais" htmlFor="select-pais">País:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.pais}</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default RolPaciente;






