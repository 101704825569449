
import axios from "axios";

const PSICOLOGO_API_BASE_URL = "/server/psicologos";
const VPSICOLOGO_API_BASE_URL = "/server/vpsicologos";
const PACDELPSIC_API_BASE_URL = "/server/vPac_Psic";
const CREAR_PSIC_API_BASE_URL = "/server/psicologoNuevo";
const EDITAR_PSIC_API_BASE_URL = "/server/psicologoEditar";
const EDITCONTRASENA_PSIC_API_BASE_URL = "/server/psicologoEditarContrasena";


// const PSICOLOGO_API_BASE_URL = "http://localhost:3002/psicologos";
// const VPSICOLOGO_API_BASE_URL = "http://localhost:3002/vpsicologos";
// const PACDELPSIC_API_BASE_URL = "http://localhost:3002/vPac_Psic";
// const CREAR_PSIC_API_BASE_URL = "http://localhost:3002/psicologoNuevo";
// const EDITAR_PSIC_API_BASE_URL = "http://localhost:3002/psicologoEditar";
// const EDITCONTRASENA_PSIC_API_BASE_URL = "http://localhost:3002/psicologoEditarContrasena";

const Buffer = require('buffer/').Buffer;

class PsicologoService {

    /*Regresa usuario que tiene id_usuario que recibe por parametro*/
    /* dentro del paréntesis axios.get() no se puede dejar espacios, ejem: {URL} ? id_user, ya que es sentencia sql */
    /* PSICOLOGO_API_BASE_URL, contiene la sentencia: (SELECT * FROM psicologo WHERE fk_usuario = ?)
        y va a comparar fk_usuario, con el id_usuario que recibe: id_usuario=${id_usuario} */

    async getPsicologo(id_usuario) {
        const response = await axios.get(`${PSICOLOGO_API_BASE_URL}?id_usuario=${id_usuario}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PsiS getPsicologo: ', error);
            });
        const psicologo = response.data;
        return psicologo;
    }


    // obtener datos Psicologo de vpsicologo
    async getPsicologoIndividual(id_psicologo) {

        const response = await axios.get(`${VPSICOLOGO_API_BASE_URL}individual?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PsiS, getPsicologoIndividual: ', error);
            });

        const psicologo = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(psicologo[0].fotografia.data, 'binary');

        if (psicologo[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            psicologo[0].fotografia.data = imgBlob;
        } else if (psicologo[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            psicologo[0].fotografia.data = imgBlob;
        } else if (psicologo[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            psicologo[0].fotografia.data = imgBlob;
        }

        return psicologo;

    }


    async getPsicologos() {
        const response = await axios.get(VPSICOLOGO_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PsicologoService, Error al obtener los pacientes:', error);
            });
        const psicologo = response.data;
        return psicologo;
    }



    async createPsicologo(psicologo) {
        const response = await axios.post(CREAR_PSIC_API_BASE_URL, psicologo)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error crearPsicologo, al crear Psicólogo ', error);
            })
        const crearPsicologo = response.data;
        return crearPsicologo;
    }


    /****************************/

    async getPacDelPsicologo(id_psicologo) {
        const response = await axios.get(`${PACDELPSIC_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("PsicologoService, getPacDelPsicologo: ", error);
            });
        const pacDelPsic = response.data;
        return pacDelPsic;
    }


    async editarPsicologo(psicologo) {

        const response = await axios.put(EDITAR_PSIC_API_BASE_URL, psicologo)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PsiS editarPsicologo: ', error);
            })
        const editPsic = response.data;
        return editPsic;
    }


    // editar contraseña
    async editarContrasenaPsicologo(datos) {
        const response = await axios.put(EDITCONTRASENA_PSIC_API_BASE_URL, datos)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PsiS editarContrasenaPsicologo: ', error);
            })

        return response;
    }


}


export default new PsicologoService();