
import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import LayoutAddPxLogin from "../../containers/LayoutAddPxLogin";
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';

import "../../styles/FormularioAddUsuarioExpressPx.css"

import PsicologoService from "../../services/PsicologoService";
import UsuariosService from "../../services/UsuariosService";
import ExpedientesService from "../../services/ExpedientesService";
import PacientesService from "../../services/PacientesService";
import IngresoService from "../../services/IngresoService";



const AddUsuarioExpressPx = () => {

    const [psicologos, setPsicologos] = useState([]);
    const [idPsicologo, setIdPsicologo] = useState('');
    const [nomUsuario, setNomUsuario] = useState('');
    const [nombre, setNombre] = useState('');
    const [ap1, setAp1] = useState('');
    const [ap2, setAp2] = useState('');
    const [edad, setEdad] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [sexo, setSexo] = useState('');
    const [escolaridad, setEscolaridad] = useState('');
    const [profesion, setProfesion] = useState('');
    const [trabajoActual, setTrabajoActual] = useState('');
    const [prefSexual, setPrefSexual] = useState('');
    const [otraPref, setOtraPref] = useState('');
    const [tel, setTel] = useState('');
    const [correo, setCorreo] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [codpostal, setCodpostal] = useState(0);  // al crear el usuario, no puede ir vacío
    const [image, setImage] = useState('');
    const [fecha_ing, setFechaIngreso] = useState('');
    const [fecha_input, setFechaInput] = useState('');

    const [cambiarEstado, setCambiarEstado] = useState('0');
    const [cambiarPais, setCambiarPais] = useState('0');

    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }


    const getData = async () => {
        const data_psi = await PsicologoService.getPsicologos();
        setPsicologos(data_psi);
    }

    useEffect((
    ) => {
        getData();
        fechaIngreso();
    }, []);


    const fechaIngreso = () => {
        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        const fecha = anioHoy + "-" + mesHoy + "-" + diaHoy;
        setFechaIngreso(fecha);

        let dia, mes, anio;

        if (diaHoy > 0 && diaHoy < 10) {
            dia = "0" + diaHoy;
        } else {
            dia = diaHoy.toString();
        }

        if (mesHoy > 0 && mesHoy < 10) {
            mes = "0" + mesHoy;
        } else {
            mes = mesHoy.toString();
        }

        anio = anioHoy.toString()

        const f_aux = anio + "-" + mes + "-" + dia;
        setFechaInput(f_aux);

    }


    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const base64String = reader.result;
                        setImage(base64String);
                    };
                }
            }
            else {
                event.target.value = '';
                event.target.setCustomValidity('Solo se permiten archivos con extensión: .jpg, .jpeg, .png');
                event.target.reportValidity();      // es como un Alert
            }
        }
    }


    const quitarImg = () => {
        setImage('');
    }


    /* Datos Usuario */

    const handlePsicologo = (event) => {
        setIdPsicologo(event.target.value);
    }
    const handleNomUsuario = (event) => {
        setNomUsuario(event.target.value);
    }
    const handleNombre = (event) => {
        setNombre(event.target.value);
    }
    const handleAp1 = (event) => {
        setAp1(event.target.value);
    }
    const handleAp2 = (event) => {
        setAp2(event.target.value);
    }
    const handleEdad = (event) => {
        if (event.target.value !== "") {
            let edad = parseInt(event.target.value);
            if (edad > 0 && edad < 120) {
                setEdad(edad);
            } else {
                mostrarError("La edad ingresada es incorrecta.")
                edad = 0;
                setEdad(edad);
            }
        } else {
            setEdad(parseInt(0));
        }
    }
    const handleDomicilio = (event) => {
        setDomicilio(event.target.value);
    }
    const handleCiudad = (event) => {
        setCiudad(event.target.value);
    }
    const handleCodPostal = (event) => {
        if (event.target.value !== "") {
            setCodpostal(parseInt(event.target.value));
        } else {
            setCodpostal(parseInt(0));
        }
    }
    const handleEstado = (event) => {
        setCambiarEstado("1");
        setEstado(event.target.value);
    }
    const handlePais = (event) => {
        setCambiarPais("1");
        setPais(event.target.value);
    }
    const handleSexo = (event) => {
        setSexo(event.target.value);
    }
    const handleEscolaridad = (event) => {
        setEscolaridad(event.target.value);
    }
    const handleProfesion = (event) => {
        setProfesion(event.target.value);
    }
    const handleTrabajoActual = (event) => {
        setTrabajoActual(event.target.value);
    }
    const handlePrefSexual = (event) => {
        setPrefSexual(event.target.value);
    }
    const handleOtraPref = (event) => {
        let pref = event.target.value;
        if (pref !== '') {
            document.getElementById("select-prefSex-addPx").value = "elige";
        }
        setOtraPref(event.target.value);
    }
    const handleTel = (event) => {
        setTel(event.target.value);
    }
    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    }
    const handleContrasena = (event) => {
        setContrasena(event.target.value);
    }
    const handleSalir = (event) => {
        // navigate('/pacientes');  //local
        navigate('/');  //web
    }


    const handleSubmit = async () => {

        if (idPsicologo !== "Elige un psicólogo") {

            if (image !== "") {   // si se carga imagen de perfil

                let formato = "";
                if (image.includes("data:image/jpeg;base64,")) {
                    formato = "jpeg"
                } else if (image.includes("data:image/jpg;base64,")) {
                    formato = "jpg"
                } else if (image.includes("data:image/png;base64,")) {
                    formato = "png";
                }

                let edoCambiar = '';

                if (cambiarEstado === '0') {
                    edoCambiar = 'mi';
                } else {
                    edoCambiar = estado;
                }

                let paisCambiar = '';

                if (cambiarPais === '0') {
                    paisCambiar = 'MX';
                } else {
                    paisCambiar = pais;
                }

                // Ingreso al sistema

                let ingresoFecha = {
                    fecha_ingreso: fecha_ing,
                    fecha_inactivo: "",
                    fecha_eliminacion: ""
                }

                // crear ingreso
                const respuesta = await IngresoService.createIngreso(ingresoFecha);

                // Usuario del sistema
                let user = {
                    nom_usuario: nomUsuario,
                    nombre: nombre,
                    ap1: ap1,
                    ap2: ap2,
                    edad: edad,
                    sexo: sexo,
                    edo_civil: estado,
                    tel: tel,
                    domicilio: domicilio,
                    ciudad: ciudad,
                    cod_pos: codpostal,
                    edo: edoCambiar,
                    pais: paisCambiar,
                    correo: correo,
                    contrasena: contrasena,
                    rol: "pax",
                    estatus: "A",
                    fotografia: image,
                    formatoFoto: formato,
                    fk_ingreso: respuesta.data.insertId   // el último id_ingreso
                }

                console.log("obj user: ", user)

                if (user.edad > 0 && user.edad < 120) {

                    if (user.nom_usuario !== "" &&
                        user.nombre !== "" &&
                        user.ap1 !== "" &&
                        user.ap2 !== "" &&
                        user.tel !== "" &&
                        user.contrasena !== "" &&
                        user.rol !== "" &&
                        user.estatus !== "" &&
                        user.fk_ingreso !== "") {

                        await UsuariosService.createUsuario(user);    // crear usuario

                        const ultimoUsuario = await UsuariosService.getUltimoUsuario();

                        let exp = {
                            pruebas: '',
                            sugerencias: '',
                            hist_medico: '',
                            exp_significativa: '',
                            familiograma: '',
                            diagnostico: ''
                        }

                        await ExpedientesService.createExpediente(exp);   // crear expediente

                        const ultimoExpediente = await ExpedientesService.getUltimoExpediente();

                        // paciente del sistema relacionado con el usuario
                        if (otraPref !== '') {
                            let paciente = {
                                escolaridad: escolaridad,
                                profesion: profesion,
                                trabajo_actual: trabajoActual,
                                pref_sexual: otraPref,
                                fk_usuario: ultimoUsuario.id_usuario,
                                fk_psicologo: idPsicologo,
                                fk_expediente: ultimoExpediente.id_expediente
                            }

                            console.log("obj otraPref: ", paciente)

                            await PacientesService.createPaciente(paciente);  // crear paciente

                            Toast.fire("¡El paciente se registró correctamente!", "", "success")
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        //navigate('/');  //web
                                    }
                                }).then(function () {
                                    //navigate('/');  //web
                                });

                        } else {

                            let paciente = {
                                escolaridad: escolaridad,
                                profesion: profesion,
                                trabajo_actual: trabajoActual,
                                pref_sexual: prefSexual,
                                fk_usuario: ultimoUsuario.id_usuario,
                                fk_psicologo: idPsicologo,
                                fk_expediente: ultimoExpediente.id_expediente
                            }

                            console.log("sin otraPref: ", paciente)

                            await PacientesService.createPaciente(paciente);  // crear paciente

                            Toast.fire("¡El paciente se registró correctamente!", "", "success")
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        //navigate('/');  //web
                                    }
                                }).then(function () {
                                    //navigate('/');  //web
                                });
                        }
                    } else {
                        mostrarError("Es importante llenar los campos: nombre de usuario, apellidos, edad y teléfono.");
                    }
                } else {
                    mostrarError("La edad ingresada es incorrecta.");
                }

            } else if (image === "") {    // si NO se carga imagen de perfil

                let edoCambiar = '';

                if (cambiarEstado === '0') {
                    edoCambiar = 'mi';
                } else {
                    edoCambiar = estado;
                }

                let paisCambiar = '';

                if (cambiarPais === '0') {
                    paisCambiar = 'MX';
                } else {
                    paisCambiar = pais;
                }


                let ingresoFecha = {
                    fecha_ingreso: fecha_ing,
                    fecha_inactivo: "",
                    fecha_eliminacion: ""
                }

                // crear ingreso
                const respuesta = await IngresoService.createIngreso(ingresoFecha);


                // usuario del sistema 
                let user = {
                    nom_usuario: nomUsuario,
                    nombre: nombre,
                    ap1: ap1,
                    ap2: ap2,
                    edad: edad,
                    sexo: sexo,
                    edo_civil: estado,
                    tel: tel,
                    domicilio: domicilio,
                    ciudad: ciudad,
                    cod_pos: codpostal,
                    edo: edoCambiar,
                    pais: paisCambiar,
                    correo: correo,
                    contrasena: contrasena,
                    rol: "pax",
                    estatus: "A",
                    fotografia: "",
                    formatoFoto: "",
                    fk_ingreso: respuesta.data.insertId   // el último id_ingreso
                }

                if (user.edad > 0 && user.edad < 120) {

                    if (user.nom_usuario !== "" &&
                        user.nombre !== "" &&
                        user.ap1 !== "" &&
                        user.ap2 !== "" &&
                        user.tel !== "" &&
                        user.contrasena !== "" &&
                        user.rol !== "" &&
                        user.estatus !== "" &&
                        user.fk_ingreso !== "") {

                        console.log("obj user: ", user)

                        await UsuariosService.createUsuario(user);  // crear usuario

                        const ultimoUsuario = await UsuariosService.getUltimoUsuario();

                        let exp = {
                            pruebas: '',
                            sugerencias: '',
                            hist_medico: '',
                            exp_significativa: '',
                            familiograma: '',
                            diagnostico: ''
                        }

                        console.log("obj exp: ", exp)

                        await ExpedientesService.createExpediente(exp);

                        const ultimoExpediente = await ExpedientesService.getUltimoExpediente();

                        if (otraPref !== '') {
                            let paciente = {
                                escolaridad: escolaridad,
                                profesion: profesion,
                                trabajo_actual: trabajoActual,
                                pref_sexual: otraPref,
                                fk_usuario: ultimoUsuario.id_usuario,
                                fk_psicologo: idPsicologo,
                                fk_expediente: ultimoExpediente.id_expediente
                            }

                            console.log("obj paciente-ConprefSx: ", paciente)

                            await PacientesService.createPaciente(paciente);  // crear paciente

                            Toast.fire("¡El paciente se registró correctamente!", "", "success")
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        //navigate('/');  //web
                                    }
                                }).then(function () {
                                    //navigate('/');  //web
                                });

                        } else {

                            let paciente = {
                                escolaridad: escolaridad,
                                profesion: profesion,
                                trabajo_actual: trabajoActual,
                                pref_sexual: prefSexual,
                                fk_usuario: ultimoUsuario.id_usuario,
                                fk_psicologo: idPsicologo,
                                fk_expediente: ultimoExpediente.id_expediente
                            }

                            console.log("obj paciente-SinprefSx: ", paciente)

                            await PacientesService.createPaciente(paciente);  // crear paciente

                            Toast.fire("¡El paciente se registró correctamente!", "", "success")
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        //navigate('/');  //web
                                    }
                                }).then(function () {
                                    //navigate('/');  //web
                                });
                        }

                    } else {
                        mostrarError("Es importante llenar los campos: nombre de usuario, apellidos, contraseña y teléfono.");
                    }

                } else {
                    mostrarError("La edad ingresada es incorrecta.");
                }
            }

        } else {
            mostrarError("Es necesario elegir un psicólogo.");
        }
    }

    return (
        <LayoutAddPxLogin>
            <Content>
                <div className="div-datos-addUsPx">
                    <div className="div-addPx-headerUs">
                        <h1>Bienvenido: ingresa tus datos</h1>
                        <br></br>
                        <p>* Es importante que todos los campos estén contestados.</p>
                        <br></br>
                    </div>
                    <div className="div_content_foto_addUsPx">
                        <div className="div-foto-px-addUsPx">
                            {image && <img alt="vista previa" className="vista-previa" src={image} />}
                        </div>
                        <div className="div_btn_foto_addUsPx">
                            <input type="file" onChange={onImageChange} className="filetype" accept=".jpg, .jpeg, .png" />
                        </div>
                        <div className="div_btn_quitar_addUsPx">
                            <button id="btn_quitarImg_addPac" onClick={quitarImg}>
                                Quitar imagen
                            </button>
                        </div>
                    </div>
                    <div className="div-Select-table-addUsPx">
                        <div className="div-tablePsi-addUsPx">
                            <div className="div-lbl-addUsPx">
                                <label className="label-psicologo" htmlFor="select-psicologo">Psicólogo:</label>
                            </div>
                            <div className="div-Select-addUsPx">
                                <select id="select-psicologo" onChange={handlePsicologo}>
                                    <option disabled selected> Elige un psicólogo </option>
                                    {psicologos.map((psicologo) =>
                                        <option value={psicologo.id_psicologo}>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="div-table-addUsPx">
                            <table className="table-addUsPx">
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-nom-usu" htmlFor="input-nom-usu">Usuario:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-nom-usu" placeholder="Nombre de usuario" onChange={handleNomUsuario}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-paciente" htmlFor="input-nombre">Paciente:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-nombre" placeholder="Nombre" onChange={handleNombre}></input>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-ap1" placeholder="Apellido paterno" onChange={handleAp1}></input>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-ap2" placeholder="Apellido materno" onChange={handleAp2}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-edad" htmlFor="label-fecha_ingreso">Fecha de ingreso:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="date" defaultValue={fecha_input} disabled></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-correo" htmlFor="input-correo">Correo:</label>
                                    </td>
                                    <td className="add-pxUs-colum2" colSpan={2}>
                                        <input type="email" id="input-correo" placeholder="Correo electrónico" onChange={handleCorreo}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="pass" id="input-contrasena" placeholder="Contraseña" onChange={handleContrasena}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-edad" htmlFor="input-edad">Edad:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="number" id="input-edad" onChange={handleEdad}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-tel" htmlFor="input-tel">Teléfono:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="tel" id="input-tel" onChange={handleTel}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-sexo" htmlFor="select-sexo">Sexo:</label>
                                    </td>
                                    <td className="add-pxUs-colum2" colSpan={2}>
                                        <div className="radio-container">
                                            <label>
                                                <input type="radio" id="masculino" name="sexo" value="m" onChange={handleSexo} />
                                                <span>
                                                    Masculino
                                                </span>
                                            </label>
                                            <label>
                                                <input type="radio" id="femenino" name="sexo" value="f" onChange={handleSexo} />
                                                <span>
                                                    Femenino
                                                </span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-pref_sex" htmlFor="select-prefSex-addPx">Preferencia sexual:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <select id="select-prefSex-addPx" defaultValue="elige" disabled={otraPref !== ''} onChange={handlePrefSexual}>
                                            <option value="elige" disabled>Elige una opción</option>
                                            <option value="heterosexual">Heterosexual</option>
                                            <option value="homosexual">Homosexual</option>
                                            <option value="bisexual">Bisexual</option>
                                            <option value="pansexual">Pansexual</option>
                                            <option value="asexual">Asexual</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-pref_sex" htmlFor="input-otraPref-addPx">Otra preferencia:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-otraPref-addPx" placeholder="Otra preferencia" onChange={handleOtraPref}></input></td>
                                </tr>
                                <tr className="add-pxUs-tr">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-escolaridad" htmlFor="select-escolaridad">Escolaridad:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <select id="select-escolaridad-addPx" defaultValue="elige" onChange={handleEscolaridad}>
                                            <option value="elige" disabled>Elige una opción</option>
                                            <option value="primaria">Primaria</option>
                                            <option value="secundaria">Secundaria</option>
                                            <option value="preparatoria">Preparatoria</option>
                                            <option value="universidad">Universidad</option>
                                            <option value="posgrado">Posgrado</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-profesion" htmlFor="input-profesion">Profesion / oficio:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-profesion" placeholder="Profesión" onChange={handleProfesion}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-trabajo" htmlFor="input-trabajo">Trabajo actual:</label></td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-trabajo" placeholder="Trabajo actual" onChange={handleTrabajoActual}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                    <td className="add-pxUs-colum2" colSpan={3}>
                                        <input type="text" id="input-domicilio" placeholder="Calle, No. Colonia..." onChange={handleDomicilio}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="text" id="input-ciudad" placeholder="Ciudad, localidad..." onChange={handleCiudad}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-cp" htmlFor="input-cp">Código Postal:</label>
                                    </td>
                                    <td className="add-pxUs-colum2">
                                        <input type="number" id="input-cp" onChange={handleCodPostal}></input>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-edo" htmlFor="select-edo">Estado:</label></td>
                                    <td className="add-pxUs-colum2">
                                        <select defaultValue="mi" onChange={handleEstado} required>
                                            <option value="ext">Extranjero</option>
                                            <hr></hr>
                                            <option value="a">Aguascalientes</option>
                                            <option value="b">Baja California</option>
                                            <option value="bs">Baja California Sur</option>
                                            <option value="c">Campeche</option>
                                            <option value="ch">Chiapas</option>
                                            <option value="chi">Chihuahua</option>
                                            <option value="co">Coahuila</option>
                                            <option value="col">Colima</option>
                                            <option value="cd">Ciudad de México</option>
                                            <option value="d">Durango</option>
                                            <option value="em">Estado de México</option>
                                            <option value="g">Guanajuato</option>
                                            <option value="gu">Guerrero</option>
                                            <option value="h">Hidalgo</option>
                                            <option value="j">Jalisco</option>
                                            <option value="mi">Michoacán</option>
                                            <option value="mo">Morelos</option>
                                            <option value="n">Nayarit</option>
                                            <option value="nl">Nuevo León</option>
                                            <option value="o">Oaxaca</option>
                                            <option value="p">Puebla</option>
                                            <option value="q">Querétaro</option>
                                            <option value="qr">Quintana Roo</option>
                                            <option value="sn">San Luis Potosí</option>
                                            <option value="s">Sinaloa</option>
                                            <option value="so">Sonora</option>
                                            <option value="t">Tabasco</option>
                                            <option value="ta">Tamaulipas</option>
                                            <option value="tl">Tlaxcala</option>
                                            <option value="v">Veracruz</option>
                                            <option value="y">Yucatán</option>
                                            <option value="z">Zacatecas</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className="add-pxUs-tr1">
                                    <td className="add-pxUs-colum1">
                                        <label className="label-pais" htmlFor="select-pais">País:</label></td>
                                    <td className="add-pxUs-colum2">
                                        <select defaultValue="MX" onChange={handlePais}>
                                            <option value="AF">Afganistán</option>
                                            <option value="AL">Albania</option>
                                            <option value="DE">Alemania</option>
                                            <option value="AD">Andorra</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">Antártida</option>
                                            <option value="AG">Barbuda</option>
                                            <option value="AN">Holandesas</option>
                                            <option value="SA">Arabia Saudí</option>
                                            <option value="DZ">Argelia</option>
                                            <option value="AR">Argentina</option>
                                            <option value="AM">Armenia</option>
                                            <option value="AW">Aruba</option>
                                            <option value="AU">Australia</option>
                                            <option value="AT">Austria</option>
                                            <option value="AZ">Azerbaiyán</option>
                                            <option value="BS">Bahamas</option>
                                            <option value="BH">Bahrein</option>
                                            <option value="BD">Bangladesh</option>
                                            <option value="BB">Barbados</option>
                                            <option value="BE">Bélgica</option>
                                            <option value="BZ">Belice</option>
                                            <option value="BJ">Benin</option>
                                            <option value="BM">Bermudas</option>
                                            <option value="BY">Bielorrusia</option>
                                            <option value="MM">Birmania</option>
                                            <option value="BO">Bolivia</option>
                                            <option value="BA">Bosnia</option>
                                            <option value="BW">Botswana</option>
                                            <option value="BR">Brasil</option>
                                            <option value="BN">Brunei</option>
                                            <option value="BG">Bulgaria</option>
                                            <option value="BF">Burkina Faso</option>
                                            <option value="BI">Burundi</option>
                                            <option value="BT">Bután</option>
                                            <option value="CV">Cabo Verde</option>
                                            <option value="KH">Camboya</option>
                                            <option value="CM">Camerún</option>
                                            <option value="CA">Canadá</option>
                                            <option value="TD">Chad</option>
                                            <option value="CL">Chile</option>
                                            <option value="CN">China</option>
                                            <option value="CY">Chipre</option>
                                            <option value="VA">Vaticano</option>
                                            <option value="CO">Colombia</option>
                                            <option value="KM">Comores</option>
                                            <option value="CG">Congo</option>
                                            <option value="CD">Congo</option>
                                            <option value="KR">Corea</option>
                                            <option value="KP">Corea del Norte</option>
                                            <option value="CI">Costa de Marfíl</option>
                                            <option value="CR">Costa Rica</option>
                                            <option value="HR">Croacia</option>
                                            <option value="CU">Cuba</option>
                                            <option value="DK">Dinamarca</option>
                                            <option value="DJ">Djibouti</option>
                                            <option value="DM">Dominica</option>
                                            <option value="EC">Ecuador</option>
                                            <option value="EG">Egipto</option>
                                            <option value="SV">El Salvador</option>
                                            <option value="AE">Emiratos Árabes U.</option>
                                            <option value="ER">Eritrea</option>
                                            <option value="SI">Eslovenia</option>
                                            <option value="ES">España</option>
                                            <option value="US">Estados Unidos</option>
                                            <option value="EE">Estonia</option>
                                            <option value="ET">Etiopía</option>
                                            <option value="FJ">Fiji</option>
                                            <option value="PH">Filipinas</option>
                                            <option value="FI">Finlandia</option>
                                            <option value="FR">Francia</option>
                                            <option value="GA">Gabón</option>
                                            <option value="GM">Gambia</option>
                                            <option value="GE">Georgia</option>
                                            <option value="GH">Ghana</option>
                                            <option value="GI">Gibraltar</option>
                                            <option value="GD">Granada</option>
                                            <option value="GR">Grecia</option>
                                            <option value="GL">Groenlandia</option>
                                            <option value="GP">Guadalupe</option>
                                            <option value="GU">Guam</option>
                                            <option value="GT">Guatemala</option>
                                            <option value="GY">Guayana</option>
                                            <option value="GF">Guayana Francesa</option>
                                            <option value="GN">Guinea</option>
                                            <option value="GQ">Guinea Ecuatorial</option>
                                            <option value="GW">Guinea-Bissau</option>
                                            <option value="HT">Haití</option>
                                            <option value="HN">Honduras</option>
                                            <option value="HU">Hungría</option>
                                            <option value="IN">India</option>
                                            <option value="ID">Indonesia</option>
                                            <option value="IQ">Irak</option>
                                            <option value="IR">Irán</option>
                                            <option value="IE">Irlanda</option>
                                            <option value="BV">Isla Bouvet</option>
                                            <option value="CX">Isla de Christmas</option>
                                            <option value="IS">Islandia</option>
                                            <option value="KY">Islas Caimán</option>
                                            <option value="CK">Islas Cook</option>
                                            <option value="CC">Islas de Cocos</option>
                                            <option value="FO">Islas Faroe</option>
                                            <option value="HM">Islas Heard</option>
                                            <option value="FK">Islas Malvinas</option>
                                            <option value="MP">Islas Marianas</option>
                                            <option value="MH">Islas Marshall</option>
                                            <option value="PW">Islas Palau</option>
                                            <option value="SB">Islas Salomón</option>
                                            <option value="SJ">Islas Svalbard</option>
                                            <option value="TK">Islas Tokelau</option>
                                            <option value="TC">Islas Turks</option>
                                            <option value="IL">Israel</option>
                                            <option value="IT">Italia</option>
                                            <option value="JM">Jamaica</option>
                                            <option value="JP">Japón</option>
                                            <option value="JO">Jordania</option>
                                            <option value="KZ">Kazajistán</option>
                                            <option value="KE">Kenia</option>
                                            <option value="KG">Kirguizistán</option>
                                            <option value="KI">Kiribati</option>
                                            <option value="KW">Kuwait</option>
                                            <option value="LA">Laos</option>
                                            <option value="LS">Lesotho</option>
                                            <option value="LV">Letonia</option>
                                            <option value="LB">Líbano</option>
                                            <option value="LR">Liberia</option>
                                            <option value="LY">Libia</option>
                                            <option value="LI">Liechtenstein</option>
                                            <option value="LT">Lituania</option>
                                            <option value="LU">Luxemburgo</option>
                                            <option value="MK">Macedonia</option>
                                            <option value="MG">Madagascar</option>
                                            <option value="MY">Malasia</option>
                                            <option value="MW">Malawi</option>
                                            <option value="MV">Maldivas</option>
                                            <option value="ML">Malí</option>
                                            <option value="MT">Malta</option>
                                            <option value="MA">Marruecos</option>
                                            <option value="MQ">Martinica</option>
                                            <option value="MU">Mauricio</option>
                                            <option value="MR">Mauritania</option>
                                            <option value="YT">Mayotte</option>
                                            <option value="MX">México</option>
                                            <option value="FM">Micronesia</option>
                                            <option value="MD">Moldavia</option>
                                            <option value="MC">Mónaco</option>
                                            <option value="MN">Mongolia</option>
                                            <option value="MS">Montserrat</option>
                                            <option value="MZ">Mozambique</option>
                                            <option value="NA">Namibia</option>
                                            <option value="NR">Nauru</option>
                                            <option value="NP">Nepal</option>
                                            <option value="NI">Nicaragua</option>
                                            <option value="NE">Níger</option>
                                            <option value="NG">Nigeria</option>
                                            <option value="NU">Niue</option>
                                            <option value="NF">Norfolk</option>
                                            <option value="NO">Noruega</option>
                                            <option value="NC">Nueva Caledonia</option>
                                            <option value="NZ">Nueva Zelanda</option>
                                            <option value="OM">Omán</option>
                                            <option value="NL">Países Bajos</option>
                                            <option value="PA">Panamá</option>
                                            <option value="PG">Papúa Nueva Guinea</option>
                                            <option value="PK">Paquistán</option>
                                            <option value="PY">Paraguay</option>
                                            <option value="PE">Perú</option>
                                            <option value="PN">Pitcairn</option>
                                            <option value="PF">Polinesia Francesa</option>
                                            <option value="PL">Polonia</option>
                                            <option value="PT">Portugal</option>
                                            <option value="PR">Puerto Rico</option>
                                            <option value="QA">Qatar</option>
                                            <option value="UK">Reino Unido</option>
                                            <option value="CF">Rep. Centroafricana</option>
                                            <option value="CZ">Rep. Checa</option>
                                            <option value="ZA">Rep. de Sudáfrica</option>
                                            <option value="DO">Rep. Dominicana</option>
                                            <option value="SK">Rep. Eslovaca</option>
                                            <option value="RE">Reunión</option>
                                            <option value="RW">Ruanda</option>
                                            <option value="RO">Rumania</option>
                                            <option value="RU">Rusia</option>
                                            <option value="EH">Sahara Occidental</option>
                                            <option value="KN">Saint Kitts</option>
                                            <option value="WS">Samoa</option>
                                            <option value="AS">Samoa Americana</option>
                                            <option value="SM">San Marino</option>
                                            <option value="VC">San Vicente</option>
                                            <option value="SH">Santa Helena</option>
                                            <option value="LC">Santa Lucía</option>
                                            <option value="ST">Santo Tomé</option>
                                            <option value="SN">Senegal</option>
                                            <option value="SC">Seychelles</option>
                                            <option value="SL">Sierra Leona</option>
                                            <option value="SG">Singapur</option>
                                            <option value="SY">Siria</option>
                                            <option value="SO">Somalia</option>
                                            <option value="LK">Sri Lanka</option>
                                            <option value="PM">St Pierre</option>
                                            <option value="SZ">Suazilandia</option>
                                            <option value="SD">Sudán</option>
                                            <option value="SE">Suecia</option>
                                            <option value="CH">Suiza</option>
                                            <option value="SR">Surinam</option>
                                            <option value="TH">Tailandia</option>
                                            <option value="TW">Taiwán</option>
                                            <option value="TZ">Tanzania</option>
                                            <option value="TJ">Tayikistán</option>
                                            <option value="TP">Timor Oriental</option>
                                            <option value="TG">Togo</option>
                                            <option value="TO">Tonga</option>
                                            <option value="TT">Trinidad y Tobago</option>
                                            <option value="TN">Túnez</option>
                                            <option value="TM">Turkmenistán</option>
                                            <option value="TR">Turquía</option>
                                            <option value="TV">Tuvalu</option>
                                            <option value="UA">Ucrania</option>
                                            <option value="UG">Uganda</option>
                                            <option value="UY">Uruguay</option>
                                            <option value="UZ">Uzbekistán</option>
                                            <option value="VU">Vanuatu</option>
                                            <option value="VE">Venezuela</option>
                                            <option value="VN">Vietnam</option>
                                            <option value="YE">Yemen</option>
                                            <option value="YU">Yugoslavia</option>
                                            <option value="ZM">Zambia</option>
                                            <option value="ZW">Zimbabue</option>
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="div-btn-registroUs">
                        <div className="div-addUsPx-botones">
                            <div className="addUsPx-btn-enviar">
                                <button value="enviar" id="btn-enviar-addPx" onClick={handleSubmit}>Enviar</button>
                            </div>
                            <div className="addUsPx-btn-cancelar">
                                <button value="cancelar" id="btn-cancelar-addPx" onClick={handleSalir}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </LayoutAddPxLogin>
    )
}

export default AddUsuarioExpressPx;


