
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import FormularioCambiarContrasena from "../Psicologo/FormularioCambiarContrasena";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Psicologo.css";

import img_exped from "../../img/folder_shared.svg";
import img_edit from "../../img/edit_note.svg";
import ExpedientesService from "../../services/ExpedientesService";


const Psicologo = (id) => {
    const [pacientes, setPacientes] = useState([]);
    const [psicologo, setPsicologo] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [estatusPac, setEstatusPaciente] = useState("");
    const [informacion, setInformacion] = useState([]);

    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const MySwal = withReactContent(Swal);


    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            timer: 6000,
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacionPsicologo(parseInt(routeParams.id));
            cargarInformacionPacientes(parseInt(routeParams.id));
        } else {
            alert("Error al cargar la página.")
        }
    };


    useEffect(() => {
        getData();
    }, [])

    const cargarInformacionPsicologo = async (id_psicologo) => {

        const data = await PsicologoService.getPsicologoIndividual(id_psicologo);
        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInputValue(data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2);

        setPsicologo(data[0]);
    };


    // Todos los pacientes peretenecientes al psicologo
    const cargarInformacionPacientes = async (id_psicologo) => {

        const data = await PacientesService.getPacientes(id_psicologo);
        setPacientes(data);

    };

    const cargarInformacion = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInputValue(data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2);
        setEstatusPaciente(data[0].estatus);

    }


    const modalEliminarPac = async (id_paciente) => {

        const dataPac = await PacientesService.getInfoPaciente(id_paciente);

        MySwal.fire({
            title: 'El paciente será eliminado del sistema.',
            html: (
                <div className="datos-px-modal-eliminar">
                    <div>
                        {
                            (dataPac[0].fotografia.data).length !== 0 ? (
                                <div className="div-avatar-eliminar">
                                    <div className="div-imgPac-eliminar">
                                        <img src={dataPac[0].fotografia.data} alt="base64"></img>
                                    </div>
                                    <div className="div-labelPac-eliminar">
                                        <a href={`#/pacientes/${dataPac[0].id_paciente}`}>
                                            <label>{dataPac[0].nombre} {dataPac[0].ap1} {dataPac[0].ap2}</label>
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div className="div-avatar-eliminar">
                                    <div className="div-labelPac-eliminar">
                                        <label>{dataPac[0].nombre} {dataPac[0].ap1} {dataPac[0].ap2}</label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <table className="modal-pax-eliminar">
                        <tbody>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Edad:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].edad}</label></td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Trabajo actual:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].trabajo_actual}</label></td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Telefono:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].tel}</label></td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Ciudad:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].ciudad}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ),
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarPacienteSys(dataPac[0].id_usuario, dataPac[0].id_paciente);
            }
        });

    }



    const eliminarPacienteSys = async (id_usuario, id_paciente) => {

        mostrarAdvertencia("El paciente sólo puede eliminarse si tiene más de 5 años en el sistema.")

        const data = await ExpedientesService.getExpediente(id_paciente);

        const usua = await PacientesService.eliminarPacienteUsaurio(id_usuario);

        if (usua.status === 200) {
            const exped = await PacientesService.eliminarPacienteExped(data[0].id_expediente);

            if (exped.status === 200) {
                const pac = await PacientesService.eliminarPaciente(id_paciente);

                if (pac.status === 200) {

                    Toast.fire("¡El paciente se eliminó correctamente!", "", "success")
                        .then((result) => {
                            if (result.isConfirmed) {
                                // navigate('/pacientes');  //local
                                navigate(`/psicologo/${usuario.id_psicologo}`);  //web
                                refreshPage();
                            }
                        }).then(function () {
                            // navigate('/pacientes');  //local
                            navigate(`/psicologo/${usuario.id_psicologo}`);  //web
                            refreshPage();
                        });

                } else {
                    mostrarError("Error al eliminar usuario.");
                }
            } else {
                mostrarError("Error al eliminar expediente.");
            }
        } else {
            mostrarError("Error al eliminar paciente.");
        }
    }


    // Pacciente Activos
    const DisplayData = pacientes.map((info, index) => {
        return (
            info.estatus === "A" ? (
                <div key={index}>
                    <table className="table-displays-psi">
                        <tbody>
                            <tr id="tr-psi">
                                <td id="td_1-psi-a">
                                    <label>{info.nombre} {info.ap1} {info.ap2}</label>
                                </td>
                                <td id="td_2-psi-a">
                                    <label>{info.estatus}</label>
                                </td>
                                <td id="td_3-psi-a">
                                    <a href={`#/expediente/${info.id_paciente}`}><img id="img_exped" src={img_exped} alt="img_exped"></img>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (<></>)
        )
    })


    // Pacientes Inactivos
    const DisplayData2 = pacientes.map((info, index) => {
        return (
            info.estatus === "I" ? (
                <div key={index}>
                    <table>
                        <tbody>
                            <tr id="tr-psi">
                                <td id="td_1-psi-i">
                                    <label>{info.nombre} {info.ap1} {info.ap2}</label>
                                </td>
                                <td id="td_2-psi-i">
                                    <label>{info.estatus}</label>
                                </td>
                                <td id="td_3-i">
                                    <a href={`#/expediente/${info.id_paciente}`}><img id="img_exped" src={img_exped} alt="img_exped"></img>
                                    </a>
                                </td>
                                <td id="td_4-psi-i">
                                    <button id="liga_psi" onClick={() => modalEliminarPac(info.id_paciente)}>Eliminar del sistema</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (<></>)
        )
    })




    const editarPsicologo = () => {
        navigate(`/editpsicologo/${usuario.id_psicologo}`);
    }


    const cambiarContrasena = async () => {

        const data = await PsicologoService.getPsicologoIndividual(usuario.id_psicologo);

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioCambiarContrasena usuario={data} />
        );
        Swal.fire({
            title: "Cambiar contraseña",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const contrasenaActual = document.getElementById("input-contrasenaActual").value;
                const contrasenaNueva = document.getElementById("input-contrasenaNueva").value;
                const id_usuario = data[0].id_usuario;

                if (contrasenaActual !== data[0].contrasena) {
                    mostrarError("La contraseña actual no es correcta.");
                } else if (contrasenaNueva === data[0].contrasena) {
                    mostrarError("No se puede repetir una contraseña actual.");
                } else {
                    return { contrasenaNueva, id_usuario };
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                putCambiarContrasena(datos);
                refreshPage();
            } else if (result.dismiss === 'cancel') {
                navigate(`/psicologo/${usuario.id_psicologo}`);
            }
        });
    }


    const putCambiarContrasena = async (datos) => {

        let data = {
            contrasena: datos.contrasenaNueva,
            id_usuario: datos.id_usuario,
        }

        const respuesta = await PsicologoService.editarContrasenaPsicologo(data);

        if (respuesta.status === 200) {

            Toast.fire("¡La contraseña se actualizó correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    navigate(`/psicologo`);
                }
            }).then(function () {
                navigate(`/psicologo`);
            });

        } else {
            mostrarError("Error al cambiar contraseña.");
        }
    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="psi_div_1">
                    <div className="psi_div_2">
                        <div className="psi_sesion">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp;</label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_psi">
                            <label>Psicólogo</label>
                        </div>
                    </div>
                    <div className="div-datos-psi">
                        <div className="div-foto-psi">
                            <div className="div-avatar-psi">
                                {
                                    sinFoto === "1" ? (
                                        fotografia === "" ? (
                                            <div>
                                                <img id="myImage" alt="base64"></img>
                                                <label> Cargando imagen...</label>
                                            </div>
                                        ) : (
                                            <img id="myImage" src={fotografia} alt="base64"></img>
                                        )
                                    ) : (<div></div>)
                                }
                            </div>
                            <div className="div-label-psi">
                                <label> {psicologo.nombre} {psicologo.ap1} {psicologo.ap2} </label>
                                <div id="container-toolTip-editar-psi">
                                    <span className="lbl-toolTip-editar-psi">
                                        Editar
                                    </span>
                                    <img src={img_edit} alt="edit" id="img_psi" onClick={editarPsicologo} />
                                </div>
                            </div>
                        </div>
                        <div className="div-table-label-psi">
                            <label>Datos personales:</label>
                        </div>
                        <div className="div-table-psi">
                            <table className="table-psi">
                                <tbody>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-edad" htmlFor="input-edad">Edad:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.edad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-sexo" htmlFor="select-sexo">Sexo:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.sexo}</label>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td className="psi-td-izq"><label className="label-edo-civil" htmlFor="select-edo-civil">Estado civil:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.edo_civil}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                        <td className="psi-td-der" colSpan={3}>
                                            <label>{psicologo.domicilio}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.ciudad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-cp" htmlFor="input-cp">Código Postal:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.cod_pos}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-tel" htmlFor="input-tel">Teléfono:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.tel}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-correo" htmlFor="input-correo">Correo:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.correo}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label></td>
                                        <td className="psi-td-der">
                                            <button className="div-psi-botones" onClick={cambiarContrasena}>Cambiar</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-escolaridad" htmlFor="select-escolaridad">Escolaridad:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.escolaridad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-cedula" htmlFor="input-cedula">Cédula:</label></td>
                                        <td className="psi-td-der" colSpan={2}>
                                            <label>{psicologo.cedula}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-especialidad" htmlFor="input-especialidad">Especialidad actual:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.especialidad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-titulo" htmlFor="select-titulo">Titulo:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.titulo}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-edo" htmlFor="select-edo">Estado:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.edo}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-pais" htmlFor="select-pais">País:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.pais}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="div-displays-psic">
                        <div className="div-displays-pAct">
                            <div className="div-displays-pAct-label">
                                <label>Pacientes en proceso: Activo</label>
                            </div>
                            <div className="div-displaysAct">
                                {DisplayData}
                            </div>
                        </div>
                        <div className="div-displays-pInAct">
                            <div className="div-displays-pInAct-label">
                                <label>Pacientes con proceso finalizado: Inactivo</label>
                            </div>
                            <div className="div-displaysInAct">
                                {DisplayData2}
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Psicologo;
