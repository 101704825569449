
import axios from "axios";

const COLEGA_API_BASE_URL = "/server/colega";
const CANALIZAR_API_BASE_URL = "/server/colegas";
const CREAR_COLEGA_API_BASE_URL = "/server/addColega";
const EDITAR_COLEGA_API_BASE_URL = "/server/colegaEdit";
const COLEGA_ATIENDE_PX = "/server/atiendePacientes";


// const COLEGA_API_BASE_URL = "http://localhost:3002/colega";
// const CANALIZAR_API_BASE_URL = "http://localhost:3002/colegas";
// const CREAR_COLEGA_API_BASE_URL = "http://localhost:3002/addColega";
// const EDITAR_COLEGA_API_BASE_URL = "http://localhost:3002/colegaEdit";
// const COLEGA_ATIENDE_PX = "http://localhost:3002/atiendePacientes";


const Buffer = require('buffer/').Buffer;


class ColegasService {

    async getInfoColega(id_colega) {
        const response = await axios.get(`${COLEGA_API_BASE_URL}?id_colega=${id_colega}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getColega: ', error);
            });
        const colega = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(colega[0].fotografia.data, 'binary');

        if (colega[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            colega[0].fotografia.data = imgBlob;
        } else if(colega[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            colega[0].fotografia.data = imgBlob;
        } else if(colega[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            colega[0].fotografia.data = imgBlob;
        }

        return colega;
    }

    async getColegas(id_psicologo) {
        const response = await axios.get(`${CANALIZAR_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getColegas vcanalizar: ', error);
            });
        const colegas = response.data;
        return colegas;
    }


    async getColegasTodos() {
        const response = await axios.get(`${CANALIZAR_API_BASE_URL}Todos?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getColegasTodos vcanalizar: ', error);
            });
        const colegas = response.data;
        return colegas;
    }


    async createColega(colega) {
        const response = await axios.post(CREAR_COLEGA_API_BASE_URL, colega)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error("CS createColega: ", error);
        })
        const colegaNuevo = response.data;
        return colegaNuevo;
    }


    async editarColega(colega) {
        const response = await axios.put(EDITAR_COLEGA_API_BASE_URL, colega)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error("CS editarColega: ", error);
        })
        const editarColega = response.data;
        return editarColega;
    }


    // Colegas que están atendiendo un paciente
    async atencionPacientesColegas(id_colega) {
        const response = await axios.get(`${COLEGA_ATIENDE_PX}?id_colega=${id_colega}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error("CS atencionPacientesColegas: ", error);
        })
        const atnColega = response.data;
        return atnColega;
    }



}


export default new ColegasService();