
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import ExpedientesService from "../../services/ExpedientesService";
import SesionesService from "../../services/SesionesService";

import "../../styles/Expedientes.css";
import "../../styles/MenuHamburguesa.css";

import img_edit from "../../img/edit_note.svg";
import img_download from "../../img/download.svg";
import img_delete from "../../img/delete.svg";
import img_search from "../../img/search_pax.svg";
import img_print from "../../img/print_white.svg";
import img_clear from "../../img/clear_all.svg";
import { result, set } from "lodash";



const Expedientes = (id) => {

    const [pacientes, setPacientes] = useState([]);
    const [sesiones, setSesiones] = useState([]);
    const [prueba, setPrueba] = useState("");
    const [idExpediente, setIdExpediente] = useState("");
    const [diagnostico, setDiagnostico] = useState("");
    const [historial, setHistorial] = useState("");
    const [sugerencias, setSugerencias] = useState("");
    const [experiencias, setExperiencias] = useState("");
    const [familiograma, setFamiliograma] = useState("");
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [banderaPrueba, setBanderaPrueba] = useState(false);
    const [banderaDiagnostico, setBanderaDiagnostico] = useState(false);
    const [banderaHistorial, setBanderaHistorial] = useState(false);
    const [banderaSugerencias, setBanderaSugerencias] = useState(false);
    const [banderaExperiencias, setBanderaExperiencias] = useState(false);
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');
    const [archivos, setArchivos] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const tiposPermitidos = ['application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/png'];
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    };


    useEffect(() => {
        getData();
    }, []);


    const cargarInformacion = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);

        const data2 = await SesionesService.getSesionesHistorial(id_paciente);
        for (let i = 0; i < data2.length; i++) {
            data2[i].numeroSesion = (data2.length + 1) - (i + 1);
        }
        setSesiones(data2)

        const data3 = await ExpedientesService.getExpediente(id_paciente);
        setPrueba(data3[0].pruebas)
        setDiagnostico(data3[0].diagnostico)
        setHistorial(data3[0].hist_medico)
        setSugerencias(data3[0].sugerencias)
        setExperiencias(data3[0].exp_significativa)
        setIdExpediente(data3[0].id_expediente)
        setFamiliograma(data3[0].familiograma)

        const data4 = await ExpedientesService.getArchivos(data3[0].id_expediente)
        setArchivos(data4);
    };


    const DisplayData = pacientes.map((info) => {
        return (
            <tr>
                <td className="td-borderBottom-Exp">
                    <button onClick={() => cargarInformacion(info.id_paciente)} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
            </tr>
        )
    });


    const DisplaySesiones = sesiones.map((sesion) => {
        return (
            <p>Sesión {sesion.numeroSesion}   ----   Hora: {sesion.hora_i}-{sesion.hora_f}  ---- Fecha: {moment(sesion.fecha).format('DD-MM-YYYY')}
                <p style={{ border: "none" }}>
                    <textarea id="textarea-sesiones" rows="4" cols="50" value={sesion.sesion} disabled>
                    </textarea>
                </p>
            </p>
        )
    });

    const cargarArchivos = async () => {
        Swal.fire({
            title: 'Subiendo archivo...',
            text: 'Por favor, espera mientras se sube el archivo.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        if (selectedFile) {
            const archivoExp = {
                nombre: fileName,
                archivo: selectedFile,
                fk_expediente: idExpediente
            }
            if (archivoExp) {
                try {
                    const response = await fetch('http://127.0.0.1:3006/subirArchivo', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(archivoExp),
                    });
                    if (response.ok) {
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: '',
                            text: 'Se cargó el archivo correctamente',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Hubo un error al cargar el archivo.',
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Hubo un error al cargar el archivo.',
                    });
                }
            };

        } else {
            Toast.fire("Asegurate de seleccionar primero un archivo", "", "error");
        }
    }


    // Buscador de pacientes
    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value, usuario.id_psicologo);
        setPacientes(data);
    }

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value, usuario.id_psicologo);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    };


    // Manejadores, secciones de expediente

    const handleBanderaPruebas = (valor) => {
        setBanderaPrueba(valor)
    }

    const handleBanderaDiagnostico = (valor) => {
        setBanderaDiagnostico(valor)
    }

    const handleBanderaExperiencia = (valor) => {
        setBanderaExperiencias(valor)
    }

    const handleBanderaHistorial = (valor) => {
        setBanderaHistorial(valor)
    }

    const handleBanderaSugerencias = (valor) => {
        setBanderaSugerencias(valor)
    }

    const handlePrueba = (event) => {
        setPrueba(event.target.value);
        handleExpediente();
    }

    const handleDiagnostico = (event) => {
        setDiagnostico(event.target.value);
        handleExpediente();
    }

    const handleExperiencia = (event) => {
        setExperiencias(event.target.value);
        handleExpediente();
    }

    const handleHistorial = (event) => {
        setHistorial(event.target.value);
        handleExpediente();
    }

    const handleSugerencias = (event) => {
        setSugerencias(event.target.value);
        handleExpediente();
    }

    const handleExpediente = async () => {
        let exped = {
            id_expediente: idExpediente,
            pruebas: prueba,
            sugerencias: sugerencias,
            hist_medico: historial,
            exp_significativa: experiencias,
            diagnostico: diagnostico,
            familiograma: familiograma
        };
        try {
            const respuesta = await ExpedientesService.editarExpediente(exped);
            if (respuesta.status === 200) {
            } else {
                Toast.fire("Hubo un problema al editar el pendiente", "", "error");
            }
        } catch (error) {
            Toast.fire("Hubo un problema al editar el pendiente", "", "error");
            console.log('Error al realizar la inserción:', error.message);
        }
    };

    const eliminarArchivo = async (id_archivo) => {
        if (id_archivo) {
            Swal.fire({
                title: "Eliminar archivo",
                text: "¿Esta seguro de eliminar el archivo?",
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isDenied) {
                    eliminarArch(id_archivo);
                }
            });
        }
    }
    const eliminarArch = async (id_archivo) => {
        const respuesta = await ExpedientesService.eliminarArchivo(id_archivo);
        if (respuesta.status === 200) {
                Toast.fire("Se ha eliminado el archivo correctamente", "", "success").then((result) => {
                    if (result.isConfirmed) {
                        refreshPage();
                    }
                });

        } else {
            Swal.fire({ icon: 'error', title: 'Error', text: 'Hubo un error al eliminar el archivo.', });
        }
    }

    // Manejador de archivos
    const handleFileUpload = (event) => {
        if (tiposPermitidos.includes(event.target.files[0].type)) {
            const file = event.target.files[0];
            setFileName(event.target.files[0].name)
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64String = reader.result.split(',')[1]; // Elimina el prefijo
                    setSelectedFile(base64String);
                }
            }

        } else {
            Toast.fire("Solo se admiten archivos: doc, docx, pdf, jpeg, jpg y png.", "", "error");
            event.target.value = '';
        }

    };

    const descargarArchivo = async (id_archivo) => {
        if (id_archivo) {
            try {
                const response = await fetch('http://127.0.0.1:3006/descargarArchivo',
                    {
                        method: 'POST', headers: { 'Content-Type': 'application/json', },
                        body: JSON.stringify({ id_archivo }),
                    });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const contentDisposition = response.headers.get('Content-Disposition');
                const isInline = contentDisposition && contentDisposition.includes('inline');
                if (isInline) { // Mostrar en el navegador 
                    window.open(url, '_blank');
                } else { // Descargar el archivo 
                    const a = document.createElement('a');
                    const filename = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : 'archivo';
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            } catch (error) {
                Swal.fire({ icon: 'error', title: 'Error', text: 'Hubo un error al descargar el archivo.', });
            }
        }
    };

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    const DsiplayArchivos = archivos.map((info) => {
        return (
            <tbody>
                <tr>
                    <td id="td-file-exp2">{info.nombre}</td>
                    <button  onClick={() => descargarArchivo(info.id_archivo)}>
                        <td id="td-file-exp2"><img src={img_download} alt="download"></img></td>
                    </button>
                    <button  onClick={() => eliminarArchivo(info.id_archivo)}>
                    <td id="td-file-exp2"><img src={img_delete} alt="delete"></img></td>
                    </button>
                </tr>
            </tbody>
        )
    })

    return (
        <Layout>
            <Content>
                <div className="div-1-expedientes">
                    <div className="px_div_2_exped">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label id="lbl-enSesion_psic">{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Expedientes</label>
                        </div>
                    </div>
                    <div className="px_buscador_exp">
                        <div className="div_No_exp_buscar_exp">
                            <div className="input_buscar_exp">
                                <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                <img src={img_search} alt="buscar" id="img_lupa"></img>
                            </div>
                        </div>
                        <div className="px_filtro_exp">
                            <select id="px_select_exp" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled >Selecciona un filtro</option>
                                <option value="A">Pacientes activos</option>
                                <option value="I">Pacientes inactivos</option>
                            </select>
                            <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                <img src={img_clear} alt="img_clear" />
                                Limpiar
                            </button>
                        </div>
                    </div>

                    <div className="px_div_3_exp_lbl">
                        <label>Pacientes</label>
                    </div>

                    <div className="px_div_3_exp">
                        <div className="div_tablas_th_px-exp">
                            <table id="filas_px-exp">
                                <tbody>
                                    {DisplayData}
                                </tbody>
                            </table>
                        </div>
                        <div className="div-3-expediente-menu">
                            {informacion.length === 0 ? (
                                <div>
                                    <div className="faq-container">

                                        <details>
                                            <summary>Sesiones transcurridas</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>

                                        <details>
                                            <summary>Pruebas psicológicas</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>

                                        <details>
                                            <summary>Sugerencias de la parte psicológica</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>

                                        <details>
                                            <summary>Historial médico</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>

                                        <details>
                                            <summary>Experiencias significativas</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>

                                        <details>
                                            <summary>Familiograma</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>

                                        <details>
                                            <summary>Diagnóstico clínico</summary>
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                        </details>
                                    </div>
                                </div>

                            ) : (
                                <div>
                                    <div className="div-p-nombre-Exp">
                                        <table className="table-head-px-Exp">
                                            <tr>
                                                <td className="td-avatar-Exp" rowSpan={3}>
                                                    <div className="div-avatar-Exp">
                                                        {
                                                            sinFoto === "1" ? (
                                                                fotografia === "" ? (
                                                                    <div id="img_avatar_Exp">
                                                                        <label> Cargando imagen...</label>
                                                                    </div>
                                                                ) : (
                                                                    <div id="img_avatar_Exp">
                                                                        <img src={fotografia} alt="base64"></img>
                                                                    </div>
                                                                )
                                                            ) : (<div></div>)
                                                        }
                                                    </div>
                                                </td>
                                                <td id="td-paciente-Exp" colSpan={3}>
                                                    {/*    local
                                                        <a href={`/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>
                                                        */}

                                                    {/*    web    */}
                                                    <a href={`#/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>

                                                </td>
                                                <td>
                                                    <buttom id="btn-imprimir" onClick={() => navigate(`/reporte/${informacion.id_paciente}`)} >
                                                        <img src={img_print} alt="buscar"></img>
                                                        <label> Reporte </label>
                                                    </buttom>
                                                </td>
                                                <td id="td-menuHamb-citas">
                                                    <div id="div-menuHamb">
                                                        <nav className="nav-menuHamb">
                                                            <input type="checkbox" id="in-menuHamb"></input>
                                                            <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                                            <ul id="ul-menuHamb">
                                                                {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                {/*     entorno web     */}
                                                                <li id="li-menuHamb-admin">
                                                                    <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                </li>
                                                                <li id="li-menuHamb-admin">
                                                                    <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                </li>
                                                                <li id="li-menuHamb-admin">
                                                                    <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                </li>
                                                                <li id="li-menuHamb-admin">
                                                                    <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                </li>
                                                                <li id="li-menuHamb-admin">
                                                                    <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id="encabezado_px_Exp">
                                                <th>Edad</th>
                                                <th>Trabajo actual</th>
                                                <th>Edo. civil</th>
                                                <th>Ciudad</th>
                                                <th>Tel</th>
                                            </tr>
                                            <tr id="filas_px_Exp">
                                                <td>
                                                    <label id="lbl-edad-Exp">{informacion.edad}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-trabajo-Exp">{informacion.trabajo_actual}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-edo-civil-Exp">{informacion.edo_civil}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-ciudad-Exp">{informacion.ciudad}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-tel-Exp">{informacion.tel}</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="faq-container">
                                        <details>
                                            <summary> Sesiones transcurridas </summary>
                                            <div className="summary-sesiones-parrafo">
                                                {DisplaySesiones}
                                            </div>
                                        </details>

                                        <details>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-2">
                                                    <button onClick={() => handleBanderaPruebas(true)}>
                                                        <img src={img_edit} alt="editar" />
                                                        <label>Editar</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <summary>Pruebas psicológicas</summary>
                                            <div>
                                                {banderaPrueba === true ? (
                                                    <p style={{ border: "none" }}>
                                                        <textarea id="textarea-sesiones" value={prueba} onChange={handlePrueba} rows="4" cols="50"></textarea>
                                                        <p id="p-guardar-sesion">
                                                            <button id="btn-guardar-sesion" onClick={() => handleBanderaPruebas(false)}>Terminar</button>
                                                        </p>
                                                    </p>
                                                ) : (
                                                    <p>{prueba}</p>
                                                )
                                                }
                                            </div>
                                            <hr id="id-hr-exp"></hr>
                                            <div className="div-tableFile-exp">
                                                <div className="div-tableFile2-exp">
                                                    <table id="tableFile-exp">
                                                        <thead id="thead-file-exp">
                                                            <tr>
                                                                <th id="th-file-exp1">Archivos</th>
                                                                <th id="th-file-exp2">Descargar</th>
                                                                <th id="th-file-exp3">Eliminar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td id="td-file-exp1">
                                                                    <input type="file" onChange={handleFileUpload} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {DsiplayArchivos}
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-3">
                                                    <button onClick={cargarArchivos}>
                                                        <label>Subir archivo</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </details>

                                        <details>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-2">
                                                    <button onClick={() => handleBanderaSugerencias(true)}>
                                                        <img src={img_edit} alt="editar" />
                                                        <label>Editar</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <summary>Sugerencias de la parte psicológica</summary>
                                            <div>
                                                {banderaSugerencias === true ? (
                                                    <p style={{ border: "none" }}>
                                                        <textarea id="textarea-sesiones" value={sugerencias} onChange={handleSugerencias} rows="4" cols="50"></textarea>
                                                        <p id="p-guardar-sesion">
                                                            <button id="btn-guardar-sesion" onClick={() => handleBanderaSugerencias(false)}>Terminar</button>
                                                        </p>
                                                    </p>
                                                ) : (
                                                    <p>{sugerencias}</p>
                                                )
                                                }
                                            </div>
                                            <hr id="id-hr-exp"></hr>
                                            <div className="div-tableFile-exp">
                                                <div className="div-tableFile2-exp">
                                                    <table id="tableFile-exp">
                                                        <thead id="thead-file-exp">
                                                            <tr>
                                                                <th id="th-file-exp1">Archivos</th>
                                                                <th id="th-file-exp2">Descargar</th>
                                                                <th id="th-file-exp3">Eliminar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td id="td-file-exp1">
                                                                    <input type="file" onChange={handleFileUpload} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {DsiplayArchivos}
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-3">
                                                    <button onClick={cargarArchivos}>
                                                        <label>Subir archivo</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </details>

                                        <details>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-2">
                                                    <button onClick={() => handleBanderaHistorial(true)}>
                                                        <img src={img_edit} alt="editar" />
                                                        <label>Editar</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <summary>Historial médico</summary>
                                            <div>
                                                {banderaHistorial === true ? (
                                                    <p style={{ border: "none" }}>
                                                        <textarea id="textarea-sesiones" value={historial} onChange={handleHistorial} rows="4" cols="50"></textarea>
                                                        <p id="p-guardar-sesion">
                                                            <button id="btn-guardar-sesion" onClick={() => handleBanderaHistorial(false)}>Terminar</button>
                                                        </p>
                                                    </p>
                                                ) : (
                                                    <p>{historial}</p>
                                                )
                                                }
                                            </div>
                                            <hr id="id-hr-exp"></hr>
                                            <div className="div-tableFile-exp">
                                                <div className="div-tableFile2-exp">
                                                    <table id="tableFile-exp">
                                                        <thead id="thead-file-exp">
                                                            <tr>
                                                                <th id="th-file-exp1">Archivos</th>
                                                                <th id="th-file-exp2">Descargar</th>
                                                                <th id="th-file-exp3">Eliminar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td id="td-file-exp1">
                                                                    <input type="file" onChange={handleFileUpload} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {DsiplayArchivos}
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-3">
                                                    <button onClick={cargarArchivos}>
                                                        <label>Subir archivo</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </details>

                                        <details>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-2">
                                                    <button onClick={() => handleBanderaExperiencia(true)}>
                                                        <img src={img_edit} alt="editar" />
                                                        <label>Editar</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <summary>Experiencias significativas</summary>
                                            <div>
                                                {banderaExperiencias === true ? (
                                                    <p style={{ border: "none" }}>
                                                        <textarea id="textarea-sesiones" value={experiencias} onChange={handleExperiencia} rows="4" cols="50"></textarea>
                                                        <p id="p-guardar-sesion">
                                                            <button id="btn-guardar-sesion" onClick={() => handleBanderaExperiencia(false)}>Terminar</button>
                                                        </p>
                                                    </p>
                                                ) : (
                                                    <p>{experiencias}</p>
                                                )
                                                }
                                            </div>
                                            <hr id="id-hr-exp"></hr>
                                            <div className="div-tableFile-exp">
                                                <div className="div-tableFile2-exp">
                                                    <table id="tableFile-exp">
                                                        <thead id="thead-file-exp">
                                                            <tr>
                                                                <th id="th-file-exp1">Archivos</th>
                                                                <th id="th-file-exp2">Descargar</th>
                                                                <th id="th-file-exp3">Eliminar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td id="td-file-exp1">
                                                                    <input type="file" onChange={handleFileUpload} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {DsiplayArchivos}
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-3">
                                                    <button onClick={cargarArchivos}>
                                                        <label>Subir archivo</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </details>

                                        <details>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-2">
                                                    <button >
                                                        <img src={img_edit} alt="editar" />
                                                        <label>Editar</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <summary>Familiograma</summary>
                                            <p>{familiograma}</p>
                                        </details>

                                        <details>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-2">
                                                    <button onClick={() => handleBanderaDiagnostico(true)}>
                                                        <img src={img_edit} alt="editar" />
                                                        <label>Editar</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <summary>Diagnóstico clínico</summary>
                                            <div>
                                                {banderaDiagnostico === true ? (
                                                    <p style={{ border: "none" }}>
                                                        <textarea id="textarea-sesiones" value={diagnostico} onChange={handleDiagnostico} rows="4" cols="50"></textarea>
                                                        <p id="p-guardar-sesion">
                                                            <button id="btn-guardar-sesion" onClick={() => handleBanderaDiagnostico(false)}>Terminar</button>
                                                        </p>
                                                    </p>
                                                ) : (
                                                    <p>{diagnostico}</p>
                                                )
                                                }
                                            </div>
                                            <hr id="id-hr-exp"></hr>
                                            <div className="div-tableFile-exp">
                                                <div className="div-tableFile2-exp">
                                                    <table id="tableFile-exp">
                                                        <thead id="thead-file-exp">
                                                            <tr>
                                                                <th id="th-file-exp1">Archivos</th>
                                                                <th id="th-file-exp2">Descargar</th>
                                                                <th id="th-file-exp3">Eliminar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td id="td-file-exp1">
                                                                    <input type="file" onChange={handleFileUpload} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        {DsiplayArchivos}
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="summary-sesiones">
                                                <div className="summary-sesiones-3">
                                                    <button onClick={cargarArchivos}>
                                                        <label>Subir archivo</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Expedientes;