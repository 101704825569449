
import React, { useEffect, useState, useCallback } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import InformacionPacientes from "../../services/InformacionPacientes";
import RecomendacionService from "../../services/RecomendacionService";
import FormularioEditarRecomendacion from "../Recomendaciones/FormularioEditarRecomendacion";

import "../../styles/FormularioAddFamiliar.css";
import "../../styles/Recomendacion.css";


const Recomendacion = () => {

    const [recomendacion, setRecomendacion] = useState([]);     // obtiene el arreglo de los objetos tabla recomendacion
    const [selectedValue, setSelectedValue] = useState('');     // valor select Pac Activos, Inactivos
    const [selectedValuePac, setSelectedValuePac] = useState('');   // Estado para el valor del select Buscar Pac
    const [image, setImage] = useState('');
    const [fotografia_p, setFotografia_p] = useState('');
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [editar, setEditar] = useState("");
    const [idRec, setIdRecomendacion] = useState("");
    const [idPac, setIdPaciente] = useState("");


    const [sesiones, setSesiones] = useState([]);
    const [fk_cita_sesion, setFk_cita_sesion] = useState("");
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const [fecha, setFecha] = useState("");
    const [hora, setHora] = useState("");
    const [textRec, setTextRec] = useState([]);      // obtiene campo recomendacion de la tabla recomendacion

    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');    //usuario psicólogo
    const usuario = JSON.parse(usuarioString);


    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }


    const getData = async () => {

        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);

    }


    useEffect(() => {
        getData();
    }, []);


    const cargarInformacion = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);
        setIdPaciente(data[0].id_paciente);

        // obtner recomendaciondes del paciente seleccionado
        const data2 = await RecomendacionService.getRecomendaciones(data[0].id_paciente);

        setRecomendacion(data2);
        setTextRec(data2.map(r => r.recomendacion))

    };



    const DisplayPx = pacientes.map((info) => {
        return (
            <tr>
                <td className="td-border-button">
                    <button onClick={() => cargarInformacion(info.id_paciente)} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
            </tr>
        )
    });


    /*--------- Cargar Imagen ----------------*/

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const base64String = reader.result;
                        setImage(base64String);
                    };
                }
            }
            else {
                event.target.value = '';
                event.target.setCustomValidity('Solo se permiten archivos con extensión: .jpg, .jpeg, .png');
                event.target.reportValidity();      // es como un Alert
            }
        }
    }


    const nuevaRecomendacion = () => {
        navigate(`/addRecomendaciones/${idPac}`);
    }


    const editarRecomendacion = async (recomend) => {

        const data = await PacientesService.getInfoPaciente(recomend.fk_paciente);
        console.log(data)

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarRecomendacion rec={recomend} pac={data[0]} />
        );

        Swal.fire({
            title: "Editar recomendación",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const editRecomend = document.getElementById("editRecomend").value;
                const idRec = recomend.id_recomendacion;
                return ({ idRec, editRecomend });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                putEditRecomendacion(datos);
                refreshPage();
            } else if (result.dismiss === 'cancel') {
                navigate(`/recomendaciones`);
            }
        });
    }

    const putEditRecomendacion = async (datos) => {

        let dataRec = {
            recomendacion: datos.editRecomend,
            id_recomendacion: datos.idRec
        }

        console.log("dataRec ------")
        console.log(dataRec)

        const respuesta = await RecomendacionService.editarRecomendacion(dataRec);

        if (respuesta.status === 200) {

            Toast.fire("¡La recomendación se actualizó correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    getData();
                }
            }).then(function () {
                getData();
            });

        } else {
            mostrarError("Error al editar la recomendación.");
        }

    }

    const eliminarRecomendacion = async (index) => {
        const recomendacionEliminar = recomendacion[index];
        if (recomendacionEliminar.id_recomendacion !== undefined) {

            const respuesta = await RecomendacionService.eliminarRecomendacion(recomendacionEliminar.id_recomendacion);

            if (respuesta.status === 200) {

                Toast.fire("Se eliminó la recomendación correctamente.", "", "success").then((result) => {

                    if (result.isConfirmed) {
                        getData();
                    }
                }).then(function () {
                    getData();
                });

            } else {

                mostrarError("Hubo un problema al eliminar la recomendación.");
            }

        } else {
            getData();
        }

    }


    const DisplayRecomendacion = recomendacion.map((recomend, index) => {
        return (
            <div className="faq-container-recom">
                <details open>
                    <summary> Creado el {recomend.fecha}, a las {moment(recomend.hora, "HH:mm:ss").format("HH:mm")} </summary>
                    <div key={index}>
                        <p style={{ border: "none" }}>
                            <textarea id="textarea-sesiones" rows="4" cols="50" value={recomend.recomendacion} disabled></textarea>
                            <p style={{ border: "none" }} id="p-button-recom">
                                <button id="btn-editar-sesion" onClick={() => editarRecomendacion(recomend)}>Editar</button>
                                <button id="btn-eliminar-sesion" onClick={() => eliminarRecomendacion(index)}>Eliminar</button>
                            </p>
                        </p>
                    </div>
                </details>
            </div>
        )
    })






    /*********** Filtros *****************/

    // Buscar pac activos, inactivos
    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            setPacientes(data);
        }
    }

    // Pac activos, inactivos
    const limpiarFiltro = () => {
        setSelectedValue("");

        //limpiarSelectInicial();
    }


    const handleSelect = async (event) => {
        setSelectedValue(event.target.value);
    };


    const handleSalir = (event) => {
        // navigate('/familiares');    // local
        navigate('/recomendaciones');    // web
    }

    const handleSubmit = async () => {


    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }



    return (
        <Layout>
            <Content>
                <div className="div-1-addFam">
                    <div className="div_2_addFam">
                        <div className="px_sesion_addFam">
                            <label id="lbl-enSesion-addFam">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_addFam">
                            <label>Recomendaciones a un paciente</label>
                        </div>
                        <hr />
                    </div>
                    <div className="div-3-sesiones">
                        <div className="div_DisplayPx_sesion">
                            <table id="table-DisplayPx-sesion">
                                <tbody>
                                    {DisplayPx}
                                </tbody>
                            </table>
                        </div>

                        <div className="div-desplegable-sesion">
                            {informacion.length === 0 ? (
                                <div className="faq-container">
                                    <details open>
                                        <summary> Recomendaciones </summary>
                                    </details>
                                    <div className="div-selecPx-sesion">
                                        <label id="label-selecPx-sesion"> Selecciona un paciente de la lista... </label>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="div-p-nombre-cita">
                                        <table className="table-head-px-cita">
                                            <tbody>
                                                <tr>
                                                    <td className="td-avatar-Exp" rowSpan={3}>
                                                        <div className="div-avatar-Exp">
                                                            {
                                                                sinFoto === "1" ? (
                                                                    fotografia === "" ? (
                                                                        <div id="img_avatar_Exp">
                                                                            <label> Cargando imagen...</label>
                                                                        </div>
                                                                    ) : (
                                                                        <div id="img_avatar_Exp">
                                                                            <img src={fotografia} alt="base64"></img>
                                                                        </div>
                                                                    )
                                                                ) : (<div></div>)
                                                            }
                                                        </div>
                                                    </td>
                                                    <td id="td-paciente-citas" colSpan={4}>
                                                        {/* local 
                                                    <a href={`/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>
                                                    */}

                                                        {/* web */}
                                                        <a href={`#/pacientes/${informacion.id_paciente}`}>
                                                            <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                        </a>

                                                    </td>
                                                    <td id="td-menuHamb-citas">
                                                        <div id="div-menuHamb">
                                                            <nav className="nav-menuHamb">
                                                                <input type="checkbox" id="in-menuHamb"></input>
                                                                <label id="label-menuHamb" htmlFor="in-menuHamb"> ☰ </label>
                                                                <ul id="ul-menuHamb">
                                                                    {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                    {/*     entorno web     */}
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="encabezado_px_citas">
                                                    <th>Edad</th>
                                                    <th>Trabajo actual</th>
                                                    <th>Edo. civil</th>
                                                    <th>Ciudad</th>
                                                    <th>Tel</th>
                                                </tr>
                                                <tr id="filas_px_citas">
                                                    <td>
                                                        <label id="lbl-edad-citas">{informacion.edad}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-edo-civil-citas">{informacion.edo_civil}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-tel-citas">{informacion.tel}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr></hr>
                                    <div className="div-button-recom">
                                        <div className="div-button-nvoRecom">
                                            <button onClick={nuevaRecomendacion}>Nueva recomendación</button>
                                        </div>
                                    </div>
                                    <div className="faq-container-Recomender">
                                        <details open>
                                            <summary> Recomendaciones </summary>
                                            <div className="summary-sesiones-parrafo">
                                                {DisplayRecomendacion}
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}



export default Recomendacion;

