
import React from "react";

import "../styles/header_login.css";
import logo from "../img/psiquedata_logo_trans.png";


const HeaderAddPxLogin = () => {

  return (
    <header className="header_login">
      <div className="logo_login">
        <img src={logo} alt="login" />
      </div>
    </header>
  );
};

export default HeaderAddPxLogin;


