// FormularioCitaPX.js
import React from "react";
import "../../styles/FormularioRegistrarCita.css";

const FormularioRegistroCitaPX = ({ psicologos, fechaF, horaI, horaF }) => {
  return (
    <div className="div-agendar-cita">
      <table className="table-registro-cita">
        <tbody>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-paciente-registro" htmlFor="select-psicologo"> Psicologo: </label>
            </td>
            <td>
            <select className="selected-pax" id="select-psicologo">
                <option className="selected-option"> Selecciona una opción </option>
                {psicologos.map((psi, index) => (
                  <option key={index} className="selected-option" value={psi.id_psicologo}>
                    {psi.nombre} {psi.ap1} {psi.ap2}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-fecha-registro" htmlFor="input-fecha"> Fecha: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="date" id="input-fecha" defaultValue={fechaF} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-horaI-registro" htmlFor="input-horaI"> Hora Inicio: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="time" id="input-horaI" defaultValue={horaI} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-horaF-registro" htmlFor="input-horaF"> Hora Final: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="time" id="input-horaF" defaultValue={horaF} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-comentario-registro"> Modalidad: </label>
            </td>
            <td className="registrar-cita-td-der">
              <select className="selected-pax" id="select-modalidad" defaultValue="elegir">
                <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                <option className="selected-option" value="presencial">Presencial</option>
                <option className="selected-option" value="videollamada">Videollamada</option>
                <option className="selected-option" value="telefono">Teléfono</option>
                <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
              </select>
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-comentario-registro" htmlFor="input-comentario"> Comentario: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="text" placeholder="Deja un comentario..." id="input-comentario" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormularioRegistroCitaPX;
