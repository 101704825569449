
import React from "react";

import "../../styles/FormularioTransferirPx.css";


const FormularioTransferirPaciente = ({ paciente, psicologos, fecha }) => {

    return (
        <div>
            <div className="div-Px-SelectPsi-transfer">
                <div className="div-avatarPx-transfer">
                    <div className="div-avatarPx-tf">
                        {
                            (paciente[0].fotografia.data).length !== 0 ? (
                                <img src={paciente[0].fotografia.data} alt="base64"></img>
                            ) : (<></>)
                        }
                    </div>
                    <div className="div-lblPx-transfer">
                        <label id="select-paciente"><b>Paciente:</b> {paciente[0].nombre} {paciente[0].ap1} {paciente[0].ap2}</label>
                    </div>
                </div>
                <div className="div-lbl-Select-transfer">
                    <div className="div-lblPsi-transfer">
                        <label className="">Psicólogo:</label>
                    </div>
                    <div className="div-selectPsi-transfer">
                        <select className="" id="select-idPsic-transfer" defaultValue={""}>
                            <option value="" disabled >Selecciona una opción</option>
                            {psicologos.map((psi) => (
                                <option value={psi.id_psicologo}>
                                    {psi.nombre} {psi.ap1} {psi.ap2}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="div-motivo-fecha-transf">
                <div className="div-motivo-transf">
                    <div className="div-motivo1-transf">
                        <label htmlFor="input-motivo"> Motivo: </label>
                    </div>
                    <div className="div-motivo2-transf">
                        <textarea id="input-motivo" required placeholder="Motivo de canalización" rows="3" cols="50"></textarea>
                    </div>
                </div>
                <div className="div-fecha-transf">
                    <div className="div-fecha1-transf">
                        <label htmlFor="input-fecha"> Fecha: </label>
                    </div>
                    <div className="div-fecha2-transf">
                        <input type="date" id="input-fecha" value={fecha} disabled required />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormularioTransferirPaciente;
