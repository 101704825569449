import axios from "axios";

const CITAS_API_BASE_URL = "/server/citas";
const CITAS_INACTIVO_API_BASE_URL = "/server/citasInactivo";
const VCITAS_API_BASE_URL = "/server/vcitas";

// const CITAS_API_BASE_URL = "http://localhost:3002/citas";
// const CITAS_INACTIVO_API_BASE_URL = "http://localhost:3002/citasInactivo";
// const VCITAS_API_BASE_URL = "http://localhost:3002/vcitas";

const Buffer = require('buffer/').Buffer;

class CitasService {

    async getCitasPS(id_psicologo) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasPS CS :', error);
            });
        const citas = response.data;

        return citas;
        
    }

    async getCitasPsicologoPX(id_psicologo, id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}psicologopx?id_psicologo=${id_psicologo}&id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasPsicologoPX CS :', error);
            });
        const citas = response.data;
        return citas;
    }


    // obtener citas de un pacientes por su Id pac
    async getCitasPacientePX(id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}px?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasPacientePX CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async crearCita(cita) {

        return await axios.post(CITAS_API_BASE_URL, cita);
        
    }

    async getInfoCita(fecha, horaI, horaF) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}info?fecha=${fecha}&hora_i=${horaI}&hora_f=${horaF}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getInfoCita CS: ', error);
            });

        const paciente = response.data;
        
        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(paciente[0].fotografia.data, 'binary');

        if (paciente[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            paciente[0].fotografia.data = imgBlob;
        } else if(paciente[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            paciente[0].fotografia.data = imgBlob;
        } else if(paciente[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            paciente[0].fotografia.data = imgBlob;
        }

        return paciente;
    }

    editarCita(cita) {
        return axios.put(CITAS_API_BASE_URL, cita);
    }
    
    eliminarCita(idCita) {

        return axios.delete(`${CITAS_API_BASE_URL}?id_cita=${idCita}`);

    }


    // eliminar todas las citas posteriore a la fecha hoy (dia que se inactiva), al Inactivar un paciente
    // idCita, fk_paciente, fecha hoy
    eliminarCitasPxInactivo(fecha, fk_paciente) {

        return axios.delete(`${CITAS_INACTIVO_API_BASE_URL}?fecha=${fecha}&fk_paciente=${fk_paciente}`);

    }


    async verificarCitaAnterior(id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}anterior?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error verificarCitaAnterior CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async comprobarCita(fecha, hora_i, hora_f, fk_psicologo) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}comprobar?fecha=${fecha}&hora_i=${hora_i}&hora_f=${hora_f}&fk_psicologo=${fk_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error comprobarCita CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async getCitasHistorial(id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}Historial?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasHistorial CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async getUltimaCita(id_psicologo) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}?id_psicologo=${id_psicologo}`)
        const citas = response.data;
        const ultimaCita = citas.slice(-1)[0];
        return ultimaCita;
      }


    async estatusCita(estatus, id_cita, id_paciente) {
        console.log("estatusCita -----")
        console.log(estatus)
        console.log(id_cita)
        console.log(id_paciente)

        const response = await axios.put(`${CITAS_API_BASE_URL}Estatus?estatus=${estatus}&id_cita=${id_cita}&id_paciente=${id_paciente}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('Error estatusCita CS: ', error);
        })
        const citas = response.data;
        return citas;
    }


}

export default new CitasService();