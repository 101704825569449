

import axios from "axios";

const RECOMENDACION_API_BASE_URL = "/server/recomendaciones";

// const RECOMENDACION_API_BASE_URL = "http://localhost:3002/recomendaciones";

class RecomendacionService {

    // recomendaciones varias que tiene un paciente
    async getRecomendaciones(fk_paciente) {
        const response = await axios.get(`${RECOMENDACION_API_BASE_URL}?fk_paciente=${fk_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getRecomendaciones: ', error);
            });
        const recom = response.data;
        return recom;
    }

    

    // una recomendación individual
    async getRecomendacion(id_recomendacion) {
        const response = await axios.get(`${RECOMENDACION_API_BASE_URL}Individual?id_recomendacion=${id_recomendacion}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getRecomendacion: ', error);
            });
        const recom = response.data;
        return recom;
    }

    nuevaRecomendacion(recomendacion) {

        return axios.post(RECOMENDACION_API_BASE_URL, recomendacion);

    }

    editarRecomendacion(editRec) {

        return axios.put(RECOMENDACION_API_BASE_URL, editRec);

    }

    eliminarRecomendacion(id_recomendacion) {

        return axios.delete(`${RECOMENDACION_API_BASE_URL}?id_recomendacion=${id_recomendacion}`);

    }

}

export default new RecomendacionService();