
// FormularioEditarCitaPX.js  ---- Paciente

import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioEditarCitaPX = ({ psicologo, fechaF, horaI, horaF, comentario, modalidad }) => {
  return (
    <div className="div-editar-cita-modal">
      <table className="tabla-editar-cita-modal">
        <tbody>
          <tr className="table-line-bottom">
            <td className="editar-cita-td-izq">
              <label className="lbl-psi-editar" htmlFor="select-psicologo"> Psicologo: </label>
            </td>
            <td className="editar-cita-td-der">
            <select id="select-psicologo" disabled>
                <option className="selected-option" value={psicologo.id_psicologo}>
                  {psicologo.nombre} {psicologo.ap1} {psicologo.ap2}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td className="editar-cita-td-izq">
              <label className="lbl-fecha" htmlFor="input-fecha"> Fecha: </label>
              </td>
            <td className="editar-cita-td-der">
              <input type="date" id="input-fecha" defaultValue={fechaF} />
              </td>
          </tr>
          <tr>
            <td className="editar-cita-td-izq">
              <label className="lbl-horaI" htmlFor="input-horaI"> Hora Inicio: </label>
              </td>
            <td className="editar-cita-td-der">
              <input type="time" id="input-horaI" defaultValue={horaI} />
              </td>
          </tr>
          <tr>
            <td className="editar-cita-td-izq">
              <label className="lbl-horaF" htmlFor="input-horaF"> Hora Final: </label>
              </td>
            <td className="editar-cita-td-der">
              <input type="time" id="input-horaF" defaultValue={horaF} />
              </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-comentario-registro"> Modalidad: </label></td>
            <td className="registrar-cita-td-der">
              <select className="selected-pax" id="select-modalidad" defaultValue={modalidad}>
                <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                <option className="selected-option" value="presencial">Presencial</option>
                <option className="selected-option" value="videollamada">Videollamada</option>
                <option className="selected-option" value="telefono">Teléfono</option>
                <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
              </select>
            </td>
          </tr>
          <tr>
            <td className="editar-cita-td-izq">
              <label className="label-comentario" htmlFor="input-comentario"> Comentario: </label>
              </td>
            <td className="editar-cita-td-der">
              <input type="text" id="input-comentario" placeholder="Puedes dejar un comentario..." defaultValue={comentario} />
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormularioEditarCitaPX;
