
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import CitasService from "../../services/CitasService";
import PendientesService from "../../services/PendientesService";
import RecomendacionService from "../../services/RecomendacionService";

import FormularioRegistroCita from "../Agenda/FormularioRegistroCita";
import FormularioRegistroCitaPX from "../Agenda/FormularioRegistroCitaPX";
import FormularioEditarCita from "../Agenda/FormularioEditarCita";
import FormularioEditarCitaPX from "../Agenda/FormularioEditarCitaPX";

import "../../styles/Calendario.css";
import "../../styles/FormularioEditarCita.css";
import "../../styles/Pacientes.css";
import "../../styles/FullCalendar.css";

import img_edit from "../../img/edit_note.svg";
import SesionesService from "../../services/SesionesService";
import { constrainPoint } from "@fullcalendar/core/internal";



const Calendario = () => {

    const [pacientes, setPacientes] = useState([]);     // recibe los pacientes del sistema
    const [psicologos, setPsicologos] = useState([]);
    const [paciente, setPaciente] = useState([]);   // info paciente individual
    const [psicologo, setPsicologo] = useState([]);     // info psicologo individual
    const [eventos, setEventos] = useState([]);
    const [pendientes, setPendientes] = useState([]);
    const [recomendacion, setRecomendacion] = useState([]);
    const [desplazarCita, setDesplazarCita] = useState("no");
    const formatosFecha = ["DD-MM-YYYY", "DD/MM/YYYY"];

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);  // usuario que ingresó al sistema
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarErrorFecha(mensaje) {
        Swal.fire({
            title: '¡Error en la Fecha ingresada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarErrorHora(mensaje) {
        Swal.fire({
            title: '¡Error en la Hora ingresada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.isConfirmed) {
                refreshPage();
            }
        });
    }

    function mostrarErrorHraOcupado(mensaje) {
        Swal.fire({
            title: '¡Error: Hora ocupada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.isConfirmed) {
                refreshPage();
            }
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        
        if (usuario.rol === "psi") {
            const data = await CitasService.getCitasPS(usuario.id_psicologo);
            setEventos(data.map(evento => ({
                title: (evento.p_nombre + " " + evento.p_ap1 + " " + evento.p_ap2),
                start: `${evento.fecha}T${evento.hora_i}:00`,
                end: `${evento.fecha}T${evento.hora_f}:00`,
                editable: true
            })));

            const data2 = await PacientesService.getPacientesCalendario(usuario.id_psicologo);
            setPacientes(data2);

        } else if (usuario.rol === "pax") {
            const data = await CitasService.getCitasPacientePX(usuario.id_paciente);
            const data2 = await CitasService.getCitasPsicologoPX(usuario.psicologo, usuario.id_paciente);
            const eventosCombinados = [...data.map(evento => (
                {
                    title: (evento.p_nombre + " " + evento.p_ap1 + " " + evento.p_ap2),
                    start: `${evento.fecha}T${evento.hora_i}:00`,
                    end: `${evento.fecha}T${evento.hora_f}:00`,
                    editable: true,
                    description: 'Paciente'
                }
            )), ...data2.map(evento => (
                {
                    title: "Ocupado",
                    start: `${evento.fecha}T${evento.hora_i}:00`,
                    end: `${evento.fecha}T${evento.hora_f}:00`,
                    color: 'gray',
                    description: 'Psicologo'
                }
            ))]

            setEventos(eventosCombinados);

            const data3 = await PsicologoService.getPsicologos();
            setPsicologos(data3);
        }
    };


    const getDataPendientes = async () => {
        if (usuario.rol === "psi") {
            const data = await PendientesService.getPendientes(usuario.id_psicologo);
            setPendientes(data);
        }
    };

    const getDataRecomendaciones = async () => {
        if (usuario.rol === "pax") {
            const data = await RecomendacionService.getRecomendaciones(usuario.id_paciente)
            setRecomendacion(data);
        }
    };

    const getDataInfoPacientePsicologo = async () => {
        if (usuario.rol === "pax") {
            const data = await PacientesService.getInfoPaciente(usuario.id_paciente);   // info individual
            setPaciente(data[0]);

            const data2 = await PsicologoService.getPsicologoIndividual(usuario.psicologo);     // info individual, // usuario.psicologo, es el Id del psic que atiende, en rol: pax
            setPsicologo(data2[0]);
        }
    }

    useEffect(() => {
        getData();
        getDataPendientes();
        getDataRecomendaciones();
        getDataInfoPacientePsicologo();  // info pac de la sesión
    }, []);



    /*********************************************************/

    // Informacion MODAL PACIENTE AGENDADO,  sesión Psicólogo
    const informacionPaciente = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
        const data2 = await PacientesService.getInfoPaciente(data[0].id_paciente);

        MySwal.fire({
            title: 'Paciente agendado',
            html: (
                <div className="datos-paciente-modal">
                    <div className="datos-foto-modal">
                        <div id="img-agendado">
                            {
                                (data2[0].fotografia.data).length !== 0 ? (
                                    <img src={data2[0].fotografia.data} alt="base64"></img>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                        <div id="lbl-a-agendado">
                            <a href={`#/pacientes/${data[0].id_paciente}`}>
                                <label id="lbl-px-agendado">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                            </a>
                        </div>
                    </div>
                    <div className="modal-pax-agendado">
                        <table className="table-modal-pax-agendado">
                            <tbody>
                                <tr className="padding-row">
                                    <td><label className="lbl-fecha-agendado" htmlFor="lbl-fecha-agendado">Fecha:</label></td>
                                    <td><label id="lbl-fecha-agendado">{moment(data[0].fecha).format("DD-MM-YYYY")}</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-horaI-agendado" htmlFor="lbl-horaI-agendado">Horario:</label></td>
                                    <td><label id="lbl-horaI-agendado">{data[0].hora_i} - {data[0].hora_f}</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-edad-agendado" htmlFor="lbl-edad-agendado">Edad:</label></td>
                                    <td><label id="lbl-edad-agendado">{data[0].edad} años</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-trabajo-agendado" htmlFor="lbl-trabajo-agendado">Trabajo actual:</label></td>
                                    <td><label id="lbl-trabajo-agendado">{data[0].trabajo_actual}</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-telefono-agendado" htmlFor="lbl-telefono-agendado">Telefono:</label></td>
                                    <td><label id="lbl-telefono-agendado">{data[0].tel}</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-ciudad-agendado" htmlFor="lbl-ciudad-agendado">Ciudad:</label></td>
                                    <td><label id="lbl-ciudad-agendado">{data[0].ciudad}</label></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ),
            showCancelButton: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: 'Editar cita',
            cancelButtonText: 'Ir a sesión',
            denyButtonText: 'Cancelar cita',
        }).then((result) => {
            if (result.isConfirmed) {
                editarCita(info);
            } else if (result.isDenied) {
                eliminarCita(info);
            } else if (result.dismiss === 'cancel') {
                // navigate(`/sesiones/${data[0].id_paciente}`);       // entorno local
                navigate(`/sesiones/${data[0].id_paciente}`);       // entorno web
            }
        });
    }


    const moverCita = async (info) => {

        setDesplazarCita("si");

        let horaI = moment(info.oldEvent.start).format('HH:mm');    // obtiene la hora inicial del evento (en la agenda) antes de moverse
        let horaF = moment(info.oldEvent.end).format('HH:mm');
        let fecha = moment(info.oldEvent.start).format('YYYY-MM-DD');

        console.log("info: ", info)
        console.log("info.oldEvent: ", info.oldEvent)
        console.log("oldEvent: ", fecha, horaI, horaF)

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);   // obtiene la cita agendada del evento antes de editarse
        const paciente = data[0].id_paciente

        console.log("cita Data: ")
        console.log(data)

        horaI = moment(info.event.start).format('HH:mm');   // obtiene la nueva hora inicial del evento (en la agenda) después de desplazarse (drop), antes de llamar a editarDatos()
        horaF = moment(info.event.end).format('HH:mm');
        fecha = moment(info.event.start).format('YYYY-MM-DD');

        console.log("nuevos datos: ", fecha, horaI, horaF)

        const modalidad = data[0].modalidad
        const comentario = data[0].comentario
        const estatus_cita = "Modificada";
        const datos = { paciente, fecha, horaI, horaF, modalidad, estatus_cita, comentario };

        editarDatos(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);     // data[0].fecha, para mantener la fecha original antes de editar

    }



    /**************  Agendar cita por Psicologo  ***************/

    // Componente MODAL para agendar cita por el psicolgo
    const agendarCita = async (info) => {

        const fechaF = moment(info.date).format('YYYY-MM-DD');      // fecha de la agenda
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCita pacientes={pacientes} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );

        const horaValida = compararHora(info.date);
        const fechaValida = compararFecha(info.date);

        if (fechaValida === "fechaValida") {
            if (horaValida === "horaValida") {
                Swal.fire({
                    title: "Agendar cita",
                    html: formularioHtml,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Guardar",
                    cancelButtonText: "Nuevo paciente",
                    preConfirm: () => {
                        const paciente = document.getElementById("select-paciente").value;
                        const fecha = (document.getElementById("input-fecha").value);
                        const horaI = document.getElementById("input-horaI").value;
                        const horaF = document.getElementById("input-horaF").value;
                        const modalidad = document.getElementById("select-modalidad").value;
                        const comentario = document.getElementById("input-comentario").value;
                        return { paciente, fecha, horaI, horaF, modalidad, comentario };
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const datos = result.value;
                        guardarDatos(datos);
                    } else if (result.dismiss === 'cancel') {
                        //navigate('/addpacienteexpress');        // entorno local
                        navigate('/addpacienteexpress');        // entorno web
                    }
                });
            }
        }
    }


    //Psicólogo, crear la cita
    const guardarDatos = async (datos) => {

        console.log("obf Datos: ------ ")
        console.log(datos)

        // ******* validando fechas *******

        // validando fecha actual
        const hoy = new Date();
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        console.log("fechaActual:  " + fechaActual)

        // validando fecha de cita pasada
        const feLetras = datos.fecha + " " + datos.horaI;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        console.log("fecha - hora Original: " + feLetras)
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaInput = fOr.getTime();    // fecha inicial que se ingresa en el input del modal, convertir a mls
        console.log("fechaInput:  " + fechaInput)

        // la diferencia entre fechas es igual a días y horas
        const resta = (parseInt(fechaInput) - parseInt(fechaActual)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        console.log("citaTranscurrida: " + res)
        const citaTranscurrida = (res);    // si la fecha input de la cita tiene una != > a -24hrs o más, la fecha ya pasó por un día o más.
        // si es = 0, se está creando una cita en el día actual que está en transcurso

        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora ingresada en el input
        const hrIn = datos.horaI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMin = parseInt(hrIn[0]) * 60 + parseInt(hrIn[1]);     // los minutos de la hr del input después de modificarse

        const formatoFecha = moment(datos.fecha).format("DD-MM-YYYY");

        if (datos.paciente === "Selecciona una opción") {

            mostrarError("Asegúrate de seleccionar un paciente");

        } else {

            if (fechaInput >= fechaActual) {
                if (validarFecha(formatoFecha) === true) {
                    if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                        if (datos.horaI >= datos.horaF) {
                            mostrarErrorHora("La hora inicial no es menor a la final");
                        } else {
                            if (citaTranscurrida > 0) {    // se está creando cita en días posteriores a la fecha actual
                                const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                                if (solapamiento === true) {    // está desocupado este horario
                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: "Confirmada",
                                        comentario: datos.comentario,
                                        fk_paciente: parseInt(datos.paciente),
                                        fk_psicologo: usuario.id_psicologo
                                    }
                                    try {

                                        // Crear la cita
                                        const respuestaCita = await CitasService.crearCita(cita);

                                        // no funciona, obtiene la penúltima
                                        // const ultimaCita = await CitasService.getUltimaCita(usuario.id_psicologo);  // obtener la última cita
                                        // data.insertId, registra el último id

                                        let sesion = {
                                            sesion: "",
                                            estatus_sesion: "",
                                            fk_cita: respuestaCita.data.insertId
                                        }

                                        // Crear la sesión
                                        const respuestaSesion = await SesionesService.crearSesion(sesion);

                                        if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                            Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });

                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita");
                                        }
                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }

                                } else {
                                    mostrarError("Ya hay una cita en ese horario");
                                }

                            } else if (citaTranscurrida === 0) {     // se está creando la cita en el día actual que está transcurriendo

                                if (sumaMin > sumMinAct) {

                                    const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);

                                    if (solapamiento === true) {    // está desocupado este horario

                                        let cita = {
                                            fecha: fechaFormateada,
                                            hora_i: datos.horaI,
                                            hora_f: datos.horaF,
                                            modalidad: datos.modalidad,
                                            estatus_cita: "Confirmada",
                                            comentario: datos.comentario,
                                            fk_paciente: parseInt(datos.paciente),
                                            fk_psicologo: usuario.id_psicologo
                                        }
                                        try {

                                            // Crear la cita
                                            const respuestaCita = await CitasService.crearCita(cita);

                                            // no funciona, obtiene la penúltima
                                            // const ultimaCita = await CitasService.getUltimaCita(usuario.id_psicologo);  // obtener la última cita

                                            let sesion = {
                                                sesion: "",
                                                estatus_sesion: "",
                                                fk_cita: respuestaCita.data.insertId
                                            }

                                            // Crear la sesión
                                            const respuestaSesion = await SesionesService.crearSesion(sesion);

                                            if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });

                                            } else {
                                                mostrarError("Hubo un problema al registrar la cita");
                                            }
                                        } catch (error) {
                                            mostrarError("Hubo un problema al registrar la cita");
                                            console.log('Error al realizar la inserción:', error.message);
                                        }

                                    } else {
                                        mostrarError(`Ya hay una cita en el horario seleccionado: ${datos.horaI}`);
                                    }

                                } else {
                                    mostrarErrorHora(`La hora ${datos.horaI} del día ${formatoFecha} que intentas registrar ya transcurrió.`);
                                }
                            }
                        }

                    } else {
                        mostrarError("Hora incorrecta, asegurate que el formato sea HH:MM");
                    }

                } else {
                    mostrarError("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                }

            } else {
                mostrarErrorFecha(`Psi. Fecha incorrecta, la fecha ${formatoFecha} ya no es actual.`);
            }
        }
    }


    const editarCita = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);

        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        let pac;

        for (let i = 0; i < pacientes.length; i++) {
            if (pacientes[i].id_paciente === data[0].id_paciente) {
                pac = pacientes[i]
            }
        }

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCita paciente={pac} fechaF={fechaF} horaI={horaI} horaF={horaF} modalidad={data[0].modalidad} comentario={data[0].comentario} />
        );
        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            // showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Guardar cambios",
            // cancelButtonText: "Cancelar",
            preConfirm: () => {
                const paciente = document.getElementById("select-px-editar").value;
                const fecha = document.getElementById("input-fecha-editar").value;
                const horaI = document.getElementById("input-horaI-editar").value;
                const horaF = document.getElementById("input-horaF-editar").value;
                const modalidad = document.getElementById("select-modalidad-editar").value;
                const estatus_cita = "Modificada";
                const comentario = document.getElementById("input-comentario-editar").value;
                return { paciente, fecha, horaI, horaF, modalidad, estatus_cita, comentario };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                editarDatos(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);     // data[0].fecha, para mantener la fecha original antes de editar
            }
        });
    }


    const editarDatos = async (datos, idCita, feOrig, hrOrig) => {

        console.log("desplazarCita: ", desplazarCita)

        console.log("obf Datos: ------ ")
        console.log(datos)
        console.log(idCita, feOrig, hrOrig)

        // ******* validando fechas *******

        // fecha actual
        const hoy = new Date();
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        console.log("fechaActual:  " + fechaActual)

        /*--------- Horarios NO permitidos -----------------*/
        // validando cita pasada, para que no se puedan editar
        const feLetras = feOrig + " " + hrOrig;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        console.log("fecha - hora Original: " + feLetras)
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaOriginal = fOr.getTime();    // convertir a mls
        console.log("fechaOriginal:  " + fechaOriginal)

        // la diferencia entre fechas es igual a días y horas
        // fechaOriginal: para no poder editar citas de fechas ya transcurridas
        const resta = (parseInt(fechaActual) - parseInt(fechaOriginal)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        console.log("citaTranscurrida: " + res)
        const citaTranscurrida = (res);    // es el tiempo en hrs entre los días pasados (+) o futuros (-) de la cita agendada y el día actual
        // si la fecha original de la cita tiene una != > a +24hrs, +48hrs, etc, es una cita con fecha menor a la actual
        // si es = 0, se está editando una cita en el día actual
        // si es < 0 es una cita que está en días posteriores al actual


        /*---------- horarios permitidos ----------------*/
        // validando fecha input, para editar citas en horarios permitidos despúes del día actual
        // para saber si en el día actual la edición de la cita es antes o después de la hra actual
        const letras = datos.fecha + " " + datos.horaI;
        console.log("fecha - hora Input: " + letras)
        const fecIn = new Date(letras);   // convierte string fecha a tipo dato: new Date()
        fecIn.setHours(0);
        fecIn.setMinutes(0);
        fecIn.setSeconds(0);
        fecIn.setMilliseconds(0);
        const fechaInput = fecIn.getTime();

        // para validar que la edidción de la cita, la fecha no sea menor a la actual
        console.log("fechaActual: " + fechaActual)
        console.log("fechaInput: " + fechaInput)
        const resta2 = (parseInt(fechaActual) - parseInt(fechaInput)) / 1000;
        const res2 = resta2 / 3600;
        console.log("citaTranscurrida2: " + res2)
        const citaTranscurrida2 = (res2);   // si la fecha input de la cita tiene una != de -24hrs, -48hr, etc,
        // es una cita que se quiere editarse de días posteriores al día actual o días pasados
        // si es = 0, se está editando una cita en el día actual

        /*--------------------------*/


        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora original antes de modificar el input de la nueva hora
        const hrOrIn = hrOrig.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMinOr = parseInt(hrOrIn[0]) * 60 + parseInt(hrOrIn[1]);   // los minutos de la hr original del input antes de modificarse

        // validando hora actual menor a la hra ingresada en el input
        const hrIn = datos.horaI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMin = parseInt(hrIn[0]) * 60 + parseInt(hrIn[1]);     // los minutos de la hr del input después de modificarse

        const restaHra = (sumMinAct - sumaMinOr) / 60;    // diferencia entre horas entre la hora actual y la hr orig de una cita
        // si la hra orig < a la actual, la cita ya pasó
        const restaMin = (sumMinAct - sumaMinOr);
        console.log("dif entre minAc y minOr: " + restaMin)

        const formatoFecha = moment(datos.fecha).format("DD-MM-YYYY");

        if (datos.paciente === "Selecciona una opción") {

            mostrarError("Asegúrate de seleccionar un paciente.");

        } else {

            if (citaTranscurrida < 0) {  // la edición de cita es en días posteriores a la fecha y hora actual, num negativos
                if (citaTranscurrida2 === 0) {     // Una cita se está recorriendo al día actual
                    if (validarFecha(formatoFecha)) {
                        if (sumaMin > sumMinAct) {     // validar hora actual con la hora input, si hra input > hra actual, es correcto
                            if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                                if (datos.horaI >= datos.horaF) {
                                    mostrarErrorHora("La hora inicial no es menor a la final");
                                } else {
                                    const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                                    if (solapamiento === true) {
                                        let cita = {
                                            fecha: fechaFormateada,
                                            hora_i: datos.horaI,
                                            hora_f: datos.horaF,
                                            modalidad: datos.modalidad,
                                            estatus_cita: datos.estatus_cita,
                                            comentario: datos.comentario,
                                            id_paciente: parseInt(datos.paciente),
                                            id_psicologo: usuario.id_psicologo,
                                            id_cita: idCita
                                        }
                                        try {
                                            const respuesta = await CitasService.editarCita(cita);
                                            if (respuesta.status === 200) {
                                                Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });
                                            } else {
                                                mostrarError("Hubo un problema al registrar la cita.");
                                                console.log("Estatus-200...");
                                            }
                                        } catch (error) {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log('Error al realizar la inserción:', error.message);
                                        }
                                    } else {
                                        mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                                    }
                                }

                            } else {
                                mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                            }
                        } else {
                            mostrarErrorHora(`La hora ${datos.horaI} que tratas de ingresar para el día ${dateHoy} ya transcurrió.`);
                        }

                    } else {
                        mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                    }

                } else if (citaTranscurrida2 < 0) {    // es una cita que se mueve de días, horas después del día actual

                    if (validarFecha(formatoFecha)) {
                        if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                            if (datos.horaI >= datos.horaF) {
                                mostrarErrorHora("La hora inicial no es menor a la final");
                            } else {
                                const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                                if (solapamiento === true) {
                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: datos.estatus_cita,
                                        comentario: datos.comentario,
                                        id_paciente: parseInt(datos.paciente),
                                        id_psicologo: usuario.id_psicologo,
                                        id_cita: idCita
                                    }
                                    try {
                                        const respuesta = await CitasService.editarCita(cita);
                                        if (respuesta.status === 200) {
                                            Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });
                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log("Estatus-200...");
                                        }
                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita.");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }
                                } else {
                                    mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                                }
                            }

                        } else {
                            mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                        }

                    } else {
                        mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                    }
                }

            } else if (citaTranscurrida >= 0 && citaTranscurrida < 10) {     // la edición de la cita es en la fecha y/o hora actual o f y hr que ya transcurrió
                console.log("cccccccc")
                if (validarFecha(formatoFecha)) {
                    if (sumaMinOr > sumMinAct) {
                        if (sumaMin > sumMinAct) {
                            if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                                if (datos.horaI >= datos.horaF) {
                                    mostrarErrorHora("La hora inicial no es menor a la final");
                                } else {
                                    const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                                    if (solapamiento === true) {
                                        let cita = {
                                            fecha: fechaFormateada,
                                            hora_i: datos.horaI,
                                            hora_f: datos.horaF,
                                            modalidad: datos.modalidad,
                                            estatus_cita: datos.estatus_cita,
                                            comentario: datos.comentario,
                                            id_paciente: parseInt(datos.paciente),
                                            id_psicologo: usuario.id_psicologo,
                                            id_cita: idCita
                                        }
                                        try {
                                            const respuesta = await CitasService.editarCita(cita);
                                            if (respuesta.status === 200) {
                                                Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });
                                            } else {
                                                mostrarError("Hubo un problema al registrar la cita.");
                                                console.log("Estatus-200...");
                                            }
                                        } catch (error) {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log('Error al realizar la inserción:', error.message);
                                        }
                                    } else {
                                        mostrarErrorHraOcupado(`Ya hay una cita en el horario selecciondado: ${formatoFecha}`);
                                    }
                                }

                            } else {
                                mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                            }

                        } else {
                            mostrarErrorHora(`Estás ingresando la hora ${datos.horaI} ya transcurrida.`);
                        }

                    } else if (restaMin > 0 && restaMin < 60) {   // miesntras transcurre la hora actual de la cita y se quiere extender el horario

                        if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                            if (datos.horaI >= datos.horaF) {
                                mostrarErrorHora("La hora inicial no es menor a la final");
                            } else {

                                const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);

                                if (solapamiento === true) {
                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: datos.estatus_cita,
                                        comentario: datos.comentario,
                                        id_paciente: parseInt(datos.paciente),
                                        id_psicologo: usuario.id_psicologo,
                                        id_cita: idCita
                                    }
                                    try {

                                        const respuesta = await CitasService.editarCita(cita);

                                        if (respuesta.status === 200) {
                                            Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });
                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log("Estatus-200...");
                                        }
                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita.");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }
                                } else {
                                    mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                                }
                            }

                        } else {
                            mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                        }

                    } else {
                        mostrarErrorHora(`Estás tratando de editar una cita de las ${hrOrig} horas, ya transcurrida.`);
                    }

                } else {
                    mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                }

            } else {
                mostrarErrorHora(`Estás tratando de editar una cita de ${Math.trunc(citaTranscurrida + restaHra)} hrs atrás.`);
            }
        }
    };

    const eliminarCita = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: true,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatos(data[0].id_cita, data[0].hora_i, data[0].fecha);
            }
        });
    };

    const eliminarDatos = async (idCita, hraI, fecha) => {

        // fecha actual
        const hoy = new Date();
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas

        // validando cita pasada
        const feLetras = fecha + " " + hraI;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaOriginal = fOr.getTime();    // convertir a mls

        // la diferencia entre fechas es igual a días y horas
        const resta = (parseInt(fechaOriginal) - parseInt(fechaActual)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        const citaTranscurrida = (res);    // si la fecha input de la cita tiene una != > a 24hrs, ya es una cita pasada.
        // si es = 0, se está editando una cita en el día actual

        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora original antes de modificar el input de la nueva hora
        const hrOrIn = hraI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMinOr = parseInt(hrOrIn[0]) * 60 + parseInt(hrOrIn[1]);   // los minutos de la hr original del input antes de modificarse


        if (fechaOriginal >= fechaActual) {      // la fecha de la cita aun no transcurre
            if (citaTranscurrida > 0) {
                const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                const respuestaCita = await CitasService.eliminarCita(idCita);
                if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                    Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });

                } else {
                    mostrarError("Hubo un problema al eliminar la cita.");
                }
            } else if (citaTranscurrida === 0) {     // se está eliminando una cita de la fecha en transcurso (actual)
                // validar hora actual
                if (sumaMinOr > sumMinAct) {
                    const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                    const respuestaCita = await CitasService.eliminarCita(idCita);
                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                        Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        }).then(function () {
                            refreshPage();
                        });

                    } else {
                        mostrarError("Hubo un problema al eliminar la cita.");
                    }

                } else {
                    mostrarError("Estás tratando de eliminar una cita ya transcurrida.");
                }

            } else {
                mostrarError("Estás tratando de eliminar una cita del día, antes de que transcurrida.");
            }

        } else {
            mostrarError("No está permitido eliminar las citas programadas ya transcurridas.");
        }
    }

    /*********************************************************/


    const verficarSolapamiento = async (fecha, horaI, horaF, id_psicologo) => {
        const data = await CitasService.comprobarCita(fecha, horaI, horaF, id_psicologo);
        if (data[0] === undefined) {    // Sí se puede agendar, está vacía
            return true;
        } else {    // No se puede agendar, ya está ocupada
            return false;
        }
    }

    function verificarCitaAnterior(fechaAnterior, fecha) {

        const lastAppointmentDate = new Date(fechaAnterior); // Fecha de la última cita
        const currentDate = new Date(fecha); // Fecha actual
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Un día en milisegundos
        const daysDifference = Math.floor((currentDate - lastAppointmentDate) / oneDayInMilliseconds);

        if (daysDifference >= 7) {
            return true;
        } else {
            return false;
        }
    }

    function verficarTiempo(horaI, horaF) {
        const tInicio = new Date();
        const pInicio = horaI.split(':');
        tInicio.setHours(parseInt(pInicio[0]), parseInt(pInicio[1]));

        const tFin = new Date();
        const pFin = horaF.split(':');
        tFin.setHours(parseInt(pFin[0]), parseInt(pFin[1]));

        const diferenciaEnMilisegundos = Math.abs(tFin - tInicio);
        const minutosDiferencia = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
        return minutosDiferencia;
    }

    function validarFecha(fecha) {
        return moment(fecha, formatosFecha, true).isValid();
    }

    function validarHora(hora) {
        return moment(hora, "HH:mm", true).isValid();
    }

    function sumarMediaHora(hora) {
        const horaActual = new Date(hora); // Crea un objeto Date a partir de la hora recibida
        horaActual.setMinutes(horaActual.getMinutes() + 60); // Suma 60 minutos
        return horaActual;
    }

    const compararFecha = (fechaAgenda) => {

        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        // fecha obtenida en campo input
        var anioFecha = fechaAgenda.getFullYear();    // Returns the year
        var mesFecha = fechaAgenda.getMonth() + 1;  // Returns the month (from 0-11)
        var diaFecha = fechaAgenda.getDate();     // Returns the day of the month (from 1-31)

        if (anioFecha < anioHoy) {
            mostrarErrorFecha("El Año seleccionado es anterior al actual.");
        } else if (anioFecha == anioHoy && mesFecha < mesHoy) {
            mostrarErrorFecha("El Mes seleccionado es anterior al actual");
        } else if (anioFecha == anioHoy && mesFecha == mesHoy && diaFecha < diaHoy) {
            mostrarErrorFecha("El Día seleccionado es anterior a Hoy");
        } else if (anioFecha == anioHoy && mesFecha >= mesHoy) {
            return "fechaValida";
        } if (anioFecha > anioHoy) {
            return "fechaValida";
        }

        // si No es válida la fecha, regresará: undefined
    }

    const compararHora = (horaInput) => {

        let horaActual = new Date();

        if (horaInput.getTime() > horaActual.getTime()) {    // .getTime(), convierte a milisegundos desde 1970
            return "horaValida"
        } else {
            mostrarErrorHora("La hora seleccionada es menor a la actual.");
            // si no es valida la hora, retorna: undefined
        }
    }

    /*********************************************************/



    // Informacion MODAL PACIENTE agendado: rol Paciente
    const informacionPacientePX = async (info) => {

        if (info.event.extendedProps.description === 'Paciente') {
            const horaI = moment(info.event.start).format('HH:mm');
            const horaF = moment(info.event.end).format('HH:mm');
            const fechaF = moment(info.event.start).format('YYYY-MM-DD');

            const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
            const data2 = await PacientesService.getInfoPaciente(data[0].id_paciente);

            MySwal.fire({
                title: 'Cita agendada',
                html: (
                    <div className="datos-paciente-modal">
                        <div>
                            <div className="div-citaAgendada-pac">
                                <div className="div-avatar-pac">
                                    <div id="img_Foto_px">
                                        {
                                            (data2[0].fotografia.data).length !== 0 ? (
                                                <img src={data2[0].fotografia.data} alt="base64"></img>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="div-fotoPac-pac">
                                    <div>
                                        <label className="label-paciente" htmlFor="lbl-paciente"> Paciente: </label>
                                    </div>
                                    <div>
                                        <label id="lbl-paciente">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="div-pax-agendado">
                            <table className="modal-pax-agendado">
                                <tbody>
                                    <tr className="padding-row">
                                        <td>
                                            <label className="lbl-fecha-agendado" htmlFor="lbl-fecha-agendado">Fecha:</label>
                                        </td>
                                        <td>
                                            <input type="date" id="lbl-fecha-agendado" value={fechaF} disabled />
                                        </td>
                                    </tr>
                                    <tr className="padding-row">
                                        <td>
                                            <label className="lbl-horaI-agendado" htmlFor="lbl-horaI-agendado">Hora inicio:</label>
                                        </td>
                                        <td>
                                            <input type="time" id="lbl-horaI-agendado" value={horaI} disabled />
                                        </td>
                                    </tr>
                                    <tr className="padding-row">
                                        <td>
                                            <label className="lbl-horaF-agendado" htmlFor="lbl-horaF-agendado">Hora final:</label>
                                        </td>
                                        <td>
                                            <input type="time" id="lbl-horaF-agendado" value={horaF} disabled />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado"> ------------ Atenderá el proceso ------------- </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="div-citaAgendada2-pac">
                                <div className="div-citaAgendada3-pac">
                                    <label id="lbl-psic-agendado">Psicólogo: {data[0].psi_nombre} {data[0].psi_ap1} {data[0].psi_ap2}</label>
                                </div>
                                <div className="div-citaAgendada4-pac">
                                    {
                                        (data[0].fotografia.data).length !== 0 ? (
                                            <img src={data[0].fotografia.data} alt="base64"></img>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            </div>
                            <table className="modal-pax-agendado">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado">Cédula:</label>
                                        </td>
                                        <td>
                                            <label id="lbl-psic-agendado">{data[0].cedula}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado">Especialidad:</label>
                                        </td>
                                        <td>
                                            <label id="lbl-psic-agendado">{data[0].especialidad}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ),
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Editar cita',
                cancelButtonText: 'Salir',
                denyButtonText: 'Eliminar cita',
            }).then((result) => {
                if (result.isConfirmed) {
                    editarCitaPX(info);
                } else if (result.isDenied) {
                    // Lógica a ejecutar cuando se presiona: Eliminar cita
                    eliminarCitaPX(info);
                }
            });
        }
    }

    // administrar Cita por el Paciente
    const agendarCitaPX = async (info) => {

        const fechaF = moment(info.date).format('YYYY-MM-DD');
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCitaPX psicologos={psicologos} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );

        const horaValida = compararHora(info.date);
        const fechaValida = compararFecha(info.date);

        if (fechaValida === "fechaValida") {
            if (horaValida === "horaValida") {
                Swal.fire({
                    title: "Agendar cita",
                    html: formularioHtml,
                    showCancelButton: true,
                    confirmButtonText: "Guardar",
                    cancelButtonText: "Cancelar",
                    preConfirm: () => {
                        const idPsicologo = document.getElementById("select-psicologo").value;    // retorna Id
                        const fecha = document.getElementById("input-fecha").value;
                        const horaI = document.getElementById("input-horaI").value;
                        const horaF = document.getElementById("input-horaF").value;
                        const modalidad = document.getElementById("select-modalidad").value;
                        const comentario = document.getElementById("input-comentario").value;

                        return { idPsicologo, fecha, horaI, horaF, comentario, modalidad };
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const datos = result.value;
                        guardarDatosPX(datos);
                    }
                });
            }
        }
    }

    //Paciente, agendar cita
    const guardarDatosPX = async (datos) => {

        console.log("obj datos: ", datos)

        // ******* validando fechas *******

        // validando fecha actual
        const hoy = new Date();
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        console.log("fecha act: ", hoy, fechaActual)

        // validando fecha de cita pasada
        const feLetras = datos.fecha + " " + datos.horaI;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaInput = fOr.getTime();    // fecha inicial que se ingresa en el input del modal, convertir a mls
        console.log("fecha input: ", fOr, fechaInput)

        // la diferencia entre fechas es igual a días y horas
        const resta = (parseInt(fechaInput) - parseInt(fechaActual)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        const citaTranscurrida = (res);    // si la fecha input de la cita tiene una != > a -24hrs o más, la fecha ya pasó por un día o más.
        // si es = 0, se está creando una cita en el día actual que está en transcurso

        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora ingresada en el input
        const hrIn = datos.horaI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMin = parseInt(hrIn[0]) * 60 + parseInt(hrIn[1]);     // los minutos de la hr del input después de modificarse

        const formatoFecha = moment(datos.fecha).format("DD-MM-YYYY");


        if (paciente.estatus === "A") {

            if (datos.idPsicologo === "Selecciona una opción") {

                mostrarError("Asegúrate de seleccionar un psicólogo.");

            } else {

                if (parseInt(usuario.psicologo) === parseInt(datos.idPsicologo)) {     // usuario.psicologo, es el Id del psic que atiende

                    if (fechaInput >= fechaActual) {
                        console.log("111111")

                        if (validarFecha(formatoFecha)) {
                            console.log("222222")

                            if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                                console.log("33333")

                                if (citaTranscurrida > 0) {    // se está creando cita en días posteriores a la fecha actual
                                    console.log("44444")

                                    const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                    const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);     // crear cita
                                    const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                                    if (citaAnt) {

                                        console.log("55555")

                                        const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                        if (tiempo < 60 || tiempo > 60) {

                                            console.log("6666666")

                                            mostrarErrorHora("La cita tiene que durar una hora.");

                                        } else if (tiempo === 60) {
                                            console.log("777777")

                                            const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                            if (solapamiento === true) {
                                                let cita = {
                                                    fecha: fechaFormateada,
                                                    hora_i: datos.horaI,
                                                    hora_f: datos.horaF,
                                                    modalidad: datos.modalidad,
                                                    estatus_cita: "Confirmada",
                                                    comentario: datos.comentario,
                                                    fk_paciente: usuario.id_paciente,
                                                    fk_psicologo: parseInt(datos.idPsicologo)
                                                }
                                                try {

                                                    console.log("8888888")

                                                    // Crear la cita
                                                    const respuestaCita = await CitasService.crearCita(cita);

                                                    // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                                    let sesion = {
                                                        sesion: "",
                                                        estatus_sesion: "",
                                                        fk_cita: respuestaCita.data.insertId
                                                    }

                                                    // Crear la sesión
                                                    const respuestaSesion = await SesionesService.crearSesion(sesion);

                                                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                        Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                            if (result.isConfirmed) {
                                                                refreshPage();
                                                            }
                                                        }).then(function () {
                                                            refreshPage();
                                                        });

                                                    } else {
                                                        mostrarError("Hubo un problema al registrar la cita");
                                                        console.log("Estatus = 200");
                                                    }

                                                } catch (error) {
                                                    mostrarError("Hubo un problema al registrar la cita");
                                                    console.log('Error al realizar la inserción:', error.message);
                                                }

                                            } else {
                                                mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                            }
                                        }
                                    } else {
                                        mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                                    }

                                } else if (citaTranscurrida === 0) {
                                    console.log("99999")

                                    if (sumaMin > sumMinAct) {

                                        console.log("-- 10 --")

                                        const fechaFormateada = format(parse(formatoFecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                        const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);     // crear cita
                                        const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                                        if (citaAnt) {

                                            console.log("-- 11 --")

                                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                            if (tiempo < 60 || tiempo > 60) {

                                                console.log("-- 12 --")

                                                mostrarErrorHora("La cita tiene que durar una hora.");

                                            } else if (tiempo === 60) {

                                                console.log("-- 13 --")

                                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                                if (solapamiento === true) {
                                                    let cita = {
                                                        fecha: fechaFormateada,
                                                        hora_i: datos.horaI,
                                                        hora_f: datos.horaF,
                                                        modalidad: datos.modalidad,
                                                        estatus_cita: "Confirmada",
                                                        comentario: datos.comentario,
                                                        fk_paciente: usuario.id_paciente,
                                                        fk_psicologo: parseInt(datos.idPsicologo)
                                                    }
                                                    try {


                                                        console.log("-- 14 --")

                                                        // Crear la cita
                                                        const respuestaCita = await CitasService.crearCita(cita);

                                                        // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                                        // no funciona
                                                        // const ultimaCita = await CitasService.getUltimaCita(datos.idPsicologo);     // obtener la última cita

                                                        let sesion = {
                                                            sesion: "",
                                                            estatus_sesion: "",
                                                            fk_cita: respuestaCita.data.insertId
                                                        }

                                                        // Crear la sesión
                                                        const respuestaSesion = await SesionesService.crearSesion(sesion);

                                                        if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                            Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                                if (result.isConfirmed) {
                                                                    refreshPage();
                                                                }
                                                            }).then(function () {
                                                                refreshPage();
                                                            });

                                                        } else {
                                                            mostrarError("Hubo un problema al registrar la cita");
                                                            console.log("Estatus = 200");
                                                        }

                                                    } catch (error) {
                                                        mostrarError("Hubo un problema al registrar la cita");
                                                        console.log('Error al realizar la inserción:', error.message);
                                                    }

                                                } else {
                                                    mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                                }
                                            }

                                        } else {
                                            mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                                        }

                                    } else {
                                        mostrarErrorHora(`La hora ${datos.horaI} del día ${formatoFecha} que intentas registrar ya transcurrió.`);
                                    }

                                } else {
                                    mostrarError("Hora incorrecta, asegurate que el formato sea HH:MM");
                                }

                            } else {
                                mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                            }

                        } else {
                            mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                        }

                    } else {
                        mostrarErrorFecha(`Px1. Fecha incorrecta, la fecha ${formatoFecha} ya no es actual.`);
                    }

                } else {
                    mostrarError("No puedes cambiar de psicólogo miestras tienes un proceso abierto.");
                }
            }

        } else if (paciente.estatus === "I") {

            if (datos.idPsicologo === "Selecciona una opción") {

                mostrarError("Asegúrate de seleccionar un psicólogo.");

            } else {

                if (fechaInput >= fechaActual) {

                    if (validarFecha(datos.fecha)) {

                        if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                            if (citaTranscurrida > 0) {    // se está creando cita en días posteriores a la fecha actual

                                const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);
                                const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                                if (citaAnt) {

                                    const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                    if (tiempo < 60 || tiempo > 60) {

                                        mostrarErrorHora("La cita tiene que durar una hora.");

                                    } else if (tiempo === 60) {

                                        const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                        if (solapamiento === true) {
                                            let cita = {
                                                fecha: fechaFormateada,
                                                hora_i: datos.horaI,
                                                hora_f: datos.horaF,
                                                modalidad: datos.modalidad,
                                                estatus_cita: "Confirmada",
                                                comentario: datos.comentario,
                                                fk_paciente: usuario.id_paciente,
                                                fk_psicologo: parseInt(datos.idPsicologo)
                                            }
                                            try {

                                                // Crear la cita
                                                const respuestaCita = await CitasService.crearCita(cita);
                                                // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                                // no funciona
                                                // const ultimaCita = await CitasService.getUltimaCita(datos.idPsicologo);     // obtener la última cita

                                                let sesion = {
                                                    sesion: "",
                                                    estatus_sesion: "",
                                                    fk_cita_sesion: respuestaCita.data.insertId
                                                }

                                                // Crear la sesión
                                                const respuestaSesion = await SesionesService.crearSesion(sesion);

                                                if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                    Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                        if (result.isConfirmed) {
                                                            refreshPage();
                                                        }
                                                    }).then(function () {
                                                        refreshPage();
                                                    });

                                                } else {
                                                    mostrarError("Hubo un problema al registrar la cita");
                                                    console.log("Estatus = 200");
                                                }

                                            } catch (error) {
                                                mostrarError("Hubo un problema al registrar la cita");
                                                console.log('Error al realizar la inserción:', error.message);
                                            }

                                        } else {
                                            mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                        }
                                    }
                                } else {
                                    mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                                }

                            } else if (citaTranscurrida === 0) {

                                if (sumaMin > sumMinAct) {

                                    const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                    const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);
                                    const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                                    if (citaAnt) {

                                        const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                        if (tiempo < 60 || tiempo > 60) {

                                            mostrarErrorHora("La cita tiene que durar una hora.");

                                        } else if (tiempo === 60) {

                                            const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                            if (solapamiento === true) {
                                                let cita = {
                                                    fecha: fechaFormateada,
                                                    hora_i: datos.horaI,
                                                    hora_f: datos.horaF,
                                                    modalidad: datos.modalidad,
                                                    estatus_cita: "Confirmada",
                                                    comentario: datos.comentario,
                                                    fk_paciente: usuario.id_paciente,
                                                    fk_psicologo: parseInt(datos.idPsicologo)
                                                }
                                                try {

                                                    // Crear la cita
                                                    const respuestaCita = await CitasService.crearCita(cita);
                                                    // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                                    // no funciona
                                                    // const ultimaCita = await CitasService.getUltimaCita(datos.idPsicologo);     // obtener la última cita

                                                    let sesion = {
                                                        sesion: "",
                                                        estatus_sesion: "",
                                                        fk_cita: respuestaCita.data.insertId
                                                    }

                                                    // Crear la sesión
                                                    const respuestaSesion = await SesionesService.crearSesion(sesion);

                                                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                        Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                            if (result.isConfirmed) {
                                                                refreshPage();
                                                            }
                                                        }).then(function () {
                                                            refreshPage();
                                                        });

                                                    } else {
                                                        mostrarError("Hubo un problema al registrar la cita");
                                                        console.log("Estatus = 200");
                                                    }

                                                } catch (error) {
                                                    mostrarError("Hubo un problema al registrar la cita");
                                                    console.log('Error al realizar la inserción:', error.message);
                                                }

                                            } else {
                                                mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                            }
                                        }
                                    } else {
                                        mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                                    }

                                } else {
                                    mostrarErrorHora(`La hora ${datos.horaI} del día ${formatoFecha} que intentas registrar ya transcurrió.`);
                                }
                            }

                        } else {
                            mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                        }

                    } else {
                        mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                    }

                } else {
                    mostrarErrorFecha(`Px2. Fecha incorrecta, la fecha ${formatoFecha} ya no es actual.`);
                }
            }
        }
    }

    const editarCitaPX = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);

        let psi;    // guardar datos del psicólogo

        for (let i = 0; i < psicologos.length; i++) {
            if (psicologos[i].id_psicologo === data[0].id_psicologo) {
                psi = psicologos[i]
            }
        }

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCitaPX psicologo={psi} fechaF={fecha} horaI={horaI} horaF={horaF} modalidad={data[0].modalidad} comentario={data[0].comentario} />
        );

        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            showCancelButton: true,
            confirmButtonText: "Guardar cambios",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const psicologo = document.getElementById("select-psicologo").value;
                const fecha = moment(document.getElementById("input-fecha").value).format('DD-MM-YYYY');
                const horaI = document.getElementById("input-horaI").value;
                const horaF = document.getElementById("input-horaF").value;
                const modalidad = document.getElementById("select-modalidad").value;
                const comentario = document.getElementById("input-comentario").value;
                const estatus_cita = "Modificada";
                return { psicologo, fecha, horaI, horaF, modalidad, comentario, estatus_cita };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                editarDatosPX(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);   // data[0].fecha, para mantener la fecha original antes de editar
            }
        });
    }

    const editarDatosPX = async (datos, idCita, feOrig, hrOrig) => {

        // ******* validando fechas *******

        // fecha actual
        const hoy = new Date();
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        console.log("fechaActual:  " + fechaActual)

        /*--------- Horarios NO permitidos -----------------*/
        // validando cita pasada, para que no se puedan editar
        const feLetras = feOrig + " " + hrOrig;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        console.log("fecha - hora Original: " + feLetras)
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaOriginal = fOr.getTime();    // convertir a mls
        console.log("fechaOriginal:  " + fechaOriginal)

        // la diferencia entre fechas es igual a días y horas
        // fechaOriginal: para no poder editar citas de fechas ya transcurridas
        const resta = (parseInt(fechaActual) - parseInt(fechaOriginal)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        console.log("citaTranscurrida: " + res)
        const citaTranscurrida = (res);    // es el tiempo en hrs entre los días pasados (+) o futuros (-) de la cita agendada y el día actual
        // si la fecha original de la cita tiene una != > a +24hrs, +48hrs, etc, es una cita con fecha menor a la actual
        // si es = 0, se está editando una cita en el día actual
        // si es < 0 es una cita que está en días posteriores al actual


        /*---------- horarios permitidos ----------------*/
        // validando fecha input, para editar citas en horarios permitidos despúes del día actual
        // para saber si en el día actual la edición de la cita es antes o después de la hra actual
        const letras = datos.fecha + " " + datos.horaI;
        console.log("fecha - hora Input: " + letras)
        const fecIn = new Date(letras);   // convierte string fecha a tipo dato: new Date()
        fecIn.setHours(0);
        fecIn.setMinutes(0);
        fecIn.setSeconds(0);
        fecIn.setMilliseconds(0);
        const fechaInput = fecIn.getTime();

        // para validar que la edidción de la cita, la fecha no sea menor a la actual
        console.log("fechaActual: " + fechaActual)
        console.log("fechaInput: " + fechaInput)
        const resta2 = (parseInt(fechaActual) - parseInt(fechaInput)) / 1000;
        const res2 = resta2 / 3600;
        console.log("citaTranscurrida2: " + res2)
        const citaTranscurrida2 = (res2);   // si la fecha input de la cita tiene una != de -24hrs, -48hr, etc,
        // es una cita que se quiere editarse de días posteriores al día actual o días pasados
        // si es = 0, se está editando una cita en el día actual

        /*--------------------------*/


        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora original antes de modificar el input de la nueva hora
        const hrOrIn = hrOrig.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMinOr = parseInt(hrOrIn[0]) * 60 + parseInt(hrOrIn[1]);   // los minutos de la hr original del input antes de modificarse

        // validando hora actual menor a la hra ingresada en el input
        const hrIn = datos.horaI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMin = parseInt(hrIn[0]) * 60 + parseInt(hrIn[1]);     // los minutos de la hr del input después de modificarse

        const restaHra = (sumMinAct - sumaMinOr) / 60;    // diferencia entre horas entre la hora actual y la hr orig de una cita
        // si la hra orig < a la actual, la cita ya pasó
        const restaMin = (sumMinAct - sumaMinOr);
        console.log("dif entre minAc y minOr: " + restaMin)

        const formatoFecha = moment(datos.fecha).format("DD-MM-YYYY");


        if (citaTranscurrida < 0) {

            if (citaTranscurrida2 === 0) {     // Una cita se está recorriendo al día actual

                if (validarFecha(datos.fecha)) {

                    if (sumaMin > sumMinAct) {     // validar hora actual con la hora input, si hra input > hra actual, es correcto

                        if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                            if (datos.horaI >= datos.horaF) {

                                mostrarErrorHora("La hora Inicio debe ser menor que la hora Final.");

                            } else {

                                const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                                const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                if (tiempo < 60 || tiempo > 60) {

                                    mostrarError("La cita tiene que durar una hora.");

                                } else if (tiempo === 60) {

                                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                    if (solapamiento === true) {
                                        let cita = {
                                            fecha: fechaFormateada,
                                            hora_i: datos.horaI,
                                            hora_f: datos.horaF,
                                            modalidad: datos.modalidad,
                                            estatus_cita: datos.estatus_cita,
                                            comentario: datos.comentario,
                                            id_paciente: usuario.id_paciente,
                                            id_psicologo: parseInt(datos.psicologo),
                                            id_cita: idCita
                                        }

                                        try {

                                            const respuesta = await CitasService.editarCita(cita, idCita);
                                            console.log(respuesta.status)

                                            if (respuesta.status === 200) {
                                                Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                });
                                            } else {
                                                mostrarError("Hubo un problema al registrar la cita.");
                                                console.log("Estatus 200")
                                            }
                                        } catch (error) {
                                            mostrarError("Hubo un problema al registrar la cita");
                                            console.log('Error al realizar la inserción:', error.message);
                                        }
                                    } else {
                                        mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                                    }
                                }
                            }

                        } else {
                            mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                        }

                    } else {
                        mostrarErrorHora(`La hora ${datos.horaI} que tratas de ingresar para el día ${dateHoy} ya transcurrió.`);
                    }

                } else {
                    mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                }

            } else if (citaTranscurrida2 < 0) {     // es una cita que se mueve de días, horas después del día actual

                if (validarFecha(datos.fecha)) {

                    if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                        if (datos.horaI >= datos.horaF) {

                            mostrarErrorHora("La hora Inicio debe ser menor que la hora Final.");

                        } else {

                            const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                            if (tiempo < 60 || tiempo > 60) {

                                mostrarError("La cita tiene que durar una hora.");

                            } else if (tiempo === 60) {

                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                if (solapamiento === true) {
                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: datos.estatus_cita,
                                        comentario: datos.comentario,
                                        id_paciente: usuario.id_paciente,
                                        id_psicologo: parseInt(datos.psicologo),
                                        id_cita: idCita
                                    }

                                    try {

                                        const respuesta = await CitasService.editarCita(cita, idCita);
                                        console.log(respuesta.status)

                                        if (respuesta.status === 200) {
                                            Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            });
                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log("Estatus 200")
                                        }
                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }
                                } else {
                                    mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                                }
                            }
                        }

                    } else {
                        mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                    }

                } else {
                    mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                }
            }

        } else if (citaTranscurrida >= 0 && citaTranscurrida < 10) {     // la edición de la cita es en la fecha y/o hora actual o f y hr que ya transcurrió

            if (validarFecha(datos.fecha)) {
                if (sumaMin > sumMinAct) {

                    if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                        if (datos.horaI >= datos.horaF) {

                            mostrarErrorHora("La hora Inicio debe ser menor que la hora Final.");

                        } else {

                            const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                            if (tiempo < 60 || tiempo > 60) {

                                mostrarError("La cita tiene que durar una hora.");

                            } else if (tiempo === 60) {

                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                if (solapamiento === true) {
                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: datos.estatus_cita,
                                        comentario: datos.comentario,
                                        id_paciente: usuario.id_paciente,
                                        id_psicologo: parseInt(datos.psicologo),
                                        id_cita: idCita
                                    }

                                    try {

                                        const respuesta = await CitasService.editarCita(cita, idCita);
                                        console.log(respuesta.status)

                                        if (respuesta.status === 200) {
                                            Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            });
                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log("Estatus 200")
                                        }
                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }
                                } else {
                                    mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                                }
                            }
                        }

                    } else {
                        mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                    }

                } else {
                    mostrarErrorHora(`Estás ingresando la hora ${datos.horaI} ya transcurrida.`);
                }

            } else {
                mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
            }

        } else {
            mostrarErrorHora(`Estás tratando de editar una cita de ${Math.trunc(citaTranscurrida + restaHra)} hrs atrás.`);
        }
    }

    const eliminarCitaPX = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatosPX(data[0].id_cita, data[0].hora_i, data[0].fecha);
            }
        });
    }

    const eliminarDatosPX = async (idCita, hraI, fecha) => {

        // fecha actual
        const hoy = new Date();
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas

        // validando cita pasada
        const feLetras = fecha + " " + hraI;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaOriginal = fOr.getTime();    // convertir a mls

        // la diferencia entre fechas es igual a días y horas
        const resta = (parseInt(fechaOriginal) - parseInt(fechaActual)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        const citaTranscurrida = (res);    // si la fecha input de la cita tiene una != > a 24hrs, ya es una cita pasada.
        // si es = 0, se está editando una cita en el día actual

        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora original antes de modificar el input de la nueva hora
        const hrOrIn = hraI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMinOr = parseInt(hrOrIn[0]) * 60 + parseInt(hrOrIn[1]);   // los minutos de la hr original del input antes de modificarse


        if (fechaOriginal >= fechaActual) {      // la fecha de la cita aun no transcurre

            if (citaTranscurrida > 0) {

                const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                const respuestaCita = await CitasService.eliminarCita(idCita);
                if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                    Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });
                } else {
                    mostrarError("Hubo un problema al eliminar la cita.");
                }

            } else if (citaTranscurrida === 0) {     // se está eliminando una cita de la fecha en transcurso (actual)

                if (sumaMinOr > sumMinAct) {

                    const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                    const respuestaCita = await CitasService.eliminarCita(idCita);
                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                        Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        }).then(function () {
                            refreshPage();
                        });
                    } else {
                        mostrarError("Hubo un problema al eliminar la cita.");
                    }

                } else {
                    mostrarError("Estás tratando de eliminar una cita ya transcurrida.");
                }

            } else {
                mostrarError("Estás tratando de eliminar una cita del día, antes de que transcurrida.");
            }

        } else {
            mostrarError("No está permitido eliminar las citas programadas ya transcurridas.");
        }
    }

    /********************************************* */


    const handleComentario = (index, comentario) => {
        const pendienteComentario = pendientes[index]
        pendienteComentario.comentario = comentario;
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }



    return (
        <Layout>
            <Content>
                <div className="vista-calendario">
                    <div className="encabezado-usuario">
                        <div className="px_sesion_fam">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        {
                            usuario.rol === "pax" ? (
                                <div className="px_sesion_fam">
                                    <label id="lbl-enSesion"> Psicólogo que atiende: &nbsp; </label>
                                    <label>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</label>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                    <div className="encabezado-agenda">
                        <label>Agenda</label>
                    </div>
                    {usuario.rol === "psi" ? (
                        <div className="calendario-datos">
                            <div className="calend">
                                {eventos !== undefined && eventos.length > 0 ? (
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                        }}
                                        buttonText={{
                                            day: 'Dia',
                                            today: 'Hoy', // Cambia el texto del botón "Hoy"
                                            month: 'Mes', // Cambia el texto del botón "Mes"
                                            week: 'Semana', // Cambia el texto del botón "Semana"
                                            year: 'Año' // Cambia el texto del botón "Día"
                                        }}
                                        locales={[esLocale]}
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                            meridiem: 'short',
                                        }}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                        }}
                                        nowIndicator={true}
                                        slotMinTime="07:00:00"
                                        slotMaxTime="22:00:00"
                                        allDaySlot={false}
                                        themeSystem="pulse"
                                        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                        initialView="timeGridWeek"
                                        events={eventos}
                                        dateClick={agendarCita}
                                        eventClick={informacionPaciente}
                                        eventDrop={moverCita}
                                    />
                                ) : (
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                        }}
                                        buttonText={{
                                            day: 'Dia',
                                            today: 'Hoy', // Cambia el texto del botón "Hoy"
                                            month: 'Mes', // Cambia el texto del botón "Mes"
                                            week: 'Semana', // Cambia el texto del botón "Semana"
                                            year: 'Año' // Cambia el texto del botón "Día"
                                        }}
                                        locales={[esLocale]}
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                            meridiem: 'short',
                                        }}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                        }}
                                        nowIndicator={true}
                                        slotMinTime="07:00:00"
                                        slotMaxTime="22:00:00"
                                        allDaySlot={false}
                                        themeSystem="pulse"
                                        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                        initialView="timeGridWeek"
                                        dateClick={agendarCita}
                                        eventClick={informacionPaciente}
                                    />
                                )}
                            </div>
                            <div className="datos-paciente">
                                <div className="edit_delete-pendientes">
                                    <div id="px_h3_calend">
                                        <label id="lbl_datos_calend">Pendientes</label>
                                    </div>
                                </div>
                                <div className="div-pendientes-textArea">
                                    <div>
                                        {
                                            pendientes.length === 0 ? (
                                                <div className="div-pendientes-edit-delete">
                                                    <div className="div-pendientes-label">
                                                        <label>No existen pendientes añadidos</label>
                                                    </div>
                                                </div>
                                            ) : (
                                                pendientes.map((pendiente, index) => (
                                                    <div>
                                                        <div className="div-pendientes-edit-delete">
                                                            <div key={index} id="id-hora-fecha-pendientes">
                                                                <label> Registrado el día: {pendiente.fecha} </label>
                                                            </div>
                                                            <div className="div-pendientes-toolTip">
                                                                <div id="container-toolTip-save">
                                                                    <span className="lbl-toolTip-save">
                                                                        Editar
                                                                    </span>
                                                                    { /*   entorno local
                                                                        <a href="/pendientes">
                                                                        <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                                    </a>
                                                                    */}

                                                                    {/*  entorno web   */}
                                                                    <a href="#/pendientes">
                                                                        <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                                    </a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div-notif-px">
                                                            <p style={{ border: "none" }}>
                                                                <textarea id="textArea-pendientes" disabled rows="4" cols="50" onChange={(event) => handleComentario(index, event.target.value)} defaultValue={pendiente.comentario}></textarea>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {usuario.rol === "pax" ? (
                                <div className="calendario-datos">
                                    <div className="calend">
                                        {eventos !== undefined && eventos.length > 0 ? (
                                            <FullCalendar
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                                }}
                                                buttonText={{
                                                    day: 'Dia',
                                                    today: 'Hoy', // Cambia el texto del botón "Hoy"
                                                    month: 'Mes', // Cambia el texto del botón "Mes"
                                                    week: 'Semana', // Cambia el texto del botón "Semana"
                                                    year: 'Año' // Cambia el texto del botón "Día"
                                                }}
                                                locales={[esLocale]}
                                                slotLabelFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                    meridiem: 'short',
                                                }}
                                                eventTimeFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                }}
                                                nowIndicator={true}
                                                slotMinTime="07:00:00"
                                                slotMaxTime="22:00:00"
                                                allDaySlot={false}
                                                themeSystem="pulse"
                                                plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                                initialView="timeGridWeek"
                                                events={eventos}
                                                dateClick={agendarCitaPX}
                                                eventClick={informacionPacientePX}
                                                eventDrop={moverCita}
                                            />
                                        ) : (
                                            <FullCalendar
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                                }}
                                                buttonText={{
                                                    day: 'Dia',
                                                    today: 'Hoy', // Cambia el texto del botón "Hoy"
                                                    month: 'Mes', // Cambia el texto del botón "Mes"
                                                    week: 'Semana', // Cambia el texto del botón "Semana"
                                                    year: 'Año' // Cambia el texto del botón "Día"
                                                }}
                                                locales={[esLocale]}
                                                slotLabelFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                    meridiem: 'short',
                                                }}
                                                eventTimeFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                }}
                                                nowIndicator={true}
                                                slotMinTime="07:00:00"
                                                slotMaxTime="22:00:00"
                                                allDaySlot={false}
                                                themeSystem="pulse"
                                                plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                                initialView="timeGridWeek"
                                                dateClick={agendarCitaPX}
                                                eventClick={informacionPacientePX}
                                            />
                                        )}
                                    </div>
                                    <div className="datos-paciente">
                                        <div className="edit_delete-pendientes">
                                            <div id="px_h3_pax">
                                                <label>Recomendaciones</label>
                                            </div>
                                        </div>
                                        <div className="div-pendientes-textArea">
                                            <div>
                                                {
                                                    recomendacion.length === 0 ? (
                                                        <div className="div-pendientes-edit-delete">
                                                            <div className="div-pendientes-toolTip">
                                                                <label>No existen recomendaciones añadidas</label>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        recomendacion.map((recomendacion, index) => (
                                                            <div className="div-pendientes-box">
                                                                <div className="div-pendientes-edit-delete">
                                                                    <div key={index} id="id-hora-fecha-pendientes">
                                                                        <label> Registrada el día: {moment(recomendacion.fecha).format('DD-MM-YYYY')} </label>
                                                                    </div>
                                                                </div>
                                                                <div className="div-notif-px">
                                                                    <p style={{ border: "none" }}>
                                                                        <textarea id="textArea-pendientes" disabled rows="4" cols="50" defaultValue={recomendacion.recomendacion}></textarea>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {usuario.rol === 'adm' ? (
                                        <div className="calendario-datos">

                                            <h1>Agenda...</h1>

                                            <div className="datos-paciente">
                                                <div className="edit_delete-pendientes">
                                                    <div id="px_h3">
                                                        <h3>Pendientes del sistema</h3>
                                                    </div>
                                                </div>
                                                <div className="div-pendientes-textArea">

                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Content>
        </Layout >
    );
};

export default Calendario;
