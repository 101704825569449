
import axios from "axios";

const TRANSFER_API_BASE_URL = "/server/transferir";
const CREATE_TRANSFER_API_BASE_URL = "/server/createTransferir";

// const TRANSFER_API_BASE_URL = "http://localhost:3002/transferir";
// const CREATE_TRANSFER_API_BASE_URL = "http://localhost:3002/createTransferir";


class TransferirService {

    async getTransferirRecibe (psic_recibe) {
        const response = await axios.get(`${TRANSFER_API_BASE_URL}Recibe?psic_recibe=${psic_recibe}`)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('TS getTransferirRecibe: ', error);
            })
            const transfer = response.data;
            return transfer;
    }


    async getTransferirRecibeIndividual (id_paciente, psic_envia, psic_recibe) {
        const response = await axios.get(`${TRANSFER_API_BASE_URL}RecibeIndividual?id_paciente=${id_paciente}&psic_envia=${psic_envia}&psic_recibe=${psic_recibe}`)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('TS getTransferirRecibeIndividual: ', error);
            })
            const transfer = response.data;
            return transfer;
    }




    async getTransferirEnviaIndividual (id_paciente, psic_envia, psic_recibe) {
        const response = await axios.get(`${TRANSFER_API_BASE_URL}EnviaIndividual?id_paciente=${id_paciente}&psic_envia=${psic_envia}&psic_recibe=${psic_recibe}`)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('TS getTransferirEnviaIndividual: ', error);
            })
            const transfer = response.data;
            return transfer;
    }




    async getTransferirEnvia (psic_envia) {
        const response = await axios.get(`${TRANSFER_API_BASE_URL}Envia?psic_envia=${psic_envia}`)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('TS getTransferirEnvia: ', error);
            })
            const transfer = response.data;
            return transfer;
    }


    async getTransferirIndividual (id_paciente) {
        const response = await axios.get(`${TRANSFER_API_BASE_URL}Individual?id_paciente=${id_paciente}`)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('TS getTransferirIndividual: ', error);
            })
            const transfer = response.data;
            return transfer;
    }


    async createTransfer (transfer) {
        const response = await axios.post(CREATE_TRANSFER_API_BASE_URL, transfer)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('TS createTransfer: ' , error);
            })
            const transferir = response.data;
            return transferir;
    }


}

export default new TransferirService;


