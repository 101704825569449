

import React from "react";

import "../../styles/FormularioEditarRecomendacion.css";
import TextA from "../Recomendaciones/TextArea";



const FormularioEditarRecomendacion = ({ rec, pac }) => {

    return (
        <div className="div-editRecom">
            <table className="table-recom">
                <tbody>
                    <tr>
                        <td id="td-editRec-izq">
                            <label> Paciente: </label>
                        </td>
                        <td id="td-editRec-der">
                            <label>{pac.nombre} {pac.ap1} {pac.ap2}</label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="div-editRec-txtA">
                <textarea id="editRecomend" defaultValue={rec.recomendacion}></textarea>
            </div>
        </div>
    )
}


export default FormularioEditarRecomendacion;


