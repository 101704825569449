
// -------- HeaderAdmin ---------------- //

import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../services/AuthContext';

import "../styles/header.css";
import "../styles/MenuUsuario.css";

import logo from "../img/psiquedata_logo_trans.png";
import img_avatar from "../img/avatar.png";
import img_salir from "../img/logout_black.svg";




const HeaderAdmin = () => {

  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);
  const { logout } = useAuth();
  const navigate = useNavigate();

  /*  Entorno web:   ligas href="#/pac" llevan símbolo #, por el método de rutas: HashRouter
      Los navigate(/pacientes) de rutas primarias, no llevan #, HashRouter antecede el símbolo #
  
    Entorno local: se puede omitir #
  
  */


  const cerrarSesion = () => {
    logout();
    navigate("/");
  }

  const handleAdministracion = () => {
    navigate("/administracion");
  }

  return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button className="btn btn-primary" type="button" onClick={handleAdministracion}>
          ☰ Reporte
        </button>
        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Cuenta
        </button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href={`#/admin/${usuario.id_admin}`}>
            <img id="img_profile" src={img_avatar} alt="img_profile"></img>
            {usuario.nombre} {usuario.ap1} {usuario.ap2}
          </a>
          </li>
        </ul>
        <button className="btn btn-primary" type="button" onClick={cerrarSesion}>
          <img id="img_salir" src={img_salir} alt="img_salir"></img>
          Salir
        </button>
      </div>
    </div>
  )
};

export default HeaderAdmin;