

class InformacionPacientes {

    estadoCivil(edoCivil) {
        switch (edoCivil) {
            case "ca":
                return "Casado";
            case "div":
                return "Divorciado";
            case "sol":
                return "Soltero";
            case "sep":
                return "Separado";
            case "viu":
                return "Viudo";
            case "con":
                return "Concubinato";
            default:
                console.log("Estado civil no reconocido.");
        }
    }


    estadoCivilIvertir(edoCivil) {
        switch (edoCivil) {
            case "Casado":
                return "ca";
            case "Divorciado":
                return "div";
            case "Soltero":
                return "sol";
            case "Separado":
                return "sep";
            case "Viudo":
                return "viu";
            case "Concubinato":
                return "con";
            default:
                console.log("Estado civil no reconocido.");
        }
    }

    sexoPaciente(sexP) {
        switch (sexP) {
            case "m":
                return "Masculino";
            case "f":
                return "Femenino";
            default:
                console.log("Sexo del paciente no reconocido.");
        }
    }

    paisesPaciente(paP) {
        switch (paP) {
            case "MX":
                return "México";
            case "US":
                return "Estados Unidos";
            case "CA":
                return "Canadá";
            default:
                console.log("País del paciente no reconocido.");
        }
    }


    paisesPacienteInvertir(paP) {
        switch (paP) {
            case "México":
                return "MX";
            case "Estados UnidosUS":
                return "US";
            case "Canadá":
                return "CA";
            default:
                console.log("País del paciente no reconocido.");
        }
    }


    estadosRepublica(edoMx) {
        switch (edoMx) {
            case "ext":
                return "Extranjero";
            case "a":
                return "Aguascalientes";
            case "b":
                return "Baja California";
            case "bs":
                return "Baja California Sur";
            case "c":
                return "Campeche";
            case "ch":
                return "Chiapas";
            case "chi":
                return "Chihuahua";
            case "co":
                return "Coahuila";
            case "col":
                return "Colima";
            case "cd":
                return "Ciudad de México";
            case "d":
                return "Durango";
            case "em":
                return "Estado de México";
            case "g":
                return "Guanajuato";
            case "gu":
                return "Guerrero";
            case "h":
                return "Hidalgo";
            case "j":
                return "Jalisco";
            case "mi":
                return "Michoacán";
            case "mo":
                return "Morelos";
            case "n":
                return "Nayarit";
            case "nl":
                return "Nuevo León";
            case "o":
                return "Oaxaca";
            case "p":
                return "Puebla";
            case "q":
                return "Querétaro";
            case "qr":
                return "Quintana Roo";
            case "sn":
                return "San Luis Potosí";
            case "s":
                return "Sinaloa";
            case "so":
                return "Sonora";
            case "t":
                return "Tabasco";
            case "ta":
                return "Tamaulipas";
            case "tl":
                return "Tlaxcala";
            case "v":
                return "Veracruz";
            case "y":
                return "Yucatán";
            case "z":
                return "Zacatecas";
            default:
                console.log("Estado de la República no reconocido.");
        }
    }


    estadosRepublicaInvertir(edoMx) {
        switch (edoMx) {
            case "Extranjero":
                return "ext";
            case "Aguascalientes":
                return "a";
            case "Baja California":
                return "b";
            case "Baja California Sur":
                return "bs";
            case "Campeche":
                return "c";
            case "Chiapas":
                return "ch";
            case "Chihuahua":
                return "chi";
            case "Coahuila":
                return "co";
            case "Colima":
                return "col";
            case "Ciudad de México":
                return "cd";
            case "Durango":
                return "d";
            case "Estado de México":
                return "em";
            case "Guanajuato":
                return "g";
            case "Guerrero":
                return "gu";
            case "Hidalgo":
                return "h";
            case "Jalisco":
                return "j";
            case "Michoacán":
                return "mi";
            case "Morelos":
                return "mo";
            case "Nayarit":
                return "n";
            case "Nuevo León":
                return "nl";
            case "Oaxaca":
                return "o";
            case "Puebla":
                return "p";
            case "Querétaro":
                return "q";
            case "Quintana Roo":
                return "qr";
            case "San Luis Potosí":
                return "sn";
            case "Sinaloa":
                return "s";
            case "Sonora":
                return "so";
            case "Tabasco":
                return "t";
            case "Tamaulipas":
                return "ta";
            case "Tlaxcala":
                return "tl";
            case "Veracruz":
                return "v";
            case "Yucatán":
                return "y";
            case "Zacatecas":
                return "z";
            default:
                console.log("Estado de la República no reconocido.");
        }
    }

}


export default new InformacionPacientes();



