
import React from "react";
import HeaderAddPxLogin from "../components/HeaderAddPxLogin";
import Content from "../components/Content";
import Footer from "../components/Footer";


const LayoutAddPxLogin = ({ children }) => {

  return (
    <div className="Layout">
      <div className="div-layout-header">
        <HeaderAddPxLogin />
      </div>
      <div className="div-layout-content">
        <Content>
          {children}
        </Content>
      </div>
      <div className="div-layout-footer">
        <Footer />
      </div>
    </div>
  );
};

export default LayoutAddPxLogin;



