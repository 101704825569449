import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../../containers/Layout";
import Content from "../../components/Content";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import ColegasService from "../../services/ColegasService";
import CitasService from "../../services/CitasService";

import "../../styles/Pacientes.css";
import "../../styles/Calendario.css";
import "../../styles/Administracion.css";

import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_photo from "../../img/photo_camera.svg";
import img_person from "../../img/person_add.svg";
import CanalizarService from "../../services/CanalizarService";
import FamiliaresService from "../../services/FamiliaresService";
import ExpedientesService from "../../services/ExpedientesService";
import SesionesService from "../../services/SesionesService";


const Administracion = () => {

    const [pacientes, setPacientes] = useState([]);     // individual
    const [pacientesTodos, setPacientesTodos] = useState([]);     // Todos los pac, se usa en Expediente
    const [pxCanalizadosTodos, setPxCanalizadosTodos] = useState([]);     // Todos
    const [psicologosTodos, setPsicologosTodos] = useState([]);
    const [colegasTodos, setColegasTodos] = useState([]);
    const [famNoEsPaciente, setFamNoEsPaciente] = useState([]);
    const [famEsPaciente, setFamEsPaciente] = useState([]);
    const [sesionesTodas, setSesionesTodas] = useState([]);
    const [informacionSesion, setInformacionSesion] = useState([]);
    const [expedientesTodos, setExpedientesTodos] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [informacionExpediente, setInformacionExpediente] = useState([]);     // exp individual
    const [informacionFNP, setInformacionFNP] = useState([]);
    const [informacionEP, setInformacionEP] = useState([]);
    const [informacionCita, setInformacionCita] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState("");     // Estado para el valor del select

    const [selectValuePxAct, setSelectValuePxAct] = useState("");     // Estado para el valor del select Px mostrar datos
    const [selectValuePxInAct, setSelectValuePxInAct] = useState("");     // Estado para el valor del select Px mostrar datos
    const [selectValuePxCan, setSelectValuePxCan] = useState("");     // Estado para el valor del select Px mostrar datos
    const [selectValuePsi, setSelectValuePsi] = useState("");     // Estado para el valor del select Psi mostrar datos
    const [selectValueCol, setSelectValueCol] = useState("");     // Estado para el valor del select Col mostrar datos
    const [selectValueFam, setSelectValueFam] = useState("");     // Estado para el valor del select Fam mostrar datos
    const [selectValueFamEP, setSelectValueFamEP] = useState("");
    const [selectValueSesiones, setSelectValueSesiones] = useState("");
    const [selectValueCita, setSelectValueCita] = useState("");

    const [selectValueExp, setSelectValueExp] = useState("");

    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [fotografia, setFotografia] = useState('');

    const [pxInAct, setPxInAct] = useState([]);
    const [pxAct, setPxAct] = useState([]);
    const [contPxTodos, setContadorPxTodos] = useState();
    const [contPxActivos, setContadorPxActivos] = useState();
    const [contPxInActivos, setContadorPxInActivos] = useState();
    const [contPxCan, setContPxCan] = useState();
    const [contPsicologos, setContPsicologos] = useState();
    const [contColegas, setContColegas] = useState();
    const [contFNP, setContFNP] = useState();
    const [contFEP, setContFEP] = useState();
    const [contExped, setContExpedientes] = useState();
    const [fechaFinCan, setFechaFinCan] = useState();
    const [fechaInCan, setFechaInCan] = useState();

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {

        const data2 = await PacientesService.getPacientesTodos();
        setPacientesTodos(data2);
        let pxAct = [];
        let pxInAct = [];
        let inac = 0;
        let ac = 0;
        for (let i = 0; i < data2.length; i++) {
            if (data2[i].estatus === "A") {
                pxAct.push(data2[i]);
                ac++;
            } else if (data2[i].estatus === "I") {
                pxInAct.push(data2[i]);
                inac++;
            }
        }
        setPxAct(pxAct);
        setPxInAct(pxInAct);
        setContadorPxActivos(ac);
        setContadorPxInActivos(inac);
        setContadorPxTodos(ac + inac);


        const data3 = await CanalizarService.getPacientesCanalizadosTodos();
        setPxCanalizadosTodos(data3);
        let pxCan = 0;
        for (let i = 0; i < data3.length; i++) {
            pxCan++;
        }
        setContPxCan(pxCan);

        const data4 = await PsicologoService.getPsicologos();
        setPsicologosTodos(data4);
        let psic = 0;
        for (let i = 0; i < data4.length; i++) {
            psic++;
        }
        setContPsicologos(psic);

        const data5 = await ColegasService.getColegasTodos();
        setColegasTodos(data5);
        let col = 0;
        for (let i = 0; i < data5.length; i++) {
            col++;
        }
        setContColegas(col);


        const data6 = await FamiliaresService.getFamiliarNoEsPacienteLista();
        let fnp = 0;
        for (let i = 0; i < data6.length; i++) {
            fnp++;
        }
        setFamNoEsPaciente(data6);
        setContFNP(fnp);


        const data7 = await FamiliaresService.getFamiliarEsPacienteTodos();
        setFamEsPaciente(data7);
        let fenp = 0;
        for (let i = 0; i < data7.length; i++) {
            fenp++;
        }
        setContFEP(fenp);


        const data8 = await ExpedientesService.getExpedientesTodos();
        setExpedientesTodos(data8);
        let exp = 0;
        for (let i = 0; i < data8.length; i++) {
            exp++;
        }
        setContExpedientes(exp);


        const data9 = await SesionesService.getSesionesTodas();
        setSesionesTodas(data9);

    };

    useEffect(() => {
        getData();
    }, [])


    // Información del paciente
    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);
        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }
        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data);  // get base64 de DB
    };

    const cargarInformacionPcCanalizados = async (id_paciente) => {
        const data = await CanalizarService.getPacienteCanalizado(id_paciente);
        // obtener foto convertida
        const cont = data[0].p_fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }
        setInformacion(data[0]);
        setFotografia(data[0].p_fotografia.data);  // get base64 de DB

        if (data[0].fecha_f === "") {
            setFechaFinCan("En proceso...")
        } else {
            setFechaFinCan(data[0].fecha_f)
        }

        let fecha1 = "";
        let fecha2 = "";
        fecha1 = data[0].fecha_i.split("-");
        fecha2 = fecha1[2] + "-" + fecha1[1] + "-" + fecha1[0];
        setFechaInCan(fecha2);
    };

    // Información del psicólogo
    const cargarInformacionPsi = async (id_psicologo) => {
        const data = await PsicologoService.getPsicologoIndividual(id_psicologo);
        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }
        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data);  // get base64 de DB
    };

    // Información del Colega
    const cargarInformacionCol = async (id_colega) => {
        const data = await ColegasService.getInfoColega(id_colega);
        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }
        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data);  // get base64 de DB
    };

    // Información del Familiar No es Pac
    const cargarInformacionFam = async (id_familiar) => {

        setFoto("");
        setFotografia("");

        const data = await FamiliaresService.getFamNoEsPaciente(id_familiar);

        // obtener foto convertida
        const cont = data[0].u_fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setInformacionFNP(data);
        setFotografia(data[0].u_fotografia.data);  // get base64 de DB
    };

    // Información del Familiar es Pac
    const cargarInformacionFamEP = async (id_paciente_familiar) => {
        setFoto("");
        setFotografia("");
        const data = await FamiliaresService.getFamiliarEsPacientePxFam(id_paciente_familiar);
        // obtener foto convertida
        const cont = data[0].pf_fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }
        setInformacionEP(data);
        setFotografia(data[0].pf_fotografia.data);  // get base64 de DB
    };

    // Información de Expediente
    const cargarInformacionExpediente = async (id_paciente) => {
        const data = await ExpedientesService.getExpediente(id_paciente);

        setInformacionExpediente(data[0]);

    };

    const cargarInformacionCitas = async (id_paciente) => {
        const data = await CitasService.getCitasPacientePX(id_paciente);
        setInformacionCita(data);
    }

    const cargarInformacionSesiones = async (id_paciente) => {
        const data = await SesionesService.getSesionesHistorial(id_paciente);
        setInformacionSesion(data);
    }

    const agregarPaciente = () => {
        navigate('/AddPacienteExpressAdmin');        // entorno web
    };

    const editarPaciente = (id_paciente) => {
        //navigate(`/editpaciente/${informacion.id_paciente}`);       // entorno local
        navigate(`/editpacienteadmin/${id_paciente}`);       // entorno web
    }

    const eliminarPaciente = async (id_paciente) => {
        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar el paciente?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatos(id_paciente);
            }
        });
    };

    const eliminarDatos = async (id_paciente) => {
        const respuesta = await PacientesService.eliminarPacienteL(id_paciente);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó al paciente correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar al paciente!", "", "error");
        }
    };

    const agregarPsicologo = () => {
        navigate('/addpsicologo');      // entorno web
    };

    const editarPsicologo = (id_psicologo) => {
        navigate(`/editpsicologoadmin/${id_psicologo}`);      // entorno web
    };

    const eliminarPsicologo = async (id_psicologo) => {
        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar al psicólogo?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatosPsicologo(id_psicologo);
            }
        });
    };

    const eliminarDatosPsicologo = async (id_psicologo) => {
        const respuesta = await PsicologoService.eliminarDatosPsicologo(id_psicologo);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó el psicólogo correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar el psicólogo!", "", "error");
        }
    };

    const agregarColega = () => {
        navigate('/addcolegaadmin');      // entorno web
    };

    const editarColega = (id_colega) => {
        navigate(`/editcolegaadmin/${id_colega}`);      // entorno web
    };

    const eliminarColega = async (id_colega) => {
        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar al colega?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatosColega(id_colega);
            }
        });
    };

    const eliminarDatosColega = async (id_colega) => {
        const respuesta = await ColegasService.eliminarColega(id_colega);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó el colega correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar el colega!", "", "error");
        }
    };

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        console.log(data);
        setPacientes(data);
    };

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes();
            console.log(data);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            console.log(data);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes();
        console.log(data);
        setPacientes(data);
    };

    const handleSelectPaxActivo = (event) => {
        setSelectValuePxAct(event.target.value);
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValueFamEP("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacion(event.target.value);
    }

    const handleSelectPaxInActivo = (event) => {
        setSelectValuePxInAct(event.target.value);
        setSelectValuePxAct("");
        setSelectValuePxCan("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValueFamEP("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacion(event.target.value);
    }

    const handleSelectPaxCan = (event) => {
        setSelectValuePxCan(event.target.value);
        setSelectValuePxInAct("");
        setSelectValuePxAct("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValueFamEP("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacionPcCanalizados(event.target.value);
    }

    const handleSelectPsi = (event) => {
        setSelectValuePsi(event.target.value);
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValueFamEP("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacionPsi(event.target.value);
    }

    const handleSelectCol = (event) => {
        setSelectValueCol(event.target.value);
        setSelectValuePsi("");
        setSelectValueFam("");
        setSelectValueFamEP("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacionCol(event.target.value);
    }

    const handleSelectFam = (event) => {
        setInformacion([]);
        setSelectValueFam(event.target.value);
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFamEP("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacionFam(event.target.value);
    }

    const handleSelectFamEP = (event) => {
        setInformacion([]);
        setSelectValueFamEP(event.target.value);
        setSelectValueFam("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacionFamEP(event.target.value);
    }

    const handleSelectExpediente = (event) => {
        setInformacion([]);
        setSelectValueExp(event.target.value);  // recibe id_expediente de vexpediente
        setSelectValueFamEP("");
        setSelectValueFam("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueCita("");
        cargarInformacionExpediente(event.target.value);    // id_expediente = id_paciente en vexpediente
    }

    const handleSelectCitas = (event) => {
        setInformacion([]);
        setInformacionSesion([]);
        setSelectValueCita(event.target.value);  // recibe id_expediente de vcita
        setSelectValueFamEP("");
        setSelectValueFam("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueExp("");
        cargarInformacionCitas(event.target.value);    // id_paciente en vcita
    }

    const handleSelectSesiones = (event) => {
        setInformacion([]);
        setInformacionCita([]);
        setSelectValueSesiones(event.target.value);  // recibe id_expediente de vsesion
        setSelectValueFamEP("");
        setSelectValueFam("");
        setSelectValuePsi("");
        setSelectValueCol("");
        setSelectValueFam("");
        setSelectValuePxAct("");
        setSelectValuePxInAct("");
        setSelectValuePxCan("");
        setSelectValueExp("");
        setSelectValueCita("");
        cargarInformacionSesiones(event.target.value);    // id_paciente en vsesion
    }

    return (
        <Layout>
            <Content>
                <div className="px_div_1_admin">
                    <div className="px_div_2_admin">
                        <div className="div_sesion_admin">
                            <div className="px_sesion_admin">
                                <label id="lbl-enSesion">En sesión: &nbsp;</label>
                                <label>{usuario.nombre} {usuario.ap1} {usuario.ap2} </label>
                            </div>
                            <div className="div_lbl_admin">
                                <label>Administración</label>
                            </div>
                        </div>
                        <div className="tablas_administracion">
                            <div className="tablas_item_modulos">
                                <div className="tabla_item">
                                    <div className="label-bottom-admin">
                                        <label className="lbl_item">Pacientes</label>
                                        <button id="px_btn_nuevo_admin" onClick={agregarPaciente}>
                                            <img src={img_person} alt="person" />
                                            Paciente
                                        </button>
                                    </div>
                                    <table id="tabla_pacientes">
                                        <tr><td>Pacientes activos:</td>
                                            <td>{contPxActivos}</td>
                                            <td><select id="px_select_admin" value={selectValuePxAct} onChange={handleSelectPaxActivo}>
                                                <option className="px_option" value="" disabled selected >Pacientes</option>
                                                {
                                                    pxAct.length !== 0 ? (
                                                        pxAct.map((pacActivos) => (
                                                            <option className="px_option" value={pacActivos.id_paciente}>{pacActivos.nombre} {pacActivos.ap1} {pacActivos.ap2}</option>
                                                        ))
                                                    ) : (<label>No existen pacientes activos.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                        <tr><td>Pacientes inactivos:</td>
                                            <td>{contPxInActivos}</td>
                                            <td><select id="px_select_admin" value={selectValuePxInAct} onChange={handleSelectPaxInActivo}>
                                                <option className="px_option" value="" disabled selected >Pacientes</option>
                                                {
                                                    pxInAct.length !== 0 ? (
                                                        pxInAct.map((pacInActivos) => (
                                                            <option className="px_option" value={pacInActivos.id_paciente}>{pacInActivos.nombre} {pacInActivos.ap1} {pacInActivos.ap2}</option>
                                                        ))
                                                    ) : (<label>No existen pacientes inactivos.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                        <tr><td>Pacientes canalizados:</td>
                                            <td>{contPxCan}</td>
                                            <td><select id="px_select_admin" value={selectValuePxCan} onChange={handleSelectPaxCan}>
                                                <option className="px_option" value="" disabled selected >Pacientes</option>
                                                {
                                                    pxCanalizadosTodos.length !== 0 ? (
                                                        pxCanalizadosTodos.map((pxCan) => (
                                                            <option className="px_option" value={pxCan.id_paciente}>{pxCan.p_nombre} {pxCan.p_ap1} {pxCan.p_ap2}</option>
                                                        ))
                                                    ) : (<label>No existen pacientes canalizados.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tabla_item">
                                    <div className="label-bottom-admin">
                                        <label className="lbl_item">Psicólogos</label>
                                        <button id="px_btn_nuevo_admin" onClick={agregarPsicologo}>
                                            <img src={img_person} alt="img_psi" />
                                            Psicólogo
                                        </button>
                                    </div>
                                    <table id="tabla_psicologos">
                                        <tr><td>Psicologos activos:</td>
                                            <td>{contPsicologos}</td>
                                            <td><select id="px_select_admin" value={selectValuePsi} onChange={handleSelectPsi}>
                                                <option className="px_option" value="" disabled selected >Psicólogos</option>
                                                {
                                                    psicologosTodos.length !== 0 ? (
                                                        psicologosTodos.map((psicTodos) => (
                                                            <option className="px_option" value={psicTodos.id_psicologo}>{psicTodos.nombre} {psicTodos.ap1} {psicTodos.ap2}</option>
                                                        ))
                                                    ) : (<label>No existen psicólogos registrados.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tabla_item">
                                    <div className="label-bottom-admin">
                                        <label className="lbl_item">Colegas</label>
                                        <button id="px_btn_nuevo_admin" onClick={agregarColega}>
                                            <img src={img_person} alt="img_colega" />
                                            Colega
                                        </button>
                                    </div>
                                    <table id="tabla_colegas">
                                        <tr><td>Colegas:</td>
                                            <td>{contColegas}</td>
                                            <td><select id="px_select_admin" value={selectValueCol} onChange={handleSelectCol}>
                                                <option className="px_option" value="" disabled selected >Colegas</option>
                                                {
                                                    colegasTodos.length !== 0 ? (
                                                        colegasTodos.map((colega) => (
                                                            <option className="px_option" value={colega.id_colega}>{colega.nombre} {colega.ap1} {colega.ap2}</option>
                                                        ))
                                                    ) : (<label>No existen colegas registrados.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tabla_item">
                                    <div className="label-bottom-admin">
                                        <label className="lbl_item">Familiares</label>
                                    </div>
                                    <table id="tabla_familiares">
                                        <tr><td>Familiares no pacientes:</td>
                                            <td>{contFNP}</td>
                                            <td><select id="px_select_admin" value={selectValueFam} onChange={handleSelectFam}>
                                                <option className="px_option" value="" disabled selected >Familiar no paciente</option>
                                                {
                                                    famNoEsPaciente.length !== 0 ? (
                                                        famNoEsPaciente.map((fnep) => (
                                                            <option className="px_option" value={fnep.id_familiar}>{fnep.f_nom} {fnep.f_ap1} {fnep.f_ap2}</option>
                                                        ))
                                                    ) : (<label>No existen familiares registrados.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                        <tr><td>Familiares que son pacientes:</td>
                                            <td>{contFEP}</td>
                                            <td><select id="px_select_admin" value={selectValueFamEP} onChange={handleSelectFamEP}>
                                                <option className="px_option" value="" disabled selected >Familiar es paciente</option>
                                                {
                                                    famEsPaciente.length !== 0 ? (
                                                        famEsPaciente.map((fep) => (
                                                            <option className="px_option" value={fep.id_paciente_familiar}>{fep.pf_nom} {fep.pf_ap1} {fep.pf_ap2}</option>
                                                        ))
                                                    ) : (<label>No existen familiares que son pacientes.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tabla_item">
                                    <div className="label-bottom-admin">
                                        <label className="lbl_item">Expedientes:</label>
                                    </div>
                                    <table id="tabla_informes">
                                        <td>Expediente:</td>
                                        <td>{contPxTodos}</td>
                                        <td><select id="px_select_admin" value={selectValueExp} onChange={handleSelectExpediente}>
                                            <option className="px_option" value="" disabled selected >Expedientes</option>
                                            {
                                                expedientesTodos.length !== 0 ? (
                                                    expedientesTodos.map((exp) => (
                                                        <option className="px_option" value={exp.id_expediente}>{exp.nombre} {exp.ap1} {exp.ap2}</option>
                                                    ))
                                                ) : (<label>No existen expedientes en el sistema.</label>)
                                            }
                                        </select></td>
                                    </table>
                                </div>
                                <div className="tabla_item">
                                    <div className="label-bottom-admin">
                                        <label className="lbl_item">Citas</label>
                                    </div>
                                    <table id="tabla_citas">
                                        <tr>
                                            <td>Cita:</td>
                                            <td><select id="px_select_admin" value={selectValueCita} onChange={handleSelectCitas}>
                                                <option className="px_option" value="" disabled selected >Pacientes</option>
                                                {
                                                    pacientesTodos.length !== 0 ? (
                                                        pacientesTodos.map((pacTodos) => (
                                                            <option className="px_option" value={pacTodos.id_paciente}>{pacTodos.nombre} {pacTodos.ap1} {pacTodos.ap2}</option>
                                                        ))
                                                    ) : (<label>No existen citas creadas en el sistema.</label>)
                                                }
                                            </select></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            {
                                selectValuePxAct !== "" || selectValuePxInAct !== "" ? (
                                    < div className="tabla_item_datos">
                                        {pxAct.length === 0 ? (
                                            <div className="div-sinInfo-admin">
                                                <div className="div_foto_perfil">
                                                    <div className="div-photo-border-sinDatos">
                                                        <img src={img_photo} alt="photo"></img>
                                                    </div>
                                                </div>
                                                <div className="px_tabla_admin">
                                                    <div id="edit_delete">
                                                        <div id="px_h3">
                                                            <h3>Paciente</h3>
                                                        </div>
                                                    </div>
                                                    <div className="div-selec-px">
                                                        <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="div-conInfo-admin">
                                                <div className="px_seleccionado_tabla_admin">
                                                    <div className="px_tabla_admin">
                                                        <div id="edit_delete">
                                                            <div id="px_h3_admin">
                                                                <label>Paciente </label>
                                                            </div>
                                                            <div id="e-d_label_admin">
                                                                <label><img src={img_edit} alt="edit" id="img_edit" onClick={() => editarPaciente(informacion.id_paciente)} /></label>
                                                                <label><img src={img_delete} alt="delete" id="img_delete" onClick={() => eliminarPaciente(informacion.id_paciente)} /></label>
                                                            </div>
                                                        </div>
                                                        <div className="div-avatarPx-admin">
                                                            <div className="div-avatarPx2-admin">
                                                                {
                                                                    foto === "1" ? (
                                                                        fotografia === "" ? (
                                                                            <div id="img_avatar_admin">
                                                                                <label> Cargando imagen...</label>
                                                                            </div>
                                                                        ) : (
                                                                            <div id="img_avatar_admin">
                                                                                <img src={fotografia} alt="base64"></img>
                                                                            </div>
                                                                        )
                                                                    ) : (<div></div>)
                                                                }
                                                            </div>
                                                            <div className="div-td-paciente-admin">
                                                                <div className="div-td-paciente2-admin">
                                                                    <label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_px_seleccionado_admin">
                                                            <table id="table_pxDatos_admin">
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.edad}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.sexo}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.escolaridad}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-profesion">Profesion:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.profesion}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-trabajo">Trabajo actual:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.trabajo_actual}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-pref_sex">Preferencia sexual:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.pref_sexual}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.edo_civil}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.tel}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.correo}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.domicilio}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.cod_pos}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.edo}</label></td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                    <td className="col2-admin"><label>{informacion.pais}</label></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    selectValuePxCan !== "" ? (
                                        < div className="tabla_item_datos">
                                            {pxAct.length === 0 ? (
                                                <div className="div-sinInfo-admin">
                                                    <div className="div_foto_perfil">
                                                        <div className="div-photo-border-sinDatos">
                                                            <img src={img_photo} alt="photo"></img>
                                                        </div>
                                                    </div>
                                                    <div className="px_tabla_admin">
                                                        <div id="edit_delete">
                                                            <div id="px_h3">
                                                                <h3>Paciente</h3>
                                                            </div>
                                                        </div>
                                                        <div className="div-selec-px">
                                                            <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="div-conInfo-admin">
                                                    <div className="px_seleccionado_tabla_admin">
                                                        <div className="px_tabla_admin">
                                                            <div id="edit_delete">
                                                                <div id="px_h3_admin">
                                                                    <label>Paciente canalizado</label>
                                                                </div>
                                                            </div>
                                                            <div className="div-avatarPx-admin">
                                                                <div className="div-avatarPx2-admin">
                                                                    {
                                                                        foto === "1" ? (
                                                                            fotografia === "" ? (
                                                                                <div id="img_avatar_admin">
                                                                                    <label> Cargando imagen...</label>
                                                                                </div>
                                                                            ) : (
                                                                                <div id="img_avatar_admin">
                                                                                    <img src={fotografia} alt="base64"></img>
                                                                                </div>
                                                                            )
                                                                        ) : (<div></div>)
                                                                    }
                                                                </div>
                                                                <div className="div-td-paciente-admin">
                                                                    <div className="div-td-paciente2-admin">
                                                                        <label id="lbl-paciente">{informacion.p_nombre} {informacion.p_ap1} {informacion.p_ap2}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div-datosCan-admin">
                                                                <div className="div-datosCan1-admin">
                                                                    <table id="table-datosCan1-admin">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td id="td-datosCan1-izq"><label>Fecha de inicio:</label></td>
                                                                                <td id="td-datosCan1-der">{fechaInCan}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td id="td-datosCan1-izq"><label>Fecha de finalización:</label></td>
                                                                                <td id="td-datosCan1-der">{fechaFinCan}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td id="td-datosCan1-izq" colSpan={2}><label>Motivo de la canalización:</label></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>{informacion.motivo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td id="td-datosCan1-izq"><label>Comentario del psicólogo que atiende:</label></td>
                                                                                <td id="td-datosCan1-der">{informacion.psi_nom} {informacion.psi_ap1} {informacion.psi_ap2}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>{informacion.comentario_psi}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td id="td-datosCan1-izq"><label>Comentario del colega que atiende:</label></td>
                                                                                <td id="td-datosCan1-der">{informacion.co_nombre} {informacion.co_ap1} {informacion.co_ap2}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>{informacion.comentario_psi}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        selectValuePsi !== "" ? (
                                            < div className="tabla_item_datos">
                                                {pxAct.length === 0 ? (
                                                    <div className="div-sinInfo-admin">
                                                        <div className="div_foto_perfil">
                                                            <div className="div-photo-border-sinDatos">
                                                                <img src={img_photo} alt="photo" />
                                                            </div>
                                                        </div>
                                                        <div className="px_tabla_admin">
                                                            <div id="edit_delete">
                                                                <div id="px_h3">
                                                                    <h3>Psicólogo</h3>
                                                                </div>
                                                            </div>
                                                            <div className="div-selec-px">
                                                                <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="div-conInfo-admin">
                                                        <div className="px_seleccionado_tabla_admin">
                                                            <div className="px_tabla_admin">
                                                                <div id="edit_delete">
                                                                    <div id="px_h3_admin">
                                                                        <label>Psicólogo</label>
                                                                    </div>
                                                                    <div id="e-d_label_admin">
                                                                        <label><img src={img_edit} alt="edit" id="img_edit" onClick={() => editarPsicologo(informacion.id_psicologo)} /></label>
                                                                        <label><img src={img_delete} alt="delete" id="img_delete" onClick={() => eliminarPsicologo(informacion.id_psicologo)} /></label>
                                                                    </div>
                                                                </div>
                                                                <div className="div-avatarPx-admin">
                                                                    <div className="div-avatarPx2-admin">
                                                                        {
                                                                            foto === "1" ? (
                                                                                fotografia === "" ? (
                                                                                    <div id="img_avatar_admin">
                                                                                        <label> Cargando imagen...</label>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div id="img_avatar_admin">
                                                                                        <img src={fotografia} alt="base64"></img>
                                                                                    </div>
                                                                                )
                                                                            ) : (<div></div>)
                                                                        }
                                                                    </div>
                                                                    <div className="div-td-paciente-admin">
                                                                        <div className="div-td-paciente2-admin">
                                                                            <label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_px_seleccionado_admin">
                                                                    <table id="table_pxDatos_admin">
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.edad}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.sexo}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.edo_civil}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.escolaridad}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-profesion">Cédula:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.cedula}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-trabajo">Especialidad:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.especialidad}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-pref_sex">Título:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.titulo}</label></td>
                                                                        </tr>

                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.tel}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.correo}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.domicilio}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.cod_pos}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.edo}</label></td>
                                                                        </tr>
                                                                        <tr className="padding-row">
                                                                            <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                            <td className="col2-admin"><label>{informacion.pais}</label></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            selectValueCol !== "" ? (
                                                < div className="tabla_item_datos">
                                                    {informacion.length === 0 ? (
                                                        <div className="div-sinInfo-admin">
                                                            <div className="px_tabla_admin">
                                                                <div className="div-selec-px">
                                                                    <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="div-conInfo-admin">
                                                            <div className="px_seleccionado_tabla_admin">
                                                                <div className="px_tabla_admin">
                                                                    <div id="edit_delete">
                                                                        <div id="px_h3_admin">
                                                                            <label>Paciente</label>
                                                                        </div>
                                                                        <div id="e-d_label_admin">
                                                                            <label><img src={img_edit} alt="edit" id="img_edit" onClick={() => editarColega(informacion.id_colega)} /></label>
                                                                            <label><img src={img_delete} alt="delete" id="img_delete" onClick={() => eliminarColega(informacion.id_colega)} /></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="div-avatarPx-admin">
                                                                        <div className="div-avatarPx2-admin">
                                                                            {
                                                                                foto === "1" ? (
                                                                                    fotografia === "" ? (
                                                                                        <div id="img_avatar_admin">
                                                                                            <label> Cargando imagen...</label>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div id="img_avatar_admin">
                                                                                            <img src={fotografia} alt="base64"></img>
                                                                                        </div>
                                                                                    )
                                                                                ) : (<div></div>)
                                                                            }
                                                                        </div>
                                                                        <div className="div-td-paciente-admin">
                                                                            <div className="div-td-paciente2-admin">
                                                                                <label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="div_px_seleccionado_admin">
                                                                        <table id="table_pxDatos_admin">
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.edad}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.sexo}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.edo_civil}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.escolaridad}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-profesion">Cédula:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.cedula}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-trabajo">Especialidad:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.especialidad}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-pref_sex">Título:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.titulo}</label></td>
                                                                            </tr>

                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.tel}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.correo}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.domicilio}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.cod_pos}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.edo}</label></td>
                                                                            </tr>
                                                                            <tr className="padding-row">
                                                                                <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                                <td className="col2-admin"><label>{informacion.pais}</label></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                selectValueFam !== "" ? (
                                                    < div className="tabla_item_datos">
                                                        {informacionFNP.length === 0 ? (
                                                            <div className="div-sinInfo-admin">
                                                                <div className="px_tabla_admin">
                                                                    <div className="div-selec-px">
                                                                        <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="div-conInfo-admin">
                                                                {
                                                                    informacionFNP.length > 1 ? (
                                                                        <div>
                                                                            <div id="edit_delete">
                                                                                <div id="px_h3_admin">
                                                                                    <label>Familiar no paciente</label>
                                                                                </div>
                                                                                <div id="e-d_label_admin">
                                                                                    <label><img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} /></label>
                                                                                    <label><img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} /></label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="div-avatarPx-admin">
                                                                                <div className="div-avatarPx2-admin">
                                                                                    {
                                                                                        foto === "1" ? (
                                                                                            fotografia === "" ? (
                                                                                                <div id="img_avatar_admin">
                                                                                                    <label> Cargando imagen...</label>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div id="img_avatar_admin">
                                                                                                    <img src={fotografia} alt="base64"></img>
                                                                                                </div>
                                                                                            )
                                                                                        ) : (<div></div>)
                                                                                    }
                                                                                </div>
                                                                                <div className="div-td-paciente-admin">
                                                                                    <div className="div-td-paciente2-admin">
                                                                                        <label id="lbl-paciente">{informacionFNP[0].f_nom} {informacionFNP[0].f_ap1} {informacionFNP[0].f_ap2}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <label>Parentescos: </label>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    informacionFNP.map((info) => (
                                                                                        <div className="px_seleccionado_tabla_admin">
                                                                                            <div className="px_tabla_admin">
                                                                                                <div>
                                                                                                    <div className="div-td-paciente2-admin">
                                                                                                        <label id="lbl-paciente">Es {info.parentesco} de {info.u_nom} {info.u_ap1} {info.u_ap2}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <label>Datos personales: </label>
                                                                            </div>
                                                                            <div className="div_px_seleccionado_admin">
                                                                                <table id="table_pxDatos_admin">
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_edad}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_sexo}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_edo_civil}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_tel}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_correo}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_domicilio}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_cod_pos}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_edo}</label></td>
                                                                                    </tr>
                                                                                    <tr className="padding-row">
                                                                                        <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                                        <td className="col2-admin"><label>{informacionFNP[0].f_pais}</label></td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        informacionFNP.map((info) => (
                                                                            <div className="px_seleccionado_tabla_admin">
                                                                                <div className="px_tabla_admin">
                                                                                    <div id="edit_delete">
                                                                                        <div id="px_h3_admin">
                                                                                            <label>Familiar</label>
                                                                                        </div>
                                                                                        <div id="e-d_label_admin">
                                                                                            <label><img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} /></label>
                                                                                            <label><img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} /></label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="div-avatarPx-admin">
                                                                                        <div className="div-avatarPx2-admin">
                                                                                            {
                                                                                                foto === "1" ? (
                                                                                                    fotografia === "" ? (
                                                                                                        <div id="img_avatar_admin">
                                                                                                            <label> Cargando imagen...</label>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div id="img_avatar_admin">
                                                                                                            <img src={fotografia} alt="base64"></img>
                                                                                                        </div>
                                                                                                    )
                                                                                                ) : (<div></div>)
                                                                                            }
                                                                                        </div>
                                                                                        <div className="div-td-paciente-admin">
                                                                                            <div className="div-td-paciente2-admin">
                                                                                                <label id="lbl-paciente">{info.f_nom} {info.f_ap1} {info.f_ap2}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="div-td-paciente2-admin">
                                                                                            <label id="lbl-paciente">Es {info.parentesco} de {info.u_nom} {info.u_ap1} {info.u_ap2}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="div_px_seleccionado_admin">
                                                                                        <table id="table_pxDatos_admin">
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_edad}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_sexo}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_edo_civil}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_tel}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_correo}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_domicilio}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_cod_pos}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_edo}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                                                <td className="col2-admin"><label>{info.f_pais}</label></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    )

                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    selectValueFamEP !== "" ? (
                                                        < div className="tabla_item_datos">
                                                            {informacionEP.length === 0 ? (
                                                                <div className="div-sinInfo-admin">
                                                                    <div className="px_tabla_admin">
                                                                        <div className="div-selec-px">
                                                                            <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="div-conInfo-admin">
                                                                    {
                                                                        informacionEP.length > 1 ? (
                                                                            <div>
                                                                                <div id="edit_delete">
                                                                                    <div id="px_h3_admin">
                                                                                        <label>Familiar paciente</label>
                                                                                    </div>
                                                                                    <div id="e-d_label_admin">
                                                                                        <label><img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} /></label>
                                                                                        <label><img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} /></label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="div-avatarPx-admin">
                                                                                    <div className="div-avatarPx2-admin">
                                                                                        {
                                                                                            foto === "1" ? (
                                                                                                fotografia === "" ? (
                                                                                                    <div id="img_avatar_admin">
                                                                                                        <label> Cargando imagen...</label>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div id="img_avatar_admin">
                                                                                                        <img src={fotografia} alt="base64"></img>
                                                                                                    </div>
                                                                                                )
                                                                                            ) : (<div></div>)
                                                                                        }
                                                                                    </div>
                                                                                    <div className="div-td-paciente-admin">
                                                                                        <div className="div-td-paciente2-admin">
                                                                                            <label id="lbl-paciente">{informacionEP[0].pf_nom} {informacionEP[0].pf_ap1} {informacionEP[0].pf_ap2}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <label>Parentescos: </label>
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        informacionEP.map((info, index) => (
                                                                                            <div className="px_seleccionado_tabla_admin" key={index}>
                                                                                                <div className="px_tabla_admin">
                                                                                                    <div>
                                                                                                        <div className="div-td-paciente2-admin">
                                                                                                            <label id="lbl-paciente">Es {info.parentesco} de {info.p_nom} {info.p_ap1} {info.p_ap2}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    <label>Datos personales: </label>
                                                                                </div>
                                                                                <div className="div_px_seleccionado_admin">
                                                                                    <table id="table_pxDatos_admin">
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_edad}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_sexo}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_edo_civil}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_tel}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_correo}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_domicilio}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_cod_pos}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_edo}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionEP[0].pf_pais}</label></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="px_seleccionado_tabla_admin">
                                                                                <div className="px_tabla_admin">
                                                                                    <div id="edit_delete">
                                                                                        <div id="px_h3_admin">
                                                                                            <label>Familiar</label>
                                                                                        </div>
                                                                                        <div id="e-d_label_admin">
                                                                                            <label><img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} /></label>
                                                                                            <label><img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} /></label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="div-avatarPx-admin">
                                                                                        <div className="div-avatarPx2-admin">
                                                                                            {
                                                                                                foto === "1" ? (
                                                                                                    fotografia === "" ? (
                                                                                                        <div id="img_avatar_admin">
                                                                                                            <label> Cargando imagen...</label>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div id="img_avatar_admin">
                                                                                                            <img src={fotografia} alt="base64"></img>
                                                                                                        </div>
                                                                                                    )
                                                                                                ) : (<div></div>)
                                                                                            }
                                                                                        </div>
                                                                                        <div className="div-td-paciente-admin">
                                                                                            <div className="div-td-paciente2-admin">
                                                                                                <label id="lbl-paciente">{informacionEP[0].pf_nom} {informacionEP[0].pf_ap1} {informacionEP[0].pf_ap2}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>Parentesco:</label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="div-td-paciente2-admin">
                                                                                            <label id="lbl-paciente">Es {informacionEP[0].parentesco} de {informacionEP[0].p_nom} {informacionEP[0].p_ap1} {informacionEP[0].p_ap2}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>Datos personales</label>
                                                                                    </div>
                                                                                    <div className="div_px_seleccionado_admin">
                                                                                        <table id="table_pxDatos_admin">
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-edad">Edad:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_edad}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-sexo">Sexo:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_sexo}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_edo_civil}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-tel">Teléfono:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_tel}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-correo">Correo:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_correo}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_domicilio}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-cp">Código Postal:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_cod_pos}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-edo">Estado:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_edo}</label></td>
                                                                                            </tr>
                                                                                            <tr className="padding-row">
                                                                                                <td className="col1-admin"><label htmlFor="lbl-pais">País:</label></td>
                                                                                                <td className="col2-admin"><label>{informacionEP[0].pf_pais}</label></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )

                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        selectValueExp !== "" ? (
                                                            < div className="tabla_item_datos">
                                                                {expedientesTodos.length === 0 ? (
                                                                    <div className="div-sinInfo-admin">
                                                                        <div className="px_tabla_admin">
                                                                            <div className="div-selec-px">
                                                                                <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="div-conInfo-admin">
                                                                        <div className="px_seleccionado_tabla_admin">
                                                                            <div className="px_tabla_admin">
                                                                                <div id="edit_delete">
                                                                                    <div id="px_h3_admin">
                                                                                        <label>Expediente</label>
                                                                                    </div>
                                                                                    <div id="e-d_label_admin">
                                                                                        <label><img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} /></label>
                                                                                        <label><img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} /></label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="div-avatarPx-admin">
                                                                                    <div className="div-avatarPx2-admin">
                                                                                        {
                                                                                            foto === "1" ? (
                                                                                                fotografia === "" ? (
                                                                                                    <div id="img_avatar_admin">
                                                                                                        <label> Cargando imagen...</label>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div id="img_avatar_admin">
                                                                                                        <img src={fotografia} alt="base64"></img>
                                                                                                    </div>
                                                                                                )
                                                                                            ) : (<div></div>)
                                                                                        }
                                                                                    </div>
                                                                                    <div className="div-td-paciente-admin">
                                                                                        <div className="div-td-paciente2-admin">
                                                                                            <label id="lbl-paciente">{informacionExpediente.nombre} {informacionExpediente.ap1} {informacionExpediente.ap2}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="div_px_seleccionado_admin">
                                                                                    <table id="table_pxDatos_admin">
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-edad">Pruebas:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionExpediente.pruebas}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-sexo">Sugerencias del psicólogo:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionExpediente.sugerencias}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-edo-civil">Historial médico:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionExpediente.hist_medico}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-escolaridad">Evolución de la historia personal:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionExpediente.exp_significativa}</label></td>
                                                                                        </tr>
                                                                                        <tr className="padding-row">
                                                                                            <td className="col1-admin"><label htmlFor="lbl-profesion">Diagnóstico:</label></td>
                                                                                            <td className="col2-admin"><label>{informacionExpediente.diagnostico}</label></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            selectValueCita !== "" ? (
                                                                < div className="tabla_item_datos">
                                                                    {informacionCita.length === 0 ? (
                                                                        <div className="div-sinInfo-admin">
                                                                            <div className="px_tabla_admin">
                                                                                <div className="div-selec-px">
                                                                                    <label id="label-selec-px-px"> El paciente no tiene citas registradas. </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="div-conInfo-admin">
                                                                            {
                                                                                <div className="px_seleccionado_tabla_admin">
                                                                                    <div id="edit_delete">
                                                                                        <div id="px_h3_admin">
                                                                                            <label>Citas del paciente</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="div-avatarPx-admin">
                                                                                        <div className="div-avatarPx2-admin">
                                                                                            {
                                                                                                foto === "1" ? (
                                                                                                    fotografia === "" ? (
                                                                                                        <div id="img_avatar_admin">
                                                                                                            <label> Cargando imagen...</label>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div id="img_avatar_admin">
                                                                                                            <img src={fotografia} alt="base64"></img>
                                                                                                        </div>
                                                                                                    )
                                                                                                ) : (<div></div>)
                                                                                            }
                                                                                        </div>
                                                                                        <div className="div-td-paciente-admin">
                                                                                            <div className="div-td-paciente2-admin">
                                                                                                <label id="lbl-paciente">{informacionCita[0].p_nombre} {informacionCita[0].p_ap1} {informacionCita[0].p_ap2}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        informacionCita.map((info, index) => (
                                                                                            <div className="px_seleccionado_tabla_admin">
                                                                                                <div className="px_tabla_admin">
                                                                                                    <div key={index}>
                                                                                                        <label>Cita {index + 1}</label>
                                                                                                    </div>
                                                                                                    <div className="div_px_seleccionado_admin">
                                                                                                        <table id="table_pxDatos_admin">
                                                                                                            <tr className="padding-row">
                                                                                                                <td className="col1-admin"><label htmlFor="lbl-edad">Fecha:</label></td>
                                                                                                                <td className="col2-admin"><label>{info.fecha}</label></td>
                                                                                                            </tr>
                                                                                                            <tr className="padding-row">
                                                                                                                <td className="col1-admin"><label htmlFor="lbl-sexo">Inicio:</label></td>
                                                                                                                <td className="col2-admin"><label>{info.hora_i}</label></td>
                                                                                                            </tr>
                                                                                                            <tr className="padding-row">
                                                                                                                <td className="col1-admin"><label htmlFor="lbl-edo-civil">Final:</label></td>
                                                                                                                <td className="col2-admin"><label>{info.hora_f}</label></td>
                                                                                                            </tr>
                                                                                                            <tr className="padding-row">
                                                                                                                <td className="col1-admin"><label htmlFor="lbl-tel">Estatus de la cita:</label></td>
                                                                                                                <td className="col2-admin"><label>{info.estatus_cita}</label></td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            }
                        </div>

                        <div className="px_buscador_admin">
                            <label className="lbl_item">Sesiones del paciente:</label>
                            <div className="input_buscar2_admin">
                                <select id="px_select_admin" value={selectValueSesiones} onChange={handleSelectSesiones}>
                                    <option className="px_option" value="" disabled selected >Pacientes</option>
                                    {
                                        sesionesTodas.map((sesionesTodas) => (
                                            <option className="px_option" value={sesionesTodas.id_paciente}>{sesionesTodas.p_nombre} {sesionesTodas.p_ap1} {sesionesTodas.p_ap2}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="px_div_3_admin">
                            {
                                informacionSesion.length !== 0 ? (
                                    <div className="px_div_4_admin">
                                        <div className="px_div_5_admin">
                                            <label>
                                                {informacionSesion[0].p_nombre} {informacionSesion[0].p_ap1} {informacionSesion[0].p_ap2}
                                            </label>
                                        </div>
                                        <div className="div-encabezado_px_admin">
                                            <table id="encabezado_px_admin">
                                                <tbody>
                                                    <tr className="tr-encabezado-px">
                                                        <th>Fecha</th>
                                                        <th>Inicio</th>
                                                        <th>Final</th>
                                                        <th>Estatus</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table id="celda_px_admin">
                                                {informacionSesion.map((info, index) => (
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {info.fecha}
                                                            </td>
                                                            <td>
                                                                {info.hora_i}
                                                            </td>
                                                            <td>
                                                                {info.hora_f}
                                                            </td>
                                                            <td>
                                                                {info.estatus_sesion}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <label key={index}>
                                                                    Sesion: {info.sesion}
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <label>Selecciona un paciente de la lista.</label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Administracion;