
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import FamiliaresService from "../../services/FamiliaresService";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Familiares.css";

import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_search from "../../img/search_pax.svg";
import img_person from "../../img/person_add.svg";
import img_clear from "../../img/clear.svg";
import ParentescoService from "../../services/ParentescoService";

const _ = require('lodash');



const Familiares = () => {
    const [pacientes, setPacientes] = useState([]);
    const [famNoEsPac, setFamNoPaciente] = useState([]);
    const [famEsPac, setFamEsPaciente] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");   // recibe cada letra en el buscador de pac
    const [inputValueFam, setInputValueFam] = useState("");   // recibe cada letra en el buscador de pac
    const [esPaciente, setEsPaciente] = useState("");   // evaluar si el Familiar tambien es paciente
    const [fotografia, setFotografia] = useState("");

    const [familiaresNPBuscador, setFamiliaresNPBuscador] = useState([]);
    const [familiarNEPxPsi, setFamiliarNEPxPsi] = useState([]);
    const [famNEPxPsi, setFamNEPxPsi] = useState("");

    const [familiaresEP, setFamiliaresEP] = useState([]);
    const [famNEPacPsicologo, setFamNEPacPsicologo] = useState([]);
    const [pacRelPac, setPacRelPac] = useState("");


    const [familiaresLista, setFamiliaresLista] = useState([]);
    const [parentescoLista, setParentescoLista] = useState([]);

    const [listaFamSinRel, setListaFamSinRel] = useState([]);   // obtiene lista fam agregando [key, value]: esIgual = 1 
    const [famListaLength, setFamListaLength] = useState("");   // validar lista vacía
    const [famEliminarSinParent, setFamEliminarSinParent] = useState("");   // nombre del fam sin rel a eliminar
    const [idFamElimSinParent, setIDFamElimSinParent] = useState("");   // ID del nombre del fam sin rel a eliminar


    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [datosFNP, setDatosFNP] = useState(0);     // bandera fam NO pac, hay o no datos del familiar
    const [datosFP, setDatosFP] = useState(0);     // bandera fam ES pac, hay o no datos del familiar
    const [idFamiliar, setIdFamiliar] = useState(null);      // recibe el id_familiar de la vista: vFamNoEsPac. Para eliminar al familiar
    const [idParent, setIdParentesco] = useState(null);     // recibe el id_parentesco de las vistas: vFamNoEsPac y vFamEsPac. Para eliminar el parentesco del familiar-paciente
    const [nombreEliminar, setNombreEliminar] = useState("");    // recibe los nombres: paciente-familiar y familiar de vFamNoEsPac y vFamEsPac
    const [displayFam, setDisplayFam] = useState("0");   // para el mensaje: Selecciona un paciente de la lista

    const [famNoEsPac_pac, setFamNoPaciente_pac] = useState([]);
    const [famEsPac_pac, setFamEsPaciente_pac] = useState([]);
    const [buscarFamNP, setBuscarFamNP] = useState("0");
    const [buscarFamEP, setBuscarFamEP] = useState("0");
    const [buscardorFam, setBuscardorFam] = useState("on");

    const [famSinParent, setFamSinParent_pac] = useState([]);
    const [buscarFamSinP, setBuscarFamSinP] = useState("0");

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);

    const MySwal = withReactContent(Swal);


    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }


    const navigate = useNavigate();

    const getData = async () => {
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);

        const data4 = await FamiliaresService.getFamiliares(usuario.id_psicologo);
        setFamiliaresLista(data4);

        const data5 = await ParentescoService.getParentescos();
        setParentescoLista(data5);

        const data6 = await FamiliaresService.getFamNoEsPacPsicologo(usuario.id_psicologo); // obtener fam por el psic de la sesion
        setFamNEPacPsicologo(data6);

        FamNEPacPsic();
        DataFamEsPac();

    };


    useEffect(() => {
        getData();
    }, []);


    const DisplayData = pacientes.map((info) => {
        return (
            <table id="filas_px-fam">
                <tbody>
                    <tr>
                        <td id="td-paxDelfam-fam">
                            <input type="radio" name="pacientes" id="radio_check_fam" onClick={() => Familiar_Paciente(info.id_paciente)} />
                            <label id="lbl_radio_fam" htmlFor="radio_check_fam">{info.nombre} {info.ap1} {info.ap2}</label>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    });


    // cargar datos familiares relcionados con un paciente   ---   idPaciente
    const Familiar_Paciente = async (idPaciente) => {
        const data = await FamiliaresService.getFamiliarNoEsPaciente(idPaciente);   //vFamNoEsPaciente
        setDatosFNP(data.length);   // 0: no hay datos,    >0: hay datos
        setFamNoPaciente(data);    //famNoEsPac
        setDisplayFam("1");

        const data2 = await FamiliaresService.getFamiliarEsPaciente(idPaciente);   //vFamEsPaciente
        setDatosFP(data2.length);   // 0: no hay datos,    >0: hay datos
        setFamEsPaciente(data2);    //famNoEsPac
        setDisplayFam("1");
    }


    // Display Familiar No es paciente
    const DisplayData2 = famNoEsPac.map((famN) => {
        return (
            <table>
                <tbody>
                    <tr id="filas_familiar">
                        <td>
                            <button type="button" className="btn btn-outline-secondary" onClick={() => cargarInformacionPorFamiliar(famN.id_familiar, famN.id_parentesco)}>
                                {famN.f_nom} {famN.f_ap1} {famN.f_ap2}
                            </button>
                        </td>
                        <td id="td_2_fam"> {famN.parentesco} </td>
                        <td id="td_3_fam"> No </td>
                        <td id="td_4_fam">
                            <button type="button" onClick={() => eliminarParentesco(famN.id_parentesco, famN.id_familiar)}>
                                <img src={img_delete} alt="delete" id="img_delete" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    })


    // cargar información del familiar
    const cargarInformacionPorFamiliar = async (id_familiar, id_parentesco) => {

        // DisplayData2: El familiar no es paciente

        setIdFamiliar(id_familiar);
        setIdParentesco(id_parentesco);

        const data = await FamiliaresService.getFamiliarIndividual(id_familiar);

        setNombreEliminar(data[0].nombre + " " + data[0].ap1 + " " + data[0].ap2);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setEsPaciente("no");
        setInformacion(data[0]);
    }


    // Display paciente_familiar: el familiar es paciente
    const DisplayData3 = famEsPac.map((famP) => {
        return (
            <tr id="filas_familiar">
                <td>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => cargarInfoPacFamiliar(famP.id_paciente_familiar, famP.id_parentesco)}>
                        {famP.pf_nom} {famP.pf_ap1} {famP.pf_ap2}
                    </button>
                </td>
                <td id="td_2_fam"> {famP.parentesco} </td>
                <td id="td_3_fam"> Sí </td>
                <td id="td_4_fam">
                    <button type="button" onClick={() => eliminarParentesco(famP.id_parentesco, famP.id_familiar)}>
                        <img src={img_delete} alt="delete" id="img_delete" />
                    </button>
                </td>
            </tr>
        )
    });

    // cargar información del paciente familiar
    const cargarInfoPacFamiliar = async (id_paciente, id_parentesco) => {

        setIdParentesco(id_parentesco);

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setNombreEliminar(data[0].nombre + " " + data[0].ap1 + " " + data[0].ap2);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setEsPaciente("si");
        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB

        console.log("cargarInfoPacFamiliar ------")
        console.log(data)

    };




    //**************** filtrar duplicado por Familiar ******************************** */


    async function FamNEPacPsic() {

        let arreObj1 = [];
        let arreObj2 = [];
        let arreObj3 = [];
        let objePac = {};
        let cont = 0;

        const famNEPxPsi = await FamiliaresService.getFamNoEsPacPsicologo(usuario.id_psicologo);

        for (let i = 0; i < famNEPxPsi.length; i++) {
            objePac = {
                id_fam: famNEPxPsi[i].id_familiar,
                nombre: famNEPxPsi[i].f_nom + " " + famNEPxPsi[i].f_ap1 + " " + famNEPxPsi[i].f_ap2,
                relacion: famNEPxPsi[i].relacion,
                id_psic: famNEPxPsi[i].id_psicologo
            }
            arreObj1.unshift(objePac);    // crear arr de objetos, unshift: agrega un elemento al comienzo del array
        }

        if (arreObj1.length > 0 || arreObj1.length !== undefined) {     // cuando el arreglo no está vacío

            setFamNEPxPsi("si");

            // eliminar obj duplicado
            arreObj2 = arreObj1;
            for (let i = 0; i < arreObj1.length; i++) {

                if (arreObj1[i].id_psic === parseInt(usuario.id_psicologo)) {     // validar PSICÓLOGO

                    for (let j = 0; j < arreObj2.length; j++) {

                        if (arreObj1[i].id_fam === arreObj2[j].id_fam) {

                            cont++;

                            if (cont > 1) {

                                arreObj3 = arreObj1[i];     // antes de borrar el elemento duplicado, hacer una copia
                                arreObj1.splice(i, 1);    // Empezando desde el índice i removió 1 elemento

                            }
                        }
                    }

                }
            }

            arreObj1.push(arreObj3);

            for (let i = 0; i < arreObj1.length; i++) {
                if ("id_fam" in arreObj1[i] === false) {
                    arreObj1.splice(i, 1)
                }
            }

            setFamiliarNEPxPsi(arreObj1);

        } else {
            setFamNEPxPsi("no");     // no existen pacientes relacionados con otros pacientes
        }

    }


    const DisplayDataFamNP = familiarNEPxPsi.map((info) => {
        return (
            <table id="filas_px-fam2">
                <tbody>
                    {
                        info.relacion > 0 ? (
                            <tr>
                                <td id="td-paxDelfam-fam2">
                                    <input type="radio" name="famNP" id="radio_check_fam" onClick={() => Paciente_FamiliarNP(info.id_fam)} />
                                    <label id="lbl_radio_fam" htmlFor="radio_check_fam">{info.nombre}</label>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )
                    }
                </tbody>
            </table>
        )
    });


    // cargar datos Familiar (id_fam) que tiene relación con uno o varios pacientes
    const Paciente_FamiliarNP = async (id_familiar) => {
        const data = await FamiliaresService.getFamNoEsPaciente(id_familiar);   //vFamNoEsPacienteLista
        setFamNoPaciente_pac(data);    //famNoEsPac
        setBuscarFamNP("1");
    }


    // por eliminación del elemento != de un arreglo
    function obtenerFamiliaresSinRelacion() {

        const famLista = familiaresLista;
        const parentLista = parentescoLista;

        for (let j = 0; j < famLista.length; j++) {
            for (let i = 0; i < parentLista.length; i++) {

                if (famLista[j].id_familiar !== undefined) {
                    if (famLista[j].id_familiar === parentLista[i].fk_familiar) {   // existe uno o varios parentescos
                        famLista[j].esIgual = 1;    // para marcar los fam que sí tienen relación
                    }
                }

            }
        }

        const arr = [];     // copiar lista Familiar

        if (famLista.length === 0) {

            setFamListaLength("0");     // validar si está vacío: <div> no existen fam sin relación </div>

        } else {

            for (let i = 0; i < famLista.length; i++) {
                arr.push(famLista[i]);      // copiando lista Familiar
            }

            for (let i = 0; i < arr.length; i++) {
                let claves = Object.keys(arr[i]);

                for (let j = 0; j < claves.length; j++) {
                    if (claves[j] === "esIgual") {      // elimina los fam marcados y deja los que no tienen parentesco (sin relación)
                        delete (arr[i]);    // elimina el contenido de la posición iterante, pero no elimina la posición
                    }
                }

            }

            const arr2 = [];     // No tienen parentesco

            for (let i = 0; i < arr.length; i++) {
                if (arr[i] !== undefined) {
                    arr2.push(arr[i]);      // se copia a nuevo arr2[0] para eliminar las posiciones del arreglo anterior: arr[2]
                }
            }

            setListaFamSinRel(arr2);
            setFamListaLength("1");

        }
    }


    const DisplayDataFamSinParent = listaFamSinRel.map((fam) => {

        return (
            <table id="filas_px-fam2">
                <tbody>
                    <tr>
                        <td id="td-paxDelfam-fam2">
                            <input type="radio" name="famSinP" id="radio_check_fam" onClick={() => FamiliarSinParentesco(fam.id_familiar)} />
                            <label id="lbl_radio_fam" htmlFor="radio_check_fam">{fam.nombre} {fam.ap1} {fam.ap2}</label>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    })



    const DataFamEsPac = async () => {

        let arrObj1 = [];
        let arrObj2 = [];
        let arrObj3 = [];
        let objPac = {};
        let cont = 0;

        const familiaresEP = await FamiliaresService.getFamiliarEsPacienteLista(usuario.id_psicologo);

        for (let i = 0; i < familiaresEP.length; i++) {
            objPac = {
                id_pac: familiaresEP[i].id_paciente,
                nombre: familiaresEP[i].p_nom + " " + familiaresEP[i].p_ap1 + " " + familiaresEP[i].p_ap2,
                id_psic: familiaresEP[i].id_psicologo,
                id_parent: familiaresEP[i].id_parentesco,
                id_pac_fam: familiaresEP[i].id_paciente_familiar
            }
            arrObj1.push(objPac);    // crear arr de objetos, push: agrega un elemento al final del array
        }

        if (arrObj1.length > 0 || arrObj1.length !== undefined) {     // cuando el arreglo No está vacío

            setPacRelPac("si");

            // eliminar obj duplicado
            arrObj2 = arrObj1;
            for (let i = 0; i < arrObj1.length; i++) {

                if (arrObj1[i].id_psic === parseInt(usuario.id_psicologo)) {     // validar PSICÓLOGO

                    for (let j = 0; j < arrObj2.length; j++) {

                        if (arrObj1[i].id_pac === arrObj2[j].id_pac) {

                            cont++;

                            if (cont > 1) {

                                arrObj3 = arrObj1[i];     // antes de borrar el elemento duplicado, hacer una copia
                                arrObj1.splice(i, 1);    // Empezando desde el índice i removió 1 elemento

                            }
                        }
                    }

                }
            }

            arrObj1.push(arrObj3);

            for (let i = 0; i < arrObj1.length; i++) {
                if ("id_pac" in arrObj1[i] === false) {
                    arrObj1.splice(i, 1)
                }
            }

            setFamiliaresEP(arrObj1);

        } else {
            setPacRelPac("no");     // no existen pacientes relacionados con otros pacientes
        }
    }


    const DisplayDataFamEP = familiaresEP.map((arr) => {
        return (
            <table id="filas_px-fam2">
                <tbody>
                    <tr>
                        <td id="td-paxDelfam-fam2">
                            <input type="radio" name="famEP" id="radio_check_fam" onClick={() => Paciente_FamiliarEP(arr.id_pac, usuario.id_psicologo)} />
                            <label id="lbl_radio_fam" htmlFor="radio_check_fam">{arr.nombre}</label>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    })


    // cargar datos Pacientes relcionados con un familiar  ---   idPaciente
    const Paciente_FamiliarEP = async (id_paciente, id_psicologo) => {
        const data = await FamiliaresService.getFamiliarEsPaciente_Pac(id_paciente, id_psicologo);   //vFamEsPaciente_Pac
        setFamEsPaciente_pac(data);    //famEsPac_Pac
        setBuscarFamEP("1");
    }


    // cargar datos Familiar sin parentesco
    const FamiliarSinParentesco = async (id_familiar) => {
        const data = await FamiliaresService.getFamiliarIndividual(id_familiar);
        const nomFam = data[0].nombre + " " + data[0].ap1 + " " + data[0].ap2;
        setFamEliminarSinParent(nomFam);
        setIDFamElimSinParent(data[0].id_familiar);
        setFamSinParent_pac(data);    //famSinParentesco
        setBuscarFamSinP("1");
    }


    // Display 2 Familiar No es paciente, Familiar relacionado con un paciente
    const DisplayData2PacNP = famNoEsPac_pac.map((pac) => {
        return (
            <div>
                {pac.length !== 0 ? (
                    <div className="div-nom-foto-parent-famNP">
                        <div className="lbl-nom-parent-famNP">
                            <label> <u>{pac.f_nom} {pac.f_ap1} {pac.f_ap2}</u> es <b>{pac.parentesco}</b> de:</label>
                        </div>
                        <div className="div-nom-foto-famNP">
                            {
                                (pac.u_fotografia.data).length !== 0 ? (
                                    <div className="div-img-foto-famNP">
                                        <img src={pac.u_fotografia.data} alt="img_pac"></img>
                                    </div>
                                ) : (<></>)
                            }
                            <div className="div-lbl-nombre-famNP">
                                <label>{pac.u_nom} {pac.u_ap1} {pac.u_ap2}</label>
                                <div className="div-table-datosfamNP">
                                    <table id="id-table-datosfamNP">
                                        <tbody>
                                            <tr id="tr-table-datosfamNP">
                                                <td id="td-table-datosfamNP">Edad:</td>
                                                <td>{pac.u_edad} años</td>
                                            </tr>
                                            <tr id="tr-table-datosfamNP">
                                                <td id="td-table-datosfamNP">Teléfono:</td>
                                                <td>{pac.u_tel}</td>
                                            </tr>
                                            <tr id="tr-table-datosfamNP">
                                                <td id="td-table-datosfamNP">Correo:</td>
                                                <td>{pac.u_correo}</td>
                                            </tr>
                                            <tr id="tr-table-datosfamNP">
                                                <td id="td-table-datosfamNP">Ciudad:</td>
                                                <td>{pac.u_ciudad}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    pac.length === 0 ? (
                        <div><label>No existen pacientes relacionados.</label></div>
                    ) : (<></>)
                )}
            </div>
        )
    })


    // Display 2 Familiar sin parentesco
    const DisplayFamSinParent = famSinParent.map((pac) => {
        return (
            <div>
                <div className="div-nom-foto-parent-famNP">
                    <div className="div-btn-parent-famNP">
                        <div id="div-btn1-parent-famNP">
                            <button id="btn-relConPac" onClick={() => relacionarFamiliar(pac.id_familiar)}>Relacionar con un paciente</button>
                        </div>
                        <div id="div-btn2-parent-famNP">
                            <button id="btn-relConPac" onClick={() => eliminarFamiliarSinRelacion(pac.id_familiar)}>Eliminar persona</button>
                        </div>
                    </div>
                    <div className="div-nom-foto-famNP">
                        {
                            (pac.fotografia.data).length !== 0 ? (
                                <div className="div-img-foto-famNP">
                                    <img src={pac.fotografia.data} alt="img_pac"></img>
                                </div>
                            ) : (<></>)
                        }
                        <div className="div-lbl-nombre-famNP">
                            <label>{pac.nombre} {pac.ap1} {pac.ap2}</label>
                            <div className="div-table-datosfamNP">
                                <table id="id-table-datosfamNP">
                                    <tbody>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Edad:</td>
                                            <td>{pac.edad}</td>
                                        </tr>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Teléfono:</td>
                                            <td>{pac.tel}</td>
                                        </tr>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Correo:</td>
                                            <td>{pac.correo}</td>
                                        </tr>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Ciudad:</td>
                                            <td>{pac.ciudad}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })


    // Display 2 Familiar ES paciente, Pac que tiene relación Familiar con otro paciente
    const DisplayData2PacEP = famEsPac_pac.map((pac) => {
        return (
            <div>
                <div className="div-nom-foto-parent-famNP">
                    <div className="lbl-nom-parent-famNP">
                        <label> <u>{pac.p_nom} {pac.p_ap1} {pac.p_ap2}</u> es <b>{pac.parentesco}</b> de:</label>
                    </div>
                    <div className="div-nom-foto-famNP">
                        {
                            (pac.p_fotografia.data).length !== 0 ? (
                                <div className="div-img-foto-famNP">
                                    <img src={pac.p_fotografia.data} alt="img_pac"></img>
                                </div>
                            ) : (<></>)
                        }
                        <div className="div-lbl-nombre-famNP">
                            <label>{pac.pf_nom} {pac.pf_ap1} {pac.pf_ap2}</label>
                            <div className="div-table-datosfamNP">
                                <table id="id-table-datosfamNP">
                                    <tbody>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Edad:</td>
                                            <td>{pac.pf_edad}</td>
                                        </tr>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Teléfono:</td>
                                            <td>{pac.pf_tel}</td>
                                        </tr>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Correo:</td>
                                            <td>{pac.pf_correo}</td>
                                        </tr>
                                        <tr id="tr-table-datosfamNP">
                                            <td id="td-table-datosfamNP">Ciudad:</td>
                                            <td>{pac.pf_ciudad}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })


    /************************************************************/

    /********* eliminar Parentesco **********************/

    const eliminarParentesco = async (id_parent, id_familiar) => {

        const data = await FamiliaresService.getFamiliarIndividual(id_familiar);
        console.log("getFamiliarIndividual ------")
        console.log(data)

        Swal.fire({
            title: "Eliminar parentesco",
            text: "¿Estás seguro de eliminar el parentesco?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                // primero, restar una relacion a relacion tabla familiar
                console.log("rel_pac: ", data[0].relacion)

                const rel_pac = parseInt(data[0].relacion) - 1;
                console.log("rel_pac - 1: ", rel_pac)

                const response = await FamiliaresService.EditarFamiliarNoRelacion(rel_pac, id_familiar);

                const respuesta = await ParentescoService.deleteParentesco(id_parent);

                if (respuesta.status === 200 && response.status === 200) {
                    navigate("/familiares");
                } else {
                    mostrarError("Error 200 al eliminar parentesco.");
                }
            }
        });
    }


    /********* editar Parentesco y campo relacion de tabla familiar **********/

    const relacionarFamiliar = async (id_familiar) => {

        const data = await FamiliaresService.getFamiliarIndividual(id_familiar);
        const data2 = await PacientesService.getPacientes(usuario.id_psicologo);

        MySwal.fire({
            title: 'Relacionar familiar',
            html: (
                <div className="datos-paciente-modal">
                    <div className="div-select-paciente-modal">
                        <select id="select-pac-fam-fam" defaultValue="" required>
                            <option value="" disabled>Selecciona un paciente</option>
                            {data2.map((pac) => (
                                <option value={pac.id_paciente}>{pac.nombre} {pac.ap1} {pac.ap2}</option>
                            ))}
                        </select>
                    </div>
                    <div className="div-select-fam-modal">
                        <select id="select-parentescoDelFam-fam" defaultValue="elige" required>
                            <option value="elige" disabled>Selecciona un parentesco</option>
                            <option value="padre">Padre</option>
                            <option value="madre">Madre</option>
                            <option value="hijo">Hijo</option>
                            <option value="hermano">Hermano</option>
                            <option value="tio">Tío</option>
                            <option value="sobrino">Sobrino</option>
                            <option value="abuelo">Abuelo</option>
                            <option value="primo">Primo</option>
                            <option value="esposo">Esposo</option>
                            <option value="esposa">Esposa</option>
                            <option value="padrastro">Padrastro</option>
                            <option value="madrastra">Madrastra</option>
                            <option value="suegro">Suegro</option>
                            <option value="cunado">Cuñado</option>
                            <option value="consuegro">Consuegro</option>
                            <option value="concuno">Concuño</option>
                            <hr></hr>
                            <option value="amigo">Amigo</option>
                            <option value="vecino">Vecino</option>
                            <option value="conocido">Persona conocida</option>
                        </select>
                    </div>
                    <div className="datos-foto-modal">
                        <div id="img-agendado">
                            {
                                (data[0].fotografia.data).length !== 0 ? (
                                    <img src={data[0].fotografia.data} alt="base64"></img>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                        <div id="lbl-a-agendado">
                            <label id="lbl-px-agendado">{data[0].nombre} {data[0].ap1} {data[0].ap2}</label>
                        </div>
                    </div>
                    <div className="modal-pax-agendado">
                        <table className="table-modal-pax-agendado">
                            <tbody>
                                <tr className="padding-row">
                                    <td><label className="lbl-edad-agendado" htmlFor="lbl-edad-agendado">Edad:</label></td>
                                    <td><label id="lbl-edad-agendado">{data[0].edad} años</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-telefono-agendado" htmlFor="lbl-telefono-agendado">Telefono:</label></td>
                                    <td><label id="lbl-telefono-agendado">{data[0].tel}</label></td>
                                </tr>
                                <tr className="padding-row">
                                    <td><label className="lbl-ciudad-agendado" htmlFor="lbl-ciudad-agendado">Ciudad:</label></td>
                                    <td><label id="lbl-ciudad-agendado">{data[0].ciudad}</label></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ),
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            preConfirm: () => {
                const parentesco = document.getElementById("select-parentescoDelFam-fam").value;
                const id_paciente = document.getElementById("select-pac-fam-fam").value;
                const id_familiar = data[0].id_familiar;
                const nomFam = data[0].nombre + data[0].ap1 + data[0].ap2
                return { parentesco, id_paciente, id_familiar, nomFam }
            },

        }).then((result) => {
            if (result.isConfirmed) {
                const info = result.value;
                editarParentesco(info);
            }
        });
    }


    const editarParentesco = async (info) => {

        const famNP = await FamiliaresService.getFamiliarNoEsPaciente(parseInt(info.id_paciente));

        if (famNP.length !== 0) {   // diferente a 0, existen familiares
            let cont = 0;

            for (let i = 0; i < famNP.length; i++) {
                if (famNP[i].parentesco === "padre" ||     // parent que no se pueden repetir
                    famNP[i].parentesco === "madre" ||
                    famNP[i].parentesco === "esposo" ||
                    famNP[i].parentesco === "esposa" ||
                    famNP[i].parentesco === "padrastro" ||
                    famNP[i].parentesco === "madrastra" ||
                    famNP[i].parentesco === "suegro") {

                    if (famNP[i].parentesco === info.parentesco) {
                        cont++;
                    }
                }
            }

            if (cont > 0) {

                mostrarError(`Ya existe un registro con el parentesco: ${info.parentesco}`);

            } else {

                let cont = 0;

                let nomFamiliar = info.nomFam;   // nombre select: Seleccionar el paciente que es familiar

                // validar para Advertencia, se repite: nombre ap1 ap2
                for (let i = 0; i < famNP.length; i++) {

                    let nomF = famNP[i].pf_nom + famNP[i].pf_ap1 + famNP[i].pf_ap2;

                    if (nomFamiliar === nomF) {
                        cont++;
                    }
                }

                if (cont > 0) {

                    Swal.fire({
                        title: '¡Advertencia!',
                        text: `Ya existe un registro con el nombre: ${nomFamiliar} ¿Deseas continuar?`,
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                        showCancelButton: true
                    }).then(async (result) => {

                        if (result.isConfirmed) {

                            // completar objeto parentesco
                            const objParent = {
                                parentesco: info.parentesco,
                                fk_familiar: parseInt(info.id_familiar),
                                fk_paciente: parseInt(info.id_paciente),
                                fk_paciente_familiar: null
                            }

                            if (info.parentesco !== "" &&
                                info.id_paciente !== "" &&
                                info.id_familiar !== "") {

                                // sumar 1 a campo relacion de tabla familiar

                                const rel_pac = 1;
                                console.log("111111 rel_pac: ", rel_pac)

                                const respuesta = await FamiliaresService.EditarFamiliarNoRelacion(rel_pac, info.id_familiar);

                                const response = await ParentescoService.createParentesco(objParent);   // crear parentesco

                                if (response.status === 200 && respuesta.status === 200) {

                                    Toast.fire("El parentesco se registró correctamente.", "", "success")
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                navigate('/familiares');
                                                refreshPage();
                                            }
                                        }).then(function () {
                                            navigate('/familiares');
                                            refreshPage();
                                        });

                                } else {
                                    mostrarError("Error al crear el parentesco.");
                                }

                            } else {
                                mostrarError("Error en los datos del parentesco.");
                            }
                        }
                    });

                } else {

                    // crear objeto parentesco
                    const objParent = {
                        parentesco: info.parentesco,
                        fk_familiar: parseInt(info.id_familiar),
                        fk_paciente: parseInt(info.id_paciente),
                        fk_paciente_familiar: null
                    }

                    if (info.parentesco !== "" &&
                        info.id_paciente !== "" &&
                        info.id_familiar !== "") {

                        // sumar 1 a campo relacion de tabla familiar
                        const rel_pac = 1;
                        console.log("222222 rel_pac: ", rel_pac)

                        const respuesta = await FamiliaresService.EditarFamiliarNoRelacion(rel_pac, info.id_familiar);

                        const response = await ParentescoService.createParentesco(objParent);   // crear parentesco

                        if (response.status === 200 && respuesta.status === 200) {

                            Toast.fire("El parentesco se registró correctamente.", "", "success")
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        navigate('/familiares');
                                        refreshPage();
                                    }
                                }).then(function () {
                                    navigate('/familiares');
                                    refreshPage();
                                });

                        } else {
                            mostrarError("Error al crear el parentesco.");
                        }
                    }
                }
            }

        } else {    // no tiene parentesco registrado, ni familiar relacionado

            // crear objeto parentesco
            const objParent = {
                parentesco: info.parentesco,
                fk_familiar: parseInt(info.id_familiar),
                fk_paciente: parseInt(info.id_paciente),
                fk_paciente_familiar: null
            }

            if (info.parentesco !== "" &&
                info.id_paciente !== "" &&
                info.id_familiar !== "") {

                // sumar 1 a campo relacion de tabla familiar
                const rel_pac = 1;
                console.log("333333 rel_pac: ", rel_pac)

                const respuesta = await FamiliaresService.EditarFamiliarNoRelacion(rel_pac, info.id_familiar);

                const response = await ParentescoService.createParentesco(objParent);   // crear parentesco

                if (response.status === 200 && respuesta.status === 200) {

                    Toast.fire("El parentesco se registró correctamente.", "", "success")
                        .then((result) => {
                            if (result.isConfirmed) {
                                navigate('/familiares');
                                refreshPage();
                            }
                        }).then(function () {
                            navigate('/familiares');
                            refreshPage();
                        });

                } else {
                    mostrarError("Error al crear el parentesco.");
                }
            }
        }
    }

    /*********************************************************** */

    const eliminarFamiliar = async () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: `Estás seguro de eliminar al familiar: ${nombreEliminar} ¿Deseas continuar?`,
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            showCancelButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                // primero, eliminar el parentesco
                const elimParent = await ParentescoService.DeleteParentesco(idParent);
                if (elimParent.status === 200) {

                    if (esPaciente === "no") {

                        // segundo, eliminar familiar
                        const elimFam = await FamiliaresService.DeleteFamiliar(idFamiliar);
                        if (elimFam.status === 200) {
                            Swal.fire({
                                title: "Familiar eliminado",
                                text: "El familiar se eliminó correctamente.",
                                showConfirmButton: true,
                                confirmButtonText: "Aceptar",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    refreshPage();
                                }
                            });
                        }
                    } else {    // si sí es paciente...
                        Swal.fire({
                            title: "Parentesco eliminado",
                            text: "El parentesco asociado al familiar, se eliminó correctamente.",
                            showConfirmButton: true,
                            confirmButtonText: "Aceptar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        });
                    }
                } else {
                    Toast.fire("Hubo un problema al eliminar el paciente.", "", "error");
                }
            }
        });
    }


    const eliminarFamiliarSinRelacion = async () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: `Estás seguro de eliminar al familiar sin parentesco: ${famEliminarSinParent} \n ¿Deseas continuar?`,
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            showCancelButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const elimFam = await FamiliaresService.DeleteFamiliar(idFamElimSinParent);
                if (elimFam.status === 200) {
                    Swal.fire({
                        title: "Familiar eliminado",
                        text: "El familiar sin parentesco se eliminó correctamente.",
                        showConfirmButton: true,
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    });
                }
            }
        });
    }


    const editarFamiliar = async () => {
        if (esPaciente === 'no') {
            // local
            // navigate(`/editfamiliar/${informacion.id_familiar}`);

            // web
            navigate(`/editfamiliar/${informacion.id_familiar}`);

        } else if (esPaciente === 'si') {
            Swal.fire({
                title: "Editar paciente",
                text: "Estás tratando de editar un paciente...",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    // navigate(`/editpaciente/${informacion.id_paciente_familiar}`);  // local
                    navigate(`/editpaciente/${informacion.id_paciente_familiar}`);     // web
                }
            });
        }
    }

    const agregarFamiliar = () => {
        // navigate('/addfamiliar');   //local
        navigate('/addfamiliar');   //web
    }

    const handleBuscarFam = (event) => {
        if (event.target.value === "si") {
            obtenerFamiliaresSinRelacion();
            setBuscardorFam(event.target.value);
        } else if (event.target.value === "on") {
            setBuscardorFam(event.target.value);
        }
    }

    // Buscador de pacientes
    const buscarPaciente = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value, usuario.id_psicologo);
        setPacientes(data);
    }

    // Buscador de familiares
    // const buscarFamiliar = async (event) => {
    //     setInputValueFam(event.target.value);
    //     const data = await FamiliaresService.getFamiliarNoEsPacienteBuscador(event.target.value);
    //     setFamiliaresNPBuscador(data);
    // }

    // Buscador de pacientes
    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value, usuario.id_psicologo);
            setPacientes(data);
        }
    }

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="px_div_1_familiares">
                    <div className="px_div_2_fam">
                        <div className="px_sesion_fam">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                    </div>
                    <div className="div-Fam-Buscar-fam">
                        <div className="div_lbl_fam">
                            <label id="lbl_familiares">Familiares</label>
                        </div>
                    </div>
                    <div className="div_filtro_selec_px_fam">
                        <div className="input_buscar1_fam">
                            <div className="input_buscar2_fam">
                                <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscarPaciente} />
                                <img src={img_search} alt="buscar" id="img_lupa"></img>
                            </div>
                        </div>
                        <div className="px_filtro_fam">
                            <select id="px_select_fam" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled >Selecciona un filtro</option>
                                <option value="A">Activos</option>
                                <option value="I">Inactivos</option>
                            </select>
                        </div>
                        <div className="div-botones-cls-newPax">
                            <div id="div_btn-cls_fam">
                                <button id="btn-limpiar-filtro-fam" onClick={limpiarFiltro}>
                                    <img src={img_clear} alt="img_clear" />
                                    Limpiar
                                </button>
                            </div>
                            <div id="div_nuevoFam_fam" >
                                <button id="btn-limpiar-filtro-fam" onClick={agregarFamiliar}>
                                    <img src={img_person} alt="img_person" />
                                    Familiar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="div-buscarPorPax">
                        <div className="px_div_3_fam">
                            <div id="px_Pac_fam">
                                <label>Pacientes</label>
                            </div>
                            <div className="div_tablas_th_fam">
                                <table id="encabezado_familiar">
                                    <tbody>
                                        <tr id="tr-encabezado-fam-1">
                                            <th>Familiares del paciente</th>
                                            <th>Parentesco</th>
                                            <th>Es paciente</th>
                                            <th>Eliminar</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div id="edit_delete_fam">
                                {
                                    informacion.length === 0 ? (
                                        <div id="px_h3_fam">
                                            <label>Familiar</label>
                                        </div>
                                    ) : (
                                        <div id="edit_delete2_fam">
                                            <div id="px_h3_fam">
                                                <label>Familiar</label>
                                            </div>
                                            <div className="div-px-toolTip">
                                                <div id="container-toolTip-editar-px">
                                                    <span className="lbl-toolTip-editar-px">
                                                        Editar
                                                    </span>
                                                    <img src={img_edit} alt="edit" id="img_edit" onClick={editarFamiliar} />
                                                </div>
                                                <div id="container-toolTip-delete-px">
                                                    <span className="lbl-toolTip-delete-px">
                                                        Eliminar
                                                    </span>
                                                    <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarFamiliar} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="div-display-tabla-fam">
                            <div className="div_tablas_th_px-fam">
                                {DisplayData}
                            </div>
                            <div className="div-table-display2">
                                {
                                    displayFam === "0" ? (
                                        <div className="div-selec-px">
                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista. </label>
                                        </div>
                                    ) : displayFam === "1" ? (
                                        datosFNP > 0 || datosFP > 0 ? (
                                            <div id="div-display1y2-fam">
                                                <div id="div-display1-fam">
                                                    {DisplayData2}
                                                </div>
                                                <div id="div-display2-fam">
                                                    {DisplayData3}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="div-selec-px">
                                                <label id="label-selec-px-px"> No existen familiares relacionados. </label>
                                            </div>
                                        )
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>

                            <div className="px_tabla_fam">
                                {informacion.length === 0 ? (
                                    <div>
                                        <div className="div-selec-px">
                                            <label id="label-selec-px-px"> Selecciona un familiar de la lista. </label>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="div-avatar-px">
                                            {
                                                foto === "1" ? (
                                                    fotografia === "" ? (
                                                        <div id="img_avatar_px">
                                                            <label> Cargando imagen...</label>
                                                        </div>
                                                    ) : (
                                                        <div id="img_avatar_px">
                                                            <img src={fotografia} alt="base64"></img>
                                                        </div>
                                                    )
                                                ) : (<div></div>)
                                            }
                                        </div>
                                        <div>
                                            <table id="table_px_seleccionado_fam">
                                                <tbody>
                                                    <tr>
                                                        <td id="td-paciente-fam" colSpan="2">
                                                            <center>
                                                                <label id="lbl-paciente-fam">
                                                                    {informacion.nombre} {informacion.ap1} {informacion.ap2}
                                                                </label>
                                                            </center>
                                                        </td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                        <td><label id="lbl-edad">{informacion.edad}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                        <td><label id="lbl-sexo">{informacion.sexo}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                        <td><label id="lbl-edo-civil">{informacion.edo_civil}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                        <td><label id="lbl-tel">{informacion.tel}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                        <td><label id="lbl-correo">{informacion.correo}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                        <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                        <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                        <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                        <td><label id="lbl-edo">{informacion.edo}</label></td>
                                                    </tr>
                                                    <tr className="tr-bottom-pac-fam">
                                                        <td id="td-padding-fam"><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                        <td><label id="lbl-pais">{informacion.pais}</label></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="div-buscarPorFam-2">
                        <div className="lbl-buscarPorFam-2">
                            <label> Realizar búsqueda por familiar </label>
                        </div>
                        <div className="lbl-buscarPorFam-3">
                            <div id="id-radio1-buscarFam">
                                <label>No</label>
                                <input type="radio" name="buscarFam" id="radio-buscarFam" onChange={handleBuscarFam} defaultChecked></input>
                            </div>
                            <div id="id-radio2-buscarFam">
                                <label>Sí</label>
                                <input type="radio" name="buscarFam" id="radio-buscarFam" onChange={handleBuscarFam} value="si"></input>
                            </div>
                        </div>
                    </div>

                    <div className="div-buscarFam-2">
                        {
                            buscardorFam === "si" ? (
                                <div>
                                    <div className="px_div_3_fam2">
                                        <div className="div_tablas_th_Pac">
                                            <table id="encabezado_familiar">
                                                <tbody>
                                                    <tr id="tr-encabezado-fam-1">
                                                        <th>Familiares</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="div_tablas_Pac-rel">
                                            <label>Paciente relacionado</label>
                                        </div>
                                    </div>
                                    <div className="div-display-tabla-fam">
                                        <div className="div_tablas_th_PAC-fam">
                                            {
                                                famNEPxPsi === "si" ? (
                                                    <div>
                                                        {DisplayDataFamNP}
                                                    </div>

                                                ) : (
                                                    famNEPxPsi === "no" ? (
                                                        <label>No existen familiares en el sistema.</label>
                                                    ) : (<></>)
                                                )
                                            }
                                        </div>
                                        <div className="div-table-display2-PAC">
                                            {
                                                buscarFamNP === "0" ? (
                                                    <div className="div-selec-px">
                                                        <label id="label-selec-px-px"> Selecciona un paciente de la lista. </label>
                                                    </div>
                                                ) : (
                                                    buscarFamNP === "1" ? (
                                                        <div id="div-display1-fam2">
                                                            {DisplayData2PacNP}
                                                        </div>
                                                    ) : (<></>)
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="div_5_fam2">
                                        <div className="px_div_5_fam2">
                                            <div className="div_tablas_th_Pac4">
                                                <table id="encabezado_familiar">
                                                    <tbody>
                                                        <tr id="tr-encabezado-fam-1">
                                                            <th>Familiares sin parentesco</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="div_tablas_Pac-rel4">
                                                <label>Información</label>
                                            </div>
                                        </div>
                                        <div className="div-display-tabla-fam">
                                            <div className="div_tablas_th_PAC-fam4">
                                                {
                                                    famListaLength === "0" ? (
                                                        <label> No existen familiares sin relación </label>
                                                    ) : (
                                                        famListaLength === "1" ? (
                                                            <div id="div-display1-fam4">
                                                                {DisplayDataFamSinParent}
                                                            </div>
                                                        ) : (<></>)
                                                    )
                                                }
                                            </div>
                                            <div className="div-table-display2-PAC">
                                                {
                                                    buscarFamSinP === "0" ? (
                                                        <div className="div-selec-px">
                                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista. </label>
                                                        </div>
                                                    ) : (
                                                        buscarFamSinP === "1" ? (
                                                            <div id="div-display2-fam4">
                                                                {DisplayFamSinParent}
                                                            </div>
                                                        ) : (<></>)
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="div_4_fam2">
                                        <div className="px_div_4_fam2">
                                            <div className="div_tablas_th_Pac4">
                                                <table id="encabezado_familiar">
                                                    <tbody>
                                                        <tr id="tr-encabezado-fam-2">
                                                            <th>Paciente que es familiar de otro paciente</th>
                                                            <th>Parentesco</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="div_tablas_Pac-rel4">
                                                <label>Paciente relacionado</label>
                                            </div>
                                        </div>
                                        <div className="div-display-tabla-fam">
                                            <div className="div_tablas_th_PAC-fam4">
                                                {
                                                    pacRelPac === "si" ? (
                                                        <div>
                                                            {DisplayDataFamEP}
                                                        </div>
                                                    ) : (
                                                        pacRelPac === "no" ? (
                                                            <label>No existen pacientes relacionados con otros pacientes.</label>
                                                        ) : (<></>)
                                                    )
                                                }
                                            </div>
                                            <div className="div-table-display2-PAC">
                                                {
                                                    buscarFamEP === "0" ? (
                                                        <div className="div-selec-px">
                                                            <label id="label-selec-px-px"> Selecciona un paciente de la lista. </label>
                                                        </div>
                                                    ) : (
                                                        buscarFamEP === "1" ? (
                                                            <div id="div-display1-fam4">
                                                                {DisplayData2PacEP}
                                                            </div>
                                                        ) : (<></>)
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Familiares;
