import axios from "axios";

const SESIONES_API_BASE_URL = "/server/sesiones";
const VSESIONES_API_BASE_URL = "/server/vsesiones";

// const SESIONES_API_BASE_URL = "http://localhost:3002/sesiones";
// const VSESIONES_API_BASE_URL = "http://localhost:3002/vsesiones";


class SesionesService {

    crearSesion(sesion) {

        return axios.post(SESIONES_API_BASE_URL, sesion);
    }

    
    guardarSesion(sesion, estatus_sesion, id_sesion) {

        return axios.put(`${SESIONES_API_BASE_URL}?sesion=${sesion}&estatus_sesion=${estatus_sesion}&id_sesion=${parseInt(id_sesion)}`);
    }


    eliminarSesion(idCita) {
        
        return axios.delete(`${SESIONES_API_BASE_URL}?id_cita=${idCita}`);
    }


    async getSesionesHistorial(id_paciente) {
        const response = await axios.get(`${VSESIONES_API_BASE_URL}Historial?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getSesionesHistorial ', error);
            });
        const sesiones = response.data;
        return sesiones;
    }


    async getSesionesTodas() {
        const response = await axios.get(`${VSESIONES_API_BASE_URL}Todas?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getSesionesTodas ', error);
            });
        const sesiones = response.data;
        return sesiones;
    }

}

export default new SesionesService();