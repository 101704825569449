
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../services/AuthContext';

import Swal from "sweetalert2";

import LayoutLogin from "../../containers/LayoutLogin";
import ContentLogin from "../../components/ContentLogin";

import UsuariosServices from "../../services/UsuariosService";
import PacientesServices from "../../services/PacientesService";
import PsicologosServices from "../../services/PsicologoService";

import "../../styles/Login.css";


const Login = () => {

  const [correo, setCorreo] = useState("");
  const [contrasena, setContrasena] = useState("");

  const navigate = useNavigate();
  const { login } = useAuth();

  function mostrarError(mensaje) {
    Swal.fire({
      title: '¡Error!',
      text: mensaje,
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }

  const handleCorreo = (event) => {
    setCorreo(event.target.value);   // setCorreo recibe el valor del campo correo y la guarda en correo
  };

  const handleContrasena = (event) => {
    setContrasena(event.target.value);
  };

  const handleSubmit = async () => {

    const data = await UsuariosServices.getLogin(correo, contrasena);

    if (data[0] === undefined) {

      mostrarError("Correo y/o contrasena incorrecto");

    } else if (data[0].rol === 'psi') {
      const data2 = await PsicologosServices.getPsicologo(data[0].id_usuario);
      const infoUsuario = {
        id_psicologo: data2[0].id_psicologo,
        nombre: data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2,
        rol: data[0].rol
      };

      const usuarioString = JSON.stringify(infoUsuario);
      sessionStorage.setItem('usuario', usuarioString);
      login();
      navigate('/calendario');

    } else if (data[0].rol === 'pax') {
      const data3 = await PacientesServices.getPaciente(data[0].id_usuario);
      const infoUsuario = {
        id_paciente: data3[0].id_paciente,
        nombre: data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2,
        rol: data[0].rol,
        psicologo: data3[0].fk_psicologo
      };

      const usuarioString = JSON.stringify(infoUsuario);
      sessionStorage.setItem('usuario', usuarioString);
      login();
      navigate('/calendario');

    } else if (data[0].rol === 'adm') {
      const infoUsuario = {
        id_usuario: data[0].id_usuario,
        nombre: data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2,
        rol: data[0].rol
      }

      const usuarioString = JSON.stringify(infoUsuario);
      sessionStorage.setItem('usuario', usuarioString);
      login();
      navigate('/administracion');
    }
  };

  

  const Cancelar = () => {
    navigate("/");
  };


  return (
    <LayoutLogin>
      <ContentLogin>
        <div className="img-fondo">
          <div className="frases-login">
            <p id="p_1">La Terapia no tiene que durar años para cambiar tu vida.</p>
            <p id="p_2">Sabemos que muchas personas desisten de continuar (o incluso comenzar) con la terapia porque piensan que solo estarán hurgando en el pasado e imaginando el futuro, pero no cambiando el presente.</p>
            <p id="p_3">¡Puedes cambiar tu presente!</p>
          </div>
          <div className="div-caja-login">
            <div className="wrapper_login">
              <div className="titulo-caja-login">
                <p> Iniciar sesión </p>
              </div>
              <div className="input-box">
                <input
                  id="id-correo-login"
                  type="text"
                  onChange={handleCorreo}
                  placeholder="Correo    |    Usuario"
                  required
                />
                <input
                  id="id-contrasena-login"
                  type="password"
                  onChange={handleContrasena}
                  placeholder="Contraseña"
                  required
                />
              </div>
              <div className="recordar-olvidar-pass">
                <div className="check_box">
                  <label>
                    <input type="checkbox" />
                  </label>
                  <label> Recordar contrasena </label>
                </div>
                <div className="olvidar_pass">
                  <a href=" "> ¿Olvidaste la contrasena?</a>
                </div>
              </div>
              <div className="botones_login">
                <div className="botones1_login">
                  <button id="btn_entrar" type="submit" onClick={handleSubmit}>
                    <label>Entrar</label>
                  </button>
                </div>
                <div className="botones1_login">
                  <button id="btn_salir" type="submit" onClick={Cancelar}>
                    <label>Salir</label>
                  </button>
                </div>
              </div>
              <div className="register-link">
                <label id="sin_cuenta">
                  No tengo una cuenta:
                </label>
                {/*   local
                  <a href="/addUsuarioExpressPx">
                  Regístrate
                </a>
                */}

                {/*   web     */}
                <a href="#/addUsuarioExpressPx">
                  Regístrate
                </a>
              </div>
            </div>
          </div>
        </div>
      </ContentLogin>
    </LayoutLogin>
  )
};


export default Login;