
import React from "react";
import HeaderLogin from "../components/HeaderLogin";
import ContentLogin from "../components/ContentLogin";


const Layout_login = ({ children }) => {

  return (
    <div className="Layout-login">
      <HeaderLogin />
      <ContentLogin>{children}</ContentLogin>
    </div>
  );
};

export default Layout_login;