
import axios from "axios";

const PARENTESCO_API_BASE_URL = "/server/parentesco";
const PARENT_INFO_API_BASE_URL = "/server/parentescoInfo";
const EDIT_FAM_ES_PAC_API_BASE_URL = "/server/editFamEsPaciente";
const EDIT_FAM_NO_ES_PAC_API_BASE_URL = "/server/editFamNoEsPaciente";
const VPARENTESCO_API_BASE_URL = "/server/vparentesco";


// const PARENTESCO_API_BASE_URL = "http://localhost:3002/parentesco";
// const PARENT_INFO_API_BASE_URL = "http://localhost:3002/parentescoInfo";
// const EDIT_FAM_ES_PAC_API_BASE_URL = "http://localhost:3002/editFamEsPaciente";
// const EDIT_FAM_NO_ES_PAC_API_BASE_URL = "http://localhost:3002/editFamNoEsPaciente";
// const VPARENTESCO_API_BASE_URL = "http://localhost:3002/vparentesco";


const Buffer = require('buffer/').Buffer;


class ParentescoService {


    /* Crear Parentesco -------- */
    async createParentesco(parentesco) {
        return await axios.post(PARENTESCO_API_BASE_URL, parentesco);
    }


    // obtner parentesco por ID del familiar
    async getParentescoIdFam(fk_familiar) {
        const parent = await axios.get(`${PARENT_INFO_API_BASE_URL}IdFamiliar?fk_familiar=${fk_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('TableParentesco PS: ', error);
            });
        const parentesco = parent.data;
        return parentesco;
    }


    // obtner todos los parentesco de la tabla parentesco
    async getParentescos() {
        const parent = await axios.get(PARENT_INFO_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS getParentescos: ', error);
            });
        const parentesco = parent.data;
        return parentesco;
    }


    // Editar Familiar es Paciente
    async editarFamEsPaciente(parentesco) {
        const response = await axios.put(EDIT_FAM_ES_PAC_API_BASE_URL, parentesco)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('editarFamEsPaciente PS: ', error);
            });
        const fam = response.data;
        return fam;
    }

    // Editar Familiar NO es Paciente
    async editarFamNoEsPaciente(parentesco) {

        console.log("editarFamNoEsPaciente PS --------- ");
        console.log(parentesco);

        const response = await axios.put(EDIT_FAM_NO_ES_PAC_API_BASE_URL, parentesco)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('editarFamNoEsPaciente PS: ', error);
            });
        const fam = response.data;
        return fam;
    }

    async getFamiliarP(id_paciente_familiar) {
        const response = await axios.get(`${VPARENTESCO_API_BASE_URL}Individual?id_paciente_familiar=${id_paciente_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarP vparentesco: ', error);
            });
        const familiar = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(familiar[0].pf_fotografia.data, 'binary');

        if (familiar[0].pf_formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            familiar[0].pf_fotografia.data = imgBlob;
        } else if (familiar[0].pf_formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            familiar[0].pf_fotografia.data = imgBlob;
        } else if (familiar[0].pf_formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            familiar[0].pf_fotografia.data = imgBlob;
        }

        return familiar;
    }


    async deleteParentesco(id_parentesco) {

        return await axios.delete(`${PARENTESCO_API_BASE_URL}Delete?id_parentesco=${id_parentesco}`);
        
    }


}

export default new ParentescoService();










