
import React, { useEffect, useState, useCallback } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import { now } from "moment";

import PsicologoService from "../../services/PsicologoService";
import PendientesService from "../../services/PendientesService";

import "../../styles/Expedientes.css";
import "../../styles/Pendientes.css";
import "../../styles/Pacientes.css";
import "../../styles/Calendario.css";
import "../../styles/Notificaciones.css";

import img_add from "../../img/playlist_add.svg";


const Pendientes = () => {

    const [pendientes, setPendientes] = useState([]);
    const [comentarios, setComentarios] = useState([]);

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);


    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        const data = await PendientesService.getPendientes(usuario.id_psicologo);
        setPendientes(data);
        setComentarios(data.map(p => p.comentario));
    };

    useEffect(() => {
        getData();
    }, [])

    const agregarPendiente = async () => {
        const hoy = moment();
        const fechaFormateada = hoy.format('DD-MM-YYYY');
        const nuevoPendiente = { fecha: fechaFormateada, hora: moment().format('HH:mm:ss'), comentario: '', fk_psicologo: usuario.id_psicologo };
        try {
            const respuesta = await PendientesService.guardarPendiente(nuevoPendiente);
            console.log(respuesta.status)
            if (respuesta.status === 200) {
                getData();
            } else {
                Toast.fire("Hubo un problema al guardar el pendiente!", "", "error");
            }
        } catch (error) {
            Toast.fire("Hubo un problema al guardar el pendiente!", "", "error");
            console.log('Error al realizar la inserción:', error.message);
        }
    };


    const guardarPendiente = async (index) => {
        const pendienteActualizado = pendientes[index];
        if (pendienteActualizado.id_pendiente === undefined) {
            try {
                const respuesta = await PendientesService.guardarPendiente(pendienteActualizado);
                console.log(respuesta.status)
                if (respuesta.status === 200) {
                    Toast.fire("Se guardó el pendiente correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            getData();
                        }
                    }).then(function () {
                        getData();
                    });
                } else {
                    Toast.fire("Hubo un problema al guardar el pendiente!", "", "error");
                }
            } catch (error) {
                Toast.fire("Hubo un problema al guardar el pendiente!", "", "error");
                console.log('Error al realizar la inserción:', error.message);
            }
        } else {
            try {
                const respuesta = await PendientesService.editarPendiente(pendienteActualizado, pendienteActualizado.id_pendiente);
                console.log(respuesta.status)
                if (respuesta.status === 200) {
                    Toast.fire("Se editó el pendiente correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            getData();
                        }
                    }).then(function () {
                        getData();
                    });
                } else {
                    Toast.fire("Hubo un problema al editar el pendiente", "", "error");
                }
            } catch (error) {
                Toast.fire("Hubo un problema al editar el pendiente", "", "error");
                console.log('Error al realizar la inserción:', error.message);
            }
        }
    };

    const eliminarPendiente = async (index) => {
        const pendienteEliminar = pendientes[index];
        if (pendienteEliminar.id_pendiente !== undefined) {
            const respuesta = await PendientesService.eliminarPendiente(pendienteEliminar.id_pendiente);
            if (respuesta.status === 200) {
                Toast.fire("Se eliminó el pendiente correctamente!", "", "success").then((result) => {
                    if (result.isConfirmed) {
                        getData();
                    }
                }).then(function () {
                    getData();
                });
            } else {
                Toast.fire("Hubo un problema al eliminar la cita!", "", "error");
            }
        } else {
            getData();
        }

    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleComentario = useCallback(debounce(async (index, comentario) => {
        const pendienteComentario = pendientes[index]
        pendienteComentario.comentario = comentario;
        if (pendienteComentario.id_pendiente === undefined) {
            try {
                const respuesta = await PendientesService.guardarPendiente(pendienteComentario);
                console.log(respuesta.status)
                if (respuesta.status === 200) {
                    getData();
                } else {
                    mostrarError("Hubo un problema al guardar el pendiente.");
                }
            } catch (error) {
                mostrarError("Hubo un problema al guardar el pendiente.");
                console.log('Error al realizar la inserción:', error.message);
            }
        } else {
            try {
                const respuesta = await PendientesService.editarPendiente(pendienteComentario, pendienteComentario.id_pendiente);
                if (respuesta.status === 200) {
                    getData();
                } else {
                    Toast.fire("Hubo un problema al editar el pendiente", "", "error");
                }
            } catch (error) {
                Toast.fire("Hubo un problema al editar el pendiente", "", "error");
                console.log('Error al realizar la inserción:', error.message);
            }
        }

    }, 10), [pendientes]);


    const DisplayPendientes = pendientes.map((pendiente, index) => {
        return (
            <div className="faq-container-pend">
                <details open>
                    <summary> Creado el {pendiente.fecha}, a las {moment(pendiente.hora, "HH:mm:ss").format("HH:mm")} </summary>
                    <div key={index}>
                        <p style={{ border: "none" }}>
                            <textarea id="textarea-sesiones" rows="4" cols="50"
                                onChange={(event) => handleComentario(index, event.target.value)}
                                value={comentarios[index]}>
                            </textarea>
                            <p id="p-guardar-sesion">
                                <button id="btn-guardar-sesion" onClick={() => guardarPendiente(index)}>Guardar</button>
                                <button id="btn-guardar-sesion" onClick={() => eliminarPendiente(index)}>Eliminar</button>
                            </p>
                        </p>
                    </div>
                </details>
            </div>
        )
    })


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-pend">
                    <div className="px_div_2_pend">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} {usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Pendientes</label>
                        </div>
                    </div>
                    <div className="div-btn-addPx-pend">
                        <button id="btn-add-pend" onClick={() => agregarPendiente()}>
                            <img src={img_add} alt="img_add"></img>
                            <label>Pendiente</label>
                        </button>
                    </div>
                    <div className="div-3-expediente-pend">
                        {DisplayPendientes}
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Pendientes;

