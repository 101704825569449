
// FormularioEditarCita.js  --- Psicologo

import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioEditarCita = ({ paciente, fechaF, horaI, horaF, modalidad, comentario }) => {
  return (
    <div className="div-editar-cita-modal">
      <table className="tabla-editar-cita-modal">
        <tbody>
          <tr className="table-line-bottom">
            <td className="editar-cita-td-izq">
              <label className="lbl-px-editar" htmlFor="select-paciente"> Paciente: </label>
            </td>
            <td className="editar-cita-td-der">
              <select id="select-px-editar" disabled>
                <option className="selected-option" value={paciente.id_paciente}>
                  {paciente.nombre} {paciente.ap1} {paciente.ap2}
                </option>
              </select>
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="editar-cita-td-izq">
              <label className="lbl-fecha-editar" htmlFor="input-fecha"> Fecha: </label>
            </td>
            <td className="editar-cita-td-der">
              <input type="date" id="input-fecha-editar" defaultValue={fechaF} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="editar-cita-td-izq">
              <label className="lbl-horaI-editar" htmlFor="lbl-horaI-editar"> Hora Inicio: </label>
            </td>
            <td className="editar-cita-td-der">
              <input type="time" id="input-horaI-editar" defaultValue={horaI} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="editar-cita-td-izq">
              <label className="lbl-horaF-editar" htmlFor="lbl-horaF-editar"> Hora Final: </label>
            </td>
            <td className="editar-cita-td-der">
              <input type="time" id="input-horaF-editar" defaultValue={horaF} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-comentario-registro"> Modalidad: </label></td>
            <td className="registrar-cita-td-der">
              <select className="selected-pax" id="select-modalidad-editar" value={modalidad}>
                <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                <option className="selected-option" value="presencial">Presencial</option>
                <option className="selected-option" value="videollamada">Videollamada</option>
                <option className="selected-option" value="telefono">Teléfono</option>
                <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
              </select>
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="editar-cita-td-izq">
              <label className="lbl-comentario-editar" htmlFor="lbl-comentario-editar"> Comentario: </label>
            </td>
            <td className="editar-cita-td-der">
              <input type="text" placeholder="Deja un comentario..." id="input-comentario-editar" defaultValue={comentario} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormularioEditarCita;
