import React from "react";

import "../styles/header_login.css";
import logo from "../img/psiquedata_logo_trans.png";


const Header_login = () => {

  return (
    <header className="header_login">
      <div className="logo_login">
        <img src={logo} alt="login" />
      </div>
      <div className="lista_login">
        <ul className="links_login">
          <li><a href="#">¿Quién soy?</a></li>
          <li><a href="#">Contacto</a></li>
        </ul>
      </div>
    </header>
  );
};

export default Header_login;