
// ------------- Layout --------------//

import React from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import HeaderPx from "../components/HeaderPx";
import HeaderPsic from "../components/HeaderPsic";
import Content from "../components/Content";
import Footer from "../components/Footer";


const Layout = ({ children }) => {


  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);
  

  const tipoUsuario = () => {
    if (usuario.rol === 'adm') {
      return (
        <div className="Layout">
          <div className="div-layout-header">
            <HeaderAdmin />
          </div>
          <div className="div-layout-content">
            <Content>
              {children}
            </Content>
          </div>
          <div className="div-layout-footer">
            <Footer />
          </div>
        </div>
      )

    } else if (usuario.rol === 'psi') {
      return (
        <div className="Layout">
          <div className="div-layout-header">
            <HeaderPsic />
          </div>
          <div className="div-layout-content">
            <Content>
              {children}
            </Content>
          </div>
          <div className="div-layout-footer">
            <Footer />
          </div>
        </div>
      )

    } else {
      return (
        <div className="Layout">
          <div className="div-layout-header">
            <HeaderPx />
          </div>
          <div className="div-layout-content">
            <Content>
              {children}
            </Content>
          </div>
          <div className="div-layout-footer">
            <Footer />
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      {tipoUsuario()}
    </div>
  );
};


export default Layout;